<!-- <div class="mx-4">
    <div style="width: 100%; margin-top: 5.5rem;">
        <app-real-time></app-real-time>
    </div>
    <div class="row">
        <div class="col-lg-6 mt-3">
            <home-map [content]="'home'"></home-map>
            <div class="mt-3">
                <attendance [content]="'home'"></attendance>
            </div>
        </div>
        <div class="col-lg-6 mt-3">
            <events [content]="'home'"></events>
        </div>
    </div>
</div>
<br> -->

<eventsinfo [content]="'home'"></eventsinfo>

import { Component } from "@angular/core";
import { SelectedLocationsService } from "src/app/core/services/selected-locations.service";

@Component({
    selector: 'logged-skeleton',
    templateUrl: 'logged-skeleton.component.html',
    styleUrls: [
        'logged-skeleton.component.scss'
    ]
})
export class LoggedSkeletonComponent {

    constructor(private locationsService: SelectedLocationsService) {
        // this.locationSuscription = this.locationsService.selectedLocationsObservable.subscribe(selectedIds => {
        //     // console.log(selectedIds);
        // })
    }
 }
<h1 #dialogTitle mat-dialog-title>
    MANAGE SITES
</h1>
<div class="dialog-margin">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="Sites">
            <div class="alert alert-info">
                The content is being implemented
            </div>
        </mat-tab>
        <mat-tab label="Add New Site">
            <site-form></site-form>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="dialog-margin d-flex justify-content-end" mat-dialog-actions>
    <button mat-stroked-button>CANCEL</button>
    <button mat-stroked-button style="color: white;" class="bg-brand-color">{{ selectedTabIndex === 0 ? 'SAVE' : 'ADD SITE' }}</button>
</div>
<!-- <span [style.top.px]="titleHeight / 2" style="position: absolute; right: 1rem; display: flex; flex-direction: column; justify-content: center; align-items: center;" class="brand-color">
    <button style="width: 70px; height: 70px; margin-bottom: 0.5rem;" mat-mini-fab>
        <mat-icon>add</mat-icon>
    </button>
    <span style="font-style: bold;">Add New Site</span>
</span> -->
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocationService } from 'src/app/core/services/location.service';

@Component({
  selector: 'hub-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  // children = false;

  locations_issues: string[] = [];

  private subscriptionLocIssues: Subscription;

  constructor(private router: Router,
    private locationsService: LocationService) {
    this.subscriptionLocIssues = this.locationsService.getLocIssuesArr().subscribe((locs) => {
      this.locations_issues = locs;
    })
  }

  ngOnInit(): void {
    this.locations_issues = []
  }

  ngOnDestroy(): void {
    this.subscriptionLocIssues?.unsubscribe();
  }


  // showChildren() {
  //   this.children = true;
  // }

  // hideChildren() {
  //   this.children = false;
  // }

}

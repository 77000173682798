<!-- <div class="hub-page-container"> -->

<!-- <h2>Drill</h2> -->
<div class="history__header">
  <div class="history__header-texts">
    <p class="history__header-subtitle">DRILL</p>
  </div>
</div>
<!--Active Event Icon-->
<div *ngIf="maxEvent" class="active__event-container" [ngStyle]="{ 'backgroundColor': maxEvent.backgroundColor }">
  <div class="active__event d-flex" [ngStyle]="{ 'backgroundColor': maxEvent.color }" >
    <div class="active__event-info">
      <div class="img-event-active">
        <img [src]="'../../../../assets/images/icons/' + maxEvent.icon"
        class="me-2" style="width: 40px;" alt="maxEvent.name">
      </div>

      <div class="me-3">
        <p class="event__title">{{ maxEvent.name }}</p>
        <p class="progress__text">{{ msg }} in progress</p>
      </div>

    </div>
  </div>
</div>

<div class="drill drill_tabs" [ngClass]="{'active__drill': maxEvent}">
  <mat-tab-group
    #tabGroup
    [selectedIndex]="selected.value"
    (selectedIndexChange)="selected.setValue($event)"
    (click)="selected.value
    ? sendTabChanged(selected.value)
    : null"
    animationDuration="0ms">
    <mat-tab label="Drill Manager">
        <drillevents [data]="dataShare"></drillevents>
    </mat-tab>
    <mat-tab label="Active Drill View">
        <eventsinfo class="activeDrillView" [content]="'drill'"></eventsinfo>
    </mat-tab>
    <mat-tab label="Drill Dashboard">
      <ng-template matTabContent>
        <drill-dashboard [dataDrill]="dataShare"></drill-dashboard>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class="button-container create-button" *ngIf="selected.value == 0">
    <div class="hub-fab">
      <button mat-fab color="primary" aria-label="create drill button" (click)="openDialog()" [disabled]="roleLocUser === 'viewer'">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div>
      <span [ngClass]="{'disabledStyle' : (roleLocUser == 'viewer')}">Add Drill</span>
    </div>
  </div>
</div>
<!-- </div> -->

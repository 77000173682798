import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { firstValueFrom, Subscription } from 'rxjs';
import { ActivationMethod } from 'src/app/core/models/activation-method';
import {
  Address,
  DataShareMap,
  LocationCoors,
  Timeline,
} from 'src/app/core/models/data-shared';
import { EventTypesLocation } from 'src/app/core/models/event-types-location';
import {
  LabelPoints4Size,
  LocationResponse,
} from 'src/app/core/models/location.model';
import { SubscriptionModel } from 'src/app/core/models/subscriptionModel';
import { BlueprintsService } from 'src/app/core/services/blueprints.service';
import { BuildingsService } from 'src/app/core/services/building.service';
import { DrillService } from 'src/app/core/services/drills.service';
import { EventsService } from 'src/app/core/services/events.service';
import { LocationService } from 'src/app/core/services/location.service';
import { OneTimeService } from 'src/app/core/services/onetime.service';
import { USGSOverlayComponent } from 'src/app/core/utils/usgsoverlay';

@Component({
  selector: 'map',
  templateUrl: 'map.component.html',
  styleUrls: ['map.component.scss'],
})
export class MapComponent implements OnInit {
  private location: any;

  private _mapElement: ElementRef | null = null;

  @ViewChild('homeMap', { static: true }) get mapElement(): ElementRef | null {
    return this._mapElement;
  }

  set mapElement(newValue: ElementRef) {
    if (this._mapElement !== newValue) {
      this._mapElement = newValue;
      // Check that newValue is a defined value, and possibly perform actions here.
    }
  }

  groupData: any;
  @Input('groupData') set groupDataSet(value: any) {
    this.groupData = value;
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i]?.setMap(null);
      this.markers[i] = null;
    }
    this.markers = [];
    if (!this.map) {
      this.drawMap();
    }
    this.addAddressToLocation(this.groupData);
  }

  get groupDataSet(): any {
    return this.groupData;
  }

  @Input('content') content: string = '';

  @Input('location') set locationSet(value: any) {
    this.mapBounds = null;
    this.mapPolygon?.setMap(null);
    this.location = value;
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i]?.setMap(null);
      this.markers[i] = null;
    }
    this.markers = [];
    if (!this.map) {
      this.drawMap();
    }
    this.drawLocationAllInfo();
  }

  get locationSet(): any {
    return this.location;
  }

  data: DataShareMap | null = null;

  @Input('data') set dataSet(value: DataShareMap) {
    this.data = value;
    // console.log('map data', this.data);

    if (this.data) {
      if (this.data.eventsRes) {
        for (const value of this.data.eventsRes) {
          this.getBuildings(value.location_id).then((res: any) => {
            this.buildings[value.location_id] = res.Buildings;
          });
        }

        if (this.data.refresh) {
          if (this.controlDivGrid) {
            this.controlDivGrid.style.display = 'none';
          }
          if (!this.map) {
            this.drawMap();
          }

          if (this.data.selectedIds) {
            if (this.data.clickSubscription) {
              if (
                this.data.clickSubscription.updateData &&
                this.data.locationAddress
              ) {
                this.setMapElements(
                  this.data.locationAddress,
                  this.data.eventsRes,
                  true
                );
              }
              this.getLocationInfo(this.data.clickSubscription.loc);
              if (this.controlDivGrid) {
                this.controlDivGrid.style.display = 'block';
              } else {
                this.drawToggleInMap();
              }
            } else if (this.data.selectedIds.length > 0) {
              this.mapPolygon?.setMap(null);
              this.addAddressToLocation(this.data.selectedIds);
            } else {
              for (let i = 0; i < this.markers.length; i++) {
                this.markers[i]?.setMap(null);
                this.markers[i] = null;
              }
              this.markers = [];
              this.mapPolygon?.setMap(null);
              this.markerCluster?.setMap(null);
            }
          } else {
            this.mapPolygon?.setMap(null);
            this.setMapElements(
              this.data.locationAddress,
              this.data.eventsRes,
              true
            );
          }
        }
      }

      if (this.data.selectedIds?.length === 0) {
        this.drawMap();
      }
    }
  }

  get dataSet(): DataShareMap | null {
    return this.data;
  }

  private getClickMapLocations: Subscription;
  private getClickEventMapHighlight: Subscription;

  private getEventsSubs: Subscription;
  private getDrillsSubs: Subscription | null = null;

  locationSuscription: Subscription | null = null;
  // allLocationAddress: Address[];
  activationMethods: ActivationMethod[] = [];

  storedLocationCoors: LocationCoors[] = [];

  drawnLocations: LocationCoors[] = [];

  /** The reference to the initialized google map */
  mapPolygon: google.maps.Polygon | null = null;
  map: google.maps.Map | null = null;
  mapBounds: google.maps.LatLngBounds | null = null;
  markers: any[] = [];
  actMethMarkers: any[] = [];

  readonly killianLat = 25.670241;
  readonly killianLng = -80.3496469;
  addressArray: Address[] = [];

  areas: Array<any> = [];
  buildings: Array<any> = [];
  mapPolygons: Array<any> = [];
  overlayArr: Array<any> = [];

  glowPin: google.maps.Marker | null = null;
  areaInfoOverLay: {
    id: string;
    overlay: USGSOverlayComponent;
  } | null = null;

  locationZoom: LocationResponse | null = null;

  markerCluster: MarkerClusterer | null = null;

  isAttendanceView: boolean = false;

  controlDivGrid: HTMLElement | null = null;

  rectangle: google.maps.Rectangle | null = null;
  gridPoints: {
    point1: google.maps.LatLng;
    point2: google.maps.LatLng;
  } | null = null;
  labelsArr: USGSOverlayComponent[] = [];
  rectanglesArr: any = [];

  constructor(
    private locationService: LocationService,
    private eventsService: EventsService,
    private _snackBar: MatSnackBar,
    private oneTimeService: OneTimeService,
    private buildingsService: BuildingsService,
    private blueprintsService: BlueprintsService,
    private drillsService: DrillService
  ) {
    this.getClickMapLocations = this.eventsService
      .getShowAllLocations()
      .subscribe(() => {
        if (this.controlDivGrid) {
          this.controlDivGrid.style.display = 'none';
        }
        if (this.data) {
          this.data.clickSubscription = undefined;
          this.setMapElements(
            this.data?.locationAddress,
            this.data?.eventsRes,
            true
          );
        }
      });

    this.getClickEventMapHighlight = this.eventsService
      .getEventMapHighlight()
      .subscribe((info: any) => {
        if (info) {
          for (let i = 0; i < this.areas?.length; i++) {
            if (info.building_id == this.areas[i].id) {
              this.highlightArea(this.areas[i]);
              break;
            }
          }
          this.reDrawMarker(info.locationId, info.id);
        } else {
          if (this.data) {
            this.drawBuildingAllInfo(this.data.clickSubscription?.loc);
          }
          this.glowPin?.setMap(null);
        }
      });

    this.getEventsSubs = this.eventsService
      .getEventsSubs()
      .subscribe((data: SubscriptionModel) => {
        if (
          data.channel == 'event-map-timeline-pins' &&
          data.broadcastID != 'MapComponent'
        ) {
          this.isAttendanceView = data.payload.isAttendanceTab;
          this.eventToMapMarker(
            data.payload.location_id,
            data.payload.isAttendanceTab
          );
        }
      });
  }

  gridToggleClick(event: any) {
    if (event.checked) {
      if (this.locationZoom?.grid_configuration) {
        if (Object.keys(this.locationZoom.grid_configuration).length > 0) {
          this.gridPoints = {
            point1: new google.maps.LatLng(
              this.locationZoom.grid_configuration.bottom_left_point.lat,
              this.locationZoom.grid_configuration.bottom_left_point.lng
            ),
            point2: new google.maps.LatLng(
              this.locationZoom.grid_configuration.upper_right_point.lat,
              this.locationZoom.grid_configuration.upper_right_point.lng
            ),
          };
          this.drawRectangleGrid();
        } else {
          this.openSnackBar(
            'There is not grid configuration for this location',
            'Ok'
          );
          event.checked = false;
        }
      } else {
        this.openSnackBar(
          'There is not grid configuration for this location',
          'Ok'
        );
        event.checked = false;
      }
    } else {
      this.rectangle?.setMap(null);
      for (let i = 0; i < this.labelsArr?.length; i++) {
        this.labelsArr[i].setMap(null);
      }
      this.labelsArr = [];
      for (const x in this.rectanglesArr) {
        for (const y in this.rectanglesArr[x]) {
          if (this.rectanglesArr[x][y].setMap) {
            this.rectanglesArr[x][y].setMap(null);
            this.rectanglesArr[x][y] = null;
          }
        }
      }
      /* START - reset the polygon*/
      this.mapBounds = null;
      this.mapPolygon?.setMap(null);
      this.mapPolygon = null;
      /* END*/
      this.drawLocationGreyedOut(this.locationZoom);
      this.map?.setOptions({
        minZoom: 0,
        keyboardShortcuts: true,
        restriction: null,
      });
    }
  }

  drawRectangleGrid() {
    this.rectangle = new google.maps.Rectangle({
      strokeColor: '#8c8d8f',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: '#FFF',
      fillOpacity: 0,
      map: this.map,
      bounds: new google.maps.LatLngBounds(
        this.gridPoints?.point1,
        this.gridPoints?.point2
      ),
    });

    this.map?.setOptions({
      minZoom: this.map?.getZoom(),
      keyboardShortcuts: false,
      restriction: {
        latLngBounds: new google.maps.LatLngBounds(
          this.gridPoints?.point1,
          this.gridPoints?.point2
        ),
      },
    });
    this.makeGrid();
  }

  makeGrid() {
    /* START - reset the polygon*/
    this.mapBounds = null;
    this.mapPolygon?.setMap(null);
    this.mapPolygon = null;
    /* END*/

    this.drawLocationPolygon(this.locationZoom, true);

    for (let i = 0; i < this.labelsArr?.length; i++) {
      this.labelsArr[i].setMap(null);
    }
    this.labelsArr = [];

    for (const x in this.rectanglesArr) {
      for (const y in this.rectanglesArr[x]) {
        if (this.rectanglesArr[x][y]?.setMap) {
          this.rectanglesArr[x][y].setMap(null);
          this.rectanglesArr[x][y] = null;
        }
      }
    }
    var leftSideDist = this.gridPoints
      ? this.gridPoints.point2.lng() - this.gridPoints.point1.lng()
      : null;
    var belowSideDist = this.gridPoints
      ? this.gridPoints.point2.lat() - this.gridPoints.point1.lat()
      : null;

    var dividerLat = 16;
    var dividerLng = 16;
    var excLat = belowSideDist ? belowSideDist / dividerLat : null;
    var excLng = leftSideDist ? leftSideDist / dividerLng : null;

    var m1Lat = this.gridPoints?.point1.lat();
    var m1Lng = this.gridPoints?.point1.lng();
    var m2Lat = this.gridPoints?.point2.lat();
    var m2Lng = this.gridPoints?.point2.lng();

    for (var i = 0; i < dividerLat; i++) {
      if (!this.rectanglesArr[i]) this.rectanglesArr[i] = [];
      for (var j = 0; j < dividerLng; j++) {
        if (!this.rectanglesArr[i][j]) this.rectanglesArr[i][j] = {};
        if (m1Lat && excLat && m1Lng && excLng) {
          let labelP4Size: LabelPoints4Size = {
            mainPoint: new google.maps.LatLng(
              m1Lat + excLat * (i + 1),
              m1Lng + excLng * j
            ),
            bottomPoint: new google.maps.LatLng(
              m1Lat + excLat * i,
              m1Lng + excLng * j
            ),
            rightPoint: new google.maps.LatLng(
              m1Lat + excLat * (i + 1),
              m1Lng + excLng * (j + 1)
            ),
          };
          if (i == dividerLat - 1 && j == 0) {
            const overlay: USGSOverlayComponent = new USGSOverlayComponent(
              'X',
              'labelGrid',
              undefined,
              undefined,
              labelP4Size
            );
            overlay.setMap(this.map);
            this.labelsArr.push(overlay);
          } else if (i == dividerLat - 1 && j != 0) {
            const overlay: USGSOverlayComponent = new USGSOverlayComponent(
              this.alphabeticColName(j - 1),
              'labelGrid',
              undefined,
              undefined,
              labelP4Size
            );
            overlay.setMap(this.map);
            this.labelsArr.push(overlay);
          } else if (i >= 0 && j == 0) {
            const overlay: USGSOverlayComponent = new USGSOverlayComponent(
              (dividerLat - 1 - i).toString(),
              'labelGrid',
              undefined,
              undefined,
              labelP4Size
            );
            overlay.setMap(this.map);
            this.labelsArr.push(overlay);
          } else {
            if (m1Lat && excLat && m1Lng && excLng) {
              this.rectanglesArr[i][j] = new google.maps.Rectangle({
                strokeColor: '#8c8d8f',
                strokeOpacity: 0.2,
                strokeWeight: 1,
                fillColor: '#FFF',
                fillOpacity: 0,
                map: this.map,
                bounds: new google.maps.LatLngBounds(
                  new google.maps.LatLng(
                    m1Lat + excLat * i,
                    m1Lng + excLng * j
                  ),
                  new google.maps.LatLng(
                    m1Lat + excLat * (i + 1),
                    m1Lng + excLng * (j + 1)
                  )
                ),
              });
            }
          }
        }
      } //for j Lng
    } //for i Lat
  }

  alphabeticColName(n: number) {
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;

    var s = '';
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

  drawToggleInMap() {
    let selfComponent = this;
    //### Add a button on Google Maps ...
    this.controlDivGrid = document.createElement('div');
    this.controlDivGrid.classList.add('toggle-map');
    let divText = document.createElement('div');
    let textnode = document.createTextNode('Grid');
    divText.appendChild(textnode);
    this.controlDivGrid.appendChild(divText);
    let labelSwitch = document.createElement('label');
    labelSwitch.classList.add('switch');
    let inputCheckbox = document.createElement('input');
    inputCheckbox.type = 'checkbox';
    inputCheckbox.onclick = (evt) => {
      selfComponent.gridToggleClick(evt.target);
    };
    labelSwitch.appendChild(inputCheckbox);
    let spanCheckbox = document.createElement('span');
    spanCheckbox.classList.add('slider');
    spanCheckbox.classList.add('round');
    labelSwitch.appendChild(spanCheckbox);
    this.controlDivGrid.appendChild(labelSwitch);
    this.controlDivGrid.title = 'Click to make the grid visible';
    //myLocationControlDiv.appendChild(controlUI);

    this.map?.controls[google.maps.ControlPosition.TOP_LEFT].push(
      this.controlDivGrid
    );
  }

  getClassCSS(): string {
    if (this.data?.content == 'home' || this.data?.content == 'drill') {
      return 'home__height';
    } else if (
      this.content == 'location-info' ||
      this.content == 'group-info'
    ) {
      return 'content-height';
    } else {
      return 'history__height';
    }
  }

  reDrawMarker(locationId: string, cardId: string) {
    for (let i = 0; i < this.drawnLocations?.length; i++) {
      if (this.drawnLocations[i].id === locationId) {
        for (let j = 0; j < this.actMethMarkers?.length; j++) {
          if (this.actMethMarkers[j].get('id') == cardId) {
            this.actMethMarkers[j].setMap(null);
            this.actMethMarkers.splice(j, 1);
            break;
          }
        }
        let locEveTyp = this.data?.eventTypes?.find(
          (evt: any) => evt.location_id == this.drawnLocations[i].id
        );
        let timelineLength = this.drawnLocations[i]['timeline'];
        if (timelineLength) {
          for (let j = 0; j < timelineLength.length; j++) {
            if (timelineLength[j].event_id == cardId) {
              let actMeth, evtCode;
              let actMethPosition = {
                lat: timelineLength[j].lat,
                lng: timelineLength[j].long,
              };
              if (timelineLength[j].actMth != 'attendance') {
                actMeth = this.getSelectedActivationMethod(
                  timelineLength[j].actMth
                );

                evtCode = locEveTyp?.eventTypes.find((evt: any) => {
                  if (timelineLength) {
                    if (evt.id == timelineLength[j].event_type_location_id) {
                      return evt;
                    } else {
                      let timelineLengthPos = timelineLength[j];
                      if (timelineLengthPos) {
                        let nestEv = evt.event_types.find(
                          (nevt: any) =>
                            nevt.id == timelineLengthPos.event_type_location_id
                        );
                        if (nestEv) {
                          return { ...nestEv, code: evt.code };
                        }
                      }
                    }
                  }
                })?.code;

                if (
                  evtCode &&
                  !actMeth.description.toLowerCase().includes('web')
                ) {
                  let svgMarker = {
                    url: `./assets/images/SVG/map-pins/map-pin--${evtCode}-${actMeth.icon}`,
                    scaledSize: new google.maps.Size(70, 70),
                    origin: new google.maps.Point(0, 0),
                  };

                  let svgGlowMarker = {
                    url: `./assets/images/SVG/map-pins/map-pin--glow-${evtCode}.svg`,
                    scaledSize: new google.maps.Size(110, 110),
                    anchor: new google.maps.Point(55, 90),
                    // origin: new google.maps.Point(0, -20)
                  };

                  this.glowPin = new google.maps.Marker({
                    position: actMethPosition,
                    map: this.map,
                    icon: svgGlowMarker,
                  });

                  let actMethMarker = new google.maps.Marker({
                    position: actMethPosition,
                    map: this.map,
                    icon: svgMarker,
                    // animation: google.maps.Animation.DROP
                  });
                  actMethMarker.set('id', timelineLength[j].event_id);
                  this.actMethMarkers.push(actMethMarker);

                  if (actMethMarker) {
                    var selfComponent =
                      this; /**It is necesary to call global variables inside the listener */
                    // google.maps.event.addListener(actMethMarker, "click", function () {
                    actMethMarker.addListener('click', function () {
                      let selfTimelineLength =
                        selfComponent.drawnLocations[i].timeline;
                      if (selfTimelineLength) {
                        selfComponent.eventsService.sendEventsSubs({
                          channel: 'map-timeline',
                          broadcastID: 'MapComponent',
                          payload: {
                            eventId: selfTimelineLength[j].event_id,
                            isGlow: true,
                          },
                        });
                      }
                      setTimeout(() => {
                        if (selfTimelineLength) {
                          selfComponent.eventsService.sendEventsSubs({
                            channel: 'map-timeline',
                            broadcastID: 'MapComponent',
                            payload: {
                              eventId: selfTimelineLength[j].event_id,
                              isGlow: false,
                            },
                          });
                        }
                      }, 3000);
                    });
                  }
                }
              }
              break;
            }
          }
        }
        break;
      }
    }
  }

  ngOnInit(): void {
    this.setActivationMethods();
    sessionStorage.removeItem('locationCoors'); // Remove the sessionStorae
    this.getDrillsSubs = this.drillsService
      .getDrillsSubs()
      .subscribe((info: SubscriptionModel) => {
        if (
          info.channel == 'drills-map' &&
          info.broadcastID != 'MapComponent'
        ) {
          if (info.payload.tab == 1) {
            setTimeout(() => {
              if (this.mapBounds != null) {
                this.map?.fitBounds(this.mapBounds);
              }
            }, 500);
          }
        }
      });
  }

  ngOnDestroy() {
    this.getClickMapLocations?.unsubscribe();
    this.getClickEventMapHighlight?.unsubscribe();
    this.getEventsSubs?.unsubscribe();
    this.getDrillsSubs?.unsubscribe();
  }

  highlightArea(row: any) {
    this.mapPolygons.map((mp: any) => {
      mp.setMap(null);
    });

    this.overlayArr.map((ov: any) => {
      ov.setMap(null);
    });
    this.overlayArr = [];
    this.mapPolygons = [];

    this.areas.map((area: any) => {
      if (area.id != row.id) {
        let polygonProperties = {
          paths: area.polygon,
          strokeColor: '#ff6c0e',
          strokeOpacity: 0.2,
          strokeWeight: 2,
          fillColor: '#ff6c0e',
          fillOpacity: 0.1,
        };
        let mapPolygon = new google.maps.Polygon(polygonProperties);
        mapPolygon.setMap(this.map); // The polygon is added to the map
        this.mapPolygons.push(mapPolygon);
      } else {
        let polygonProperties = {
          paths: area.polygon,
          strokeColor: '#ff6c0e',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#ff6c0e',
          fillOpacity: 0.35,
        };
        let mapPolygon = new google.maps.Polygon(polygonProperties);
        mapPolygon.setMap(this.map);
        this.mapPolygons.push(mapPolygon);
        this.addLabelPolygon(area);
      }
    });
  }

  getAreas(location: any) {
    this.areas = [];

    this.buildingsService.getBuildings(location.id).subscribe(
      (res: any) => {
        res.Buildings.map((building: any) => {
          this.areas.push({
            id: building.id,
            polygon: building.polygon_coordinates,
            name: building.name,
            address: building.address,
            address_line_2: building.address_line_2,
            city: building.city,
            state: building.state,
            zip_code: building.zip_code,
            zip_code_plus_4: building.zip_code_plus_4,
            total_blueprints: building.total_blueprints,
            code: building.code,
            level: building.level,
            location_id: building.location_id,
            location_name: location.name,
          });
        });
        this.drawBuildingAllInfo(location.id);
      },
      (error) => {
        this.openSnackBar(error.message, 'Ok');
      }
    );
  }

  getBuildings(locationId: string) {
    return firstValueFrom(this.buildingsService.getBuildings(locationId));
  }

  drawBuildingAllInfo(locationId: string | undefined) {
    this.mapPolygons?.map((mp: any) => {
      mp.setMap(null);
    });

    this.overlayArr?.map((ov: any) => {
      ov.setMap(null);
    });

    this.overlayArr = [];

    this.mapPolygons = [];

    this.areas?.map((area: any) => {
      let polygonProperties = {
        paths: area.polygon,
        strokeColor: '#ff6c0e',
        strokeOpacity: 0.2,
        strokeWeight: 2,
        fillColor: '#ff6c0e',
        fillOpacity: 0.1,
      };
      let mapPolygon = new google.maps.Polygon(polygonProperties);
      mapPolygon.setMap(this.map); // The polygon is added to the map
      this.mapPolygons.push(mapPolygon);

      var selfComponent =
        this; /**It is necesary to call global variables inside the listener */
      // google.maps.event.addListener(mapPolygon, "mouseover", function () {
      mapPolygon.addListener('mouseover', function (this: any) {
        this.setOptions({ strokeOpacity: 0.8, fillOpacity: 0.35 });
        selfComponent.addLabelPolygon(area);
        selfComponent.eventsService.sendMapEventHighlight(area);
        selfComponent.eventsService.sendEventsSubs({
          channel: 'map-attendance',
          broadcastID: 'MapComponent',
          payload: {
            area: area,
            isGlow: true,
          },
        });
      });

      // google.maps.event.addListener(mapPolygon, "mouseout", function () {
      mapPolygon.addListener('mouseout', function (this: any) {
        selfComponent.overlayArr?.map((ov: any) => {
          ov.setMap(null);
        });
        selfComponent.overlayArr = [];
        this.setOptions({ strokeOpacity: 0.2, fillOpacity: 0.1 });
        selfComponent.eventsService.sendMapEventHighlight(undefined);
        selfComponent.eventsService.sendEventsSubs({
          channel: 'map-attendance',
          broadcastID: 'MapComponent',
          payload: {
            area: area,
            isGlow: false,
          },
        });
      });

      // google.maps.event.addListener(mapPolygon, "click", function () {
      mapPolygon.addListener('click', function () {
        selfComponent.overlayArr?.map((ov: any) => {
          ov.setMap(null);
        });
        selfComponent.overlayArr = [];
        if (selfComponent.areaInfoOverLay?.id != area.id) {
          selfComponent.areaInfoOverLay?.overlay.setMap(null);
          selfComponent.getBlueprints(area, locationId);
        } else {
          if (selfComponent.areaInfoOverLay?.overlay.getMap() == null) {
            selfComponent.getBlueprints(area, locationId);
          } else {
            selfComponent.areaInfoOverLay?.overlay.setMap(null);
          }
        }
      });
    });
  }

  addLabelPolygon(area: any) {
    let mapBoundsAreas = new google.maps.LatLngBounds();
    for (let i = 0; i < area.polygon.length; i++) {
      mapBoundsAreas.extend(area.polygon[i]);
    }
    let text = area.name;
    let overlay: USGSOverlayComponent = new USGSOverlayComponent(
      text,
      'areaName',
      mapBoundsAreas
    );
    overlay.setMap(this.map);
    this.overlayArr.push(overlay);
  }

  getBlueprints(area: any, locationId: string | undefined) {
    if (locationId != undefined) {
      this.blueprintsService
        .getBlueprints(locationId, area.id)
        .subscribe((res: any) => {
          this.openAreaInfo(area, res, locationId);
        });
    }
  }

  openAreaInfo(area: any, blueprints: Array<any>, locationId: string) {
    let fullEvent = this.data?.eventsRes?.find(
      (tml: any) => tml.location_id == locationId
    );
    let genEvType = this.getSelectedEventType(
      fullEvent?.general_event_type_id,
      locationId
    );
    let genActMeht = this.getSelectedActivationMethod(
      fullEvent?.general_activation_method_id
    );

    let mapBoundsAreas = new google.maps.LatLngBounds();
    for (let i = 0; i < area.polygon.length; i++) {
      mapBoundsAreas.extend(area.polygon[i]);
    }
    let text = area.name;
    let evt = this.getMainEventFromArea(area.id, area.location_id);
    let overlay = new USGSOverlayComponent(text, 'areaInfo', mapBoundsAreas, {
      area: area,
      blueprints: blueprints,
      event: evt,
      genEvType: genEvType,
      genActMeht: genActMeht.description,
    });
    overlay.setMap(this.map);
    this.areaInfoOverLay = {
      id: area.id,
      overlay: overlay,
    };
  }

  getSelectedEventType(eventTypeId: string | undefined, locationId: string) {
    let selectedEventType: EventTypesLocation | null = null;
    this.data?.eventTypes?.map((item: any) => {
      if (item.location_id === locationId) {
        item.eventTypes?.map((event: any) => {
          if (eventTypeId === event.id) {
            selectedEventType = event;
          } else {
            event.event_types?.map((nev: any) => {
              if (eventTypeId === nev.id) {
                selectedEventType = {
                  ...nev,
                  name: `${event.name}: ${nev.name}`,
                };
              }
            });
          }
        });
      }
    });
    return selectedEventType;
  }

  getMainEventFromArea(areaID: string, locationId: string) {
    let events = this.data?.eventsRes?.find(
      (ev: any) => ev.location_id === locationId
    );
    if (events) {
      let event_tml = [...events?.event_timeline].reverse();
      let eventByArea = event_tml.find(
        (ev: any) => ev.building_id === areaID && ev.record_type === 'event'
      );
      if (eventByArea) {
        let eventTypesInfo = this.data?.eventTypes?.find(
          (evt: any) => evt.location_id === locationId
        );
        let eventInfo = eventTypesInfo?.eventTypes.find((evt: any) => {
          if (evt.id == eventByArea?.event_type_location_id) {
            return evt;
          } else {
            let nestEv = evt.event_types?.find(
              (nevt: any) => nevt.id == eventByArea?.event_type_location_id
            );
            if (nestEv) {
              return { ...nestEv, code: evt.code };
            }
          }
        });
        return {
          ...eventInfo,
          activation_method_name: eventByArea.activation_method_name,
        };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  drawMap() {
    let mapProperties: google.maps.MapOptions = {
      center: new google.maps.LatLng(this.killianLat, this.killianLng),
      zoom: 11,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      // disableDefaultUI: true,
      zoomControl: true,
      scaleControl: true,
      gestureHandling: 'greedy',
    };

    this.map = new google.maps.Map(
      this.mapElement?.nativeElement,
      mapProperties
    );

    var noPoi = [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ];
    this.map?.setOptions({ styles: noPoi });
  }

  getLocationInfo(locationId: string) {
    this.locationService.getLocation(locationId).subscribe(
      (res: any) => {
        this.locationZoom = res;
        if (
          !this.data?.locationAddress &&
          this.data?.clickSubscription?.updateData
        ) {
          this.data.locationAddress = [
            {
              id: res.id,
              address: res.address,
              coors: new google.maps.LatLng(res.latitude, res.longitude),
              locationName: res.name,
            },
          ];
          this.setMapElements(
            this.data.locationAddress,
            this.data.eventsRes,
            true
          );
        }
        this.eventToMapMarker(
          this.data?.clickSubscription?.loc,
          this.isAttendanceView
        );
        this.drawLocationGreyedOut(res);
        this.getAreas({ id: res.id, name: res.name });
      },
      (error) => {
        this.openSnackBar(error.message, 'Ok');
      }
    );
  }

  drawLocationPolygon(location: any, withoutColor?: boolean) {
    /* START - reset the polygon*/
    this.mapBounds = null;
    this.mapPolygon?.setMap(null);
    this.mapPolygon = null;
    /* END*/

    let polygonProperties = {
      paths: location.polygon_coordinates,
      strokeColor: '#ff6c0e',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#ff6c0e',
      fillOpacity: withoutColor ? 0 : 0.35,
    };

    // it is used to zoom in on the polygon
    this.mapBounds = new google.maps.LatLngBounds();

    for (let i = 0; i < location.polygon_coordinates.length; i++) {
      this.mapBounds?.extend(location.polygon_coordinates[i]);
    }
    // end zoom in

    this.mapPolygon = new google.maps.Polygon(polygonProperties);
    this.mapPolygon?.setMap(this.map); // The polygon is added to the map

    this.map?.fitBounds(this.mapBounds); // The bounds are added for zoom in
  }

  drawLocationGreyedOut(location: any) {
    /* START - reset the polygon*/
    this.mapBounds = null;
    this.mapPolygon?.setMap(null);
    this.mapPolygon = null;
    /* END*/

    let outerCoords = [
      {
        lat: 0,
        lng: 180,
      },
      {
        lat: 85,
        lng: 180,
      },
      {
        lat: 85,
        lng: 0,
      },
      {
        lat: 85,
        lng: -180,
      },
      {
        lat: 0,
        lng: -180,
      },
      {
        lat: -85,
        lng: -180,
      },
      {
        lat: -85,
        lng: 0,
      },
      {
        lat: -85,
        lng: 180,
      },
    ];

    if (Math.sign(this.edgesSum(location.polygon_coordinates)) == 1) {
      outerCoords = outerCoords.reverse();
    }

    let polygonProperties = {
      paths: [outerCoords, location.polygon_coordinates],
      strokeColor: '#ff6c0e',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#000',
      fillOpacity: 0.35,
    };

    // it is used to zoom in on the polygon

    this.mapBounds = new google.maps.LatLngBounds();

    for (let i = 0; i < location.polygon_coordinates.length; i++) {
      this.mapBounds?.extend(location.polygon_coordinates[i]);
    }

    // end zoom in

    this.mapPolygon = new google.maps.Polygon(polygonProperties);

    this.mapPolygon.setMap(this.map); // The polygon is added to the map

    /** Here we add +1 to the zoom of the map */

    // let map = this.map
    // var selfComponent = this;
    // google.maps.event.addListener(map, 'zoom_changed', function () {
    //     let zoomChangeBoundsListener =
    //         google.maps.event.addListener(map, 'bounds_changed', function (event) {
    //             // if (this.getZoom() > 15 && initialZoom == true) {
    //             if (initialZoom == true) {
    //                 // Change max/min zoom here
    //                 this.setZoom(this.getZoom() + 1);
    //                 initialZoom = false;
    //             }
    //             google.maps.event.removeListener(zoomChangeBoundsListener);
    //         });
    // });
    // let initialZoom = true;

    /**END */

    this.map?.fitBounds(this.mapBounds); // The bounds are added for zoom in
  }

  edgesSum(polygon: any): number {
    let res = 0;
    for (let i = 0; i < polygon.length; i++) {
      if (i == polygon.length - 1) {
        res =
          res +
          (polygon[0].lat - polygon[i].lat) * (polygon[0].lng + polygon[i].lng);
      } else {
        res =
          res +
          (polygon[i + 1].lat - polygon[i].lat) *
          (polygon[i + 1].lng + polygon[i].lng);
      }
    }
    return res;
  }

  setMapElements(
    locationIds: Address[] | undefined,
    mapRes: any,
    isOnInit: boolean
  ) {
    this.markerCluster?.setMap(null);
    if (!this.data?.clickSubscription) {
      this.mapPolygon?.setMap(null);
    }

    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i]?.setMap(null);
      this.markers[i] = null;
    }
    this.markers = [];

    if (mapRes) {
      let timelineLocationIds: Address[] | undefined = [];
      // if (isOnInit) {
      if (this.data?.content === 'home' || this.data?.content === 'drill') {
        timelineLocationIds = locationIds;

        mapRes?.map((res: any) => {
          timelineLocationIds?.map(async (loc: any) => {
            if (loc.id === res.location_id) {
              let aTimeline: Timeline[] = [];

              res.event_timeline?.map((tml: any) => {
                if (tml.record_type === 'event') {
                  aTimeline.push({
                    actMth: tml.activation_method_id,
                    lat: tml.event_latitude,
                    long: tml.event_longitude,
                    event_type_location_id: tml.event_type_location_id,
                    event_id: tml.event_id,
                  });
                }

                if (tml.record_type === 'attendance') {
                  aTimeline.push({
                    actMth: 'attendance',
                    lat: !tml.building_id.includes('00000000-0000')
                      ? tml.latitude
                      : loc.coors.lat(),
                    long: !tml.building_id.includes('00000000-0000')
                      ? tml.longitude
                      : loc.coors.lng(),
                    icon: 'map-pin--attendance.svg',
                    people: tml.people,
                    event_id: tml.event_id,
                  });
                }
              });

              // // Merge attendances by building
              // res.areas_attendance?.map((areaAttendance: any) => {

              //     let building = this.buildings[loc.id]?.find((b: any) => b.id === areaAttendance.id);
              //     if (building === undefined){
              //         building = {
              //           latitude: loc.coors.lat(),
              //           longitude: loc.coors.lng()
              //         }
              //     }
              //     aTimeline.push({
              //         actMth: "attendance",
              //         lat: building.latitude,
              //         long: building.longitude,
              //         icon: "map-pin--attendance.svg",
              //         people: areaAttendance.total_reported,
              //         event_id: areaAttendance.event_id
              //       })
              // })

              loc.timeline = aTimeline;
              loc.eventId = res.id;
            }
          });
        });
      }

      if (
        this.data?.content === 'eventHistory' ||
        this.data?.content === 'drillHistory'
      ) {
        
        mapRes?.map((resLoc: any) =>{
          locationIds?.map((loc: any) => {
            if (loc.id == resLoc.location_id) {
              if (resLoc.id === this.data?.eventHistory.id) {
                let aTimeline: Timeline[] = [];
                resLoc.event_timeline.map((tml: any) => {
                  if (tml.record_type === 'event') {
                    aTimeline.push({
                      actMth: tml.activation_method_id,
                      lat: tml.event_latitude,
                      long: tml.event_longitude,
                      event_type_location_id: tml.event_type_location_id,
                      event_id: tml.event_id,
                    });
                  }
                  if (tml.record_type === 'attendance') {
                    aTimeline.push({
                      actMth: 'attendance',
                      lat: !tml.building_id.includes('00000000-0000')
                        ? tml.latitude
                        : loc.coors.lat(),
                      long: !tml.building_id.includes('00000000-0000')
                        ? tml.longitude
                        : loc.coors.lng(),
                      icon: 'map-pin--attendance.svg',
                      people: tml.people,
                      event_id: tml.id,
                    });
                  }
                });
  
                timelineLocationIds?.push({
                  id: loc.id,
                  address: loc.address,
                  coors: loc.coors,
                  timeline: aTimeline,
                  eventId: resLoc.id,
                  locationName: loc.locationName,
                });
              }
            }
          });
        });
      }
      
      if (!this.data?.clickSubscription) {
        if (timelineLocationIds) {
          this.drawLocations(timelineLocationIds);
        }
      } else {
        if (timelineLocationIds) {
          this.drawnLocations = this.addressToLocCoors(timelineLocationIds);
        }
      }
    } else {
      if (locationIds) this.drawLocations(locationIds);
    }
  }

  addressToLocCoors(locations: Address[]): LocationCoors[] {
    let locCoors: LocationCoors[] = [];
    locations.map((l: any) => {
      locCoors.push({
        id: l.id,
        address: l.address,
        locationName: l.locationName,
        location: {
          lat: l.coors.lat(),
          lng: l.coors.lng(),
        },
        timeline: l.timeline,
        eventId: l.eventId,
      });
    });

    return locCoors;
  }

  drawLocations(locations: Address[]) {
    let foundStored = false;
    if (sessionStorage.getItem('locationCoors')) {
      let strObj = sessionStorage.getItem('locationCoors');
      if (strObj) {
        let obj = JSON.parse(strObj);
        for (let i = 0; i < obj.length; i++) {
          this.storedLocationCoors[i] = obj[i];
        }
        foundStored = true;
      }
    }

    /**Remove the markers */
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i]?.setMap(null);
      this.markers[i] = null;
    }

    for (let i = 0; i < this.actMethMarkers.length; i++) {
      this.actMethMarkers[i].setMap(null);
    }

    this.markers = [];
    this.actMethMarkers = [];

    this.mapBounds = new google.maps.LatLngBounds(); // Para poder hacer zoom a los markers dibujados

    if (foundStored) {
      let counter = 0;
      let locationToDraw: LocationCoors[] = [];
      locations?.map((l: any) => {
        this.storedLocationCoors.map((slc: any) => {
          if (slc.id === l.id) {
            counter++;
          }
        });
      });
      if (counter === locations?.length) {
        // To make sure that all locatios are in the previous stored loations from session
        locations?.map((l: any) => {
          this.storedLocationCoors?.map((slc: any) => {
            if (slc.id === l.id) {
              if (l.eventId) {
                locationToDraw.push({ ...slc, timeline: l.timeline });
              } else {
                locationToDraw.push({
                  ...slc,
                  eventId: l.eventId,
                });
              }

              this.mapBounds?.extend(slc.location); // Add the position to the bouns of the map
            }
          });
        });
        this.drawnLocations = locationToDraw;

        if (!this.data?.clickSubscription) {
          this.drawMarkers(locationToDraw);
        }
      } else {
        let missingLocIds: Address[] = [];
        locations?.map((loc: any) => {
          let foundLocation = false;
          this.storedLocationCoors?.map((slc: any) => {
            if (slc.id === loc.id) {
              foundLocation = true;
            }
          });
          if (!foundLocation) {
            missingLocIds.push(loc);
          }
        });
        missingLocIds?.map((ml: any) => {
          this.storedLocationCoors.push({
            id: ml.id,
            address: ml.address,
            location: {
              lat: ml.coors.lat(),
              lng: ml.coors.lng(),
            },
            timeline: ml.timeline,
            eventId: ml.eventId,
            locationName: ml.locationName,
          });
          try {
            sessionStorage.setItem(
              'locationCoors',
              JSON.stringify(this.storedLocationCoors)
            );
          } catch (e) {
            sessionStorage.removeItem('locationCoors');
          }
        });
        this.storedLocationCoors?.map((slc: any) => {
          locations?.map((l: any) => {
            if (slc.id === l.id) {
              locationToDraw.push({
                id: l.id,
                address: l.address,
                location: {
                  lat: l.coors.lat(),
                  lng: l.coors.lng(),
                },
                timeline: l.timeline,
                eventId: l.eventId,
                locationName: l.locationName,
              });
              this.mapBounds?.extend(slc.location); // Add the position to the bouns of the map
            }
          });
        });
        this.drawnLocations = locationToDraw;
        if (!this.data?.clickSubscription) {
          this.drawMarkers(locationToDraw);
        }
      }
    } else {
      let locationToDraw: LocationCoors[] = [];
      locations?.map((loc: any) => {
        this.storedLocationCoors.push({
          id: loc.id,
          address: loc.address,
          location: {
            lat: loc.coors.lat(),
            lng: loc.coors.lng(),
          },
          timeline: loc.timeline,
          eventId: loc.eventId,
          locationName: loc.locationName,
        });
        try {
          sessionStorage.setItem(
            'locationCoors',
            JSON.stringify(this.storedLocationCoors)
          );
        } catch (e) {
          sessionStorage.removeItem('locationCoors');
        }
      });
      this.storedLocationCoors?.map((slc: any) => {
        locations?.map((l: any) => {
          if (slc.id === l.id) {
            locationToDraw.push({
              id: l.id,
              address: l.address,
              location: {
                lat: l.coors.lat(),
                lng: l.coors.lng(),
              },
              timeline: l.timeline,
              eventId: l.eventId,
              locationName: l.locationName,
            });
            this.mapBounds?.extend(slc.location); // Add the position to the bounds of the map
          }
        });
      });
      this.drawnLocations = locationToDraw;
      if (!this.data?.clickSubscription) {
        this.drawMarkers(locationToDraw);
      }
    }
  }

  drawLocationAllInfo() {
    let locationCoor = new google.maps.LatLng(
      this.location.latitude,
      this.location.longitude
    );

    if (this.content === 'location-info') {
      this.drawLocationPolygon(this.location);
      if (this.mapBounds) this.map?.setCenter(this.mapBounds.getCenter());
      // this.map?.setZoom(17);
    }
  }

  drawMarkers(locations: LocationCoors[]) {
    // this.drawnLocations = locations;
    locations?.map((loc: any) => {
      if (this.content === 'group-info') {
        let svgMarker = {
          path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
          fillColor: '#606060',
          fillOpacity: 0.6,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(15, 30),
        };
        let marker = new google.maps.Marker({
          position: loc.location,
          map: this.map,
          icon: svgMarker,
          title: loc.locationName,
          // optimized: false,
          // zIndex: 10
        });

        this.markers.push(marker);
      } else {
        if (this.data?.eventsRes) {
          if (this.data?.eventsRes?.length > 0) {
            for (let i = 0; i < this.data.eventsRes.length; i++) {
              if (loc.eventId == this.data.eventsRes[i].id) {
                let genEvId = this.data.eventsRes[i].general_event_type_id;
                let eventTypesData: any = this.data.eventTypes;
                if (eventTypesData) {
                  for (let j = 0; j < eventTypesData.length; j++) {
                    if (eventTypesData[j].location_id === loc.id) {
                      let evColor;
                      let evTypeName;
                      for (
                        let k = 0;
                        k < eventTypesData[j].eventTypes.length;
                        k++
                      ) {
                        let found = false;
                        if (eventTypesData[j].eventTypes[k].id === genEvId) {
                          found = true;
                          evColor = eventTypesData[j].eventTypes[k].color;
                          evTypeName = eventTypesData[j].eventTypes[k].name;
                        } else {
                          let eventTypesArr =
                            eventTypesData[j].eventTypes[k].event_types;
                          if (eventTypesArr) {
                            for (let n = 0; n < eventTypesArr.length; n++) {
                              if (eventTypesArr[n].id === genEvId) {
                                found = true;
                                evColor = eventTypesArr[n].color;
                                evTypeName = `${eventTypesData[j].eventTypes[k].name}: ${eventTypesArr[n].name}`;
                              }
                            }
                          }
                        }

                        if (found) {
                          if (!this.data?.clickSubscription) {
                            let svgMarker = {
                              url: `./assets/images/SVG/map-pins/map-pin--${eventTypesData[j].eventTypes[k].icon}`,
                              scaledSize: new google.maps.Size(70, 70),
                              origin: new google.maps.Point(0, 0),
                            };

                            let marker = new google.maps.Marker({
                              position: loc.location,
                              map: this.map,
                              icon: svgMarker,
                              // optimized: false,
                              // zIndex: 10
                            });

                            marker.set(
                              'id',
                              this.data?.eventsRes?.[i].location_id
                            );

                            let contentString =
                              '<div id="content">' +
                              '<p class="loc__name">' +
                              loc.locationName +
                              '</p>' +
                              '<p class="event__color" style="color:' +
                              evColor +
                              ';">' +
                              evTypeName +
                              '</p>' +
                              '</div>';

                            let infowindow = new google.maps.InfoWindow({
                              content: contentString,
                            });

                            marker.addListener('mouseover', () => {
                              infowindow.open(this.map, marker);
                            });

                            marker.addListener('mouseout', () => {
                              infowindow.close();
                            });

                            this.markers.push(marker);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            let foundLoc = this.data.eventsRes.find(
              (evLoc: any) => evLoc.location_id == loc.id
            );
            if (!foundLoc) {
              let svgMarker = {
                path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                fillColor: '#606060',
                fillOpacity: 0.6,
                strokeWeight: 0,
                rotation: 0,
                scale: 2,
                anchor: new google.maps.Point(15, 30),
              };
              let marker = new google.maps.Marker({
                position: loc.location,
                map: this.map,
                icon: svgMarker,
                title: loc.locationName,
                // optimized: false,
                // zIndex: 10
              });

              this.markers.push(marker);
            }
          } else if (this.data?.eventsRes?.length === 0) {
            let svgMarker = {
              path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
              fillColor: '#606060',
              fillOpacity: 0.6,
              strokeWeight: 0,
              rotation: 0,
              scale: 2,
              anchor: new google.maps.Point(15, 30),
            };
            let marker = new google.maps.Marker({
              position: loc.location,
              map: this.map,
              icon: svgMarker,
              title: loc.locationName,
              // optimized: false,
              // zIndex: 10
            });

            this.markers.push(marker);
          }
        }
      }
    });

    if (this.markers.length < 2) {
      const center = this.mapBounds?.getCenter() || new google.maps.LatLng(0);
      if (this.mapBounds) this.map?.setCenter(center);
    } else {
      if (this.map)
        this.markerCluster = new MarkerClusterer(this.map, this.markers, {
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        });
      if (this.mapBounds) this.map?.fitBounds(this.mapBounds);
    }

    this.markers?.map((mrkr: any) => {
      mrkr.addListener('click', () => {
        // for (let i = 0; i < this.actMethMarkers.length; i++) {
        //     this.actMethMarkers[i].setMap(null);
        //     this.actMethMarkers[i] = null;
        // }
        // this.actMethMarkers = []
        // this.map?.setCenter(mrkr.getPosition() as google.maps.LatLng);
        // this.map?.fitBounds(this.mapBounds)
        // let position = {
        //     lat: (mrkr.getPosition() as google.maps.LatLng).lat(),
        //     lng: (mrkr.getPosition() as google.maps.LatLng).lng()
        // }

        locations?.map((loc: any) => {
          // if (loc.location.lat === position.lat && loc.location.lng === position.lng) {
          //     if (loc.timeline) {
          /**TODO: zoom in the location and expand timeline */
          // this.eventsService.sendRefreshHomeComponents(loc.eventId, '');
          // let mobileOnce = false;
          // loc.timeline?.map(tml => {
          //     let actMeth = this.getSelectedActivationMethod(tml.actMth);
          //     let actMethPosition = {
          //         lat: tml.lat,
          //         lng: tml.long
          //     }
          //     let actMethMarker: any;
          //     if (actMeth.description.toLowerCase().includes('mobile') && !mobileOnce) {
          //         mobileOnce = true;
          //         actMethMarker = new google.maps.Marker({
          //             position: actMethPosition,
          //             map: this.map,
          //             icon: `./assets/images/icons/map_${actMeth.icon}`,
          //             // optimized: false,
          //             // zIndex: 10
          //         });
          //         this.actMethMarkers.push(actMethMarker);
          //         // this.actMethMapBounds.extend(actMethPosition);
          //     }
          //     if (!actMeth.description.toLowerCase().includes('mobile')) {
          //         actMethMarker = new google.maps.Marker({
          //             position: actMethPosition,
          //             map: this.map,
          //             icon: `./assets/images/icons/map_${actMeth.icon}`,
          //             // optimized: false,
          //             // zIndex: 10
          //         });
          //         this.actMethMarkers.push(actMethMarker);
          //     }
          //         // })
          //     }
          // }
        });
      });
    });
  }

  eventToMapMarker(locationId: string | undefined, isAttendance?: boolean) {
    for (let i = 0; i < this.drawnLocations?.length; i++) {
      if (this.drawnLocations[i].id === locationId) {
        for (let k = 0; k < this.actMethMarkers.length; k++) {
          this.actMethMarkers[k].setMap(null);
          this.actMethMarkers[k] = null;
        }
        this.actMethMarkers = [];
        let locEveTyp = this.data?.eventTypes?.find(
          (evt: any) => evt.location_id == this.drawnLocations[i].id
        );
        let totalAttendance = 0;
        let timelineDrawLoc = this.drawnLocations[i].timeline;
        if (timelineDrawLoc) {
          for (let j = 0; j < timelineDrawLoc.length; j++) {
            let actMeth, evtCode;
            let actMethPosition = {
              lat: timelineDrawLoc[j].lat,
              lng: timelineDrawLoc[j].long,
            };
            let actMethMarker: any = null;
            if (isAttendance) {
              if (timelineDrawLoc[j].actMth == 'attendance') {
                let svgMarker = {
                  url: `./assets/images/SVG/map-pins/${timelineDrawLoc[j].icon}`,
                  // path: svgIcon,
                  scaledSize: new google.maps.Size(70, 70),
                  origin: new google.maps.Point(0, 0),
                };
                let peopleText = timelineDrawLoc[j].people;
                actMethMarker = new google.maps.Marker({
                  position: actMethPosition,
                  map: this.map,
                  icon: svgMarker,
                  // optimized: false,
                  // zIndex: 10,
                  label: {
                    text: peopleText ? peopleText.toString() : '',
                    color: 'white',
                    className: 'badge-marker',
                  },
                });

                actMethMarker.set('id', timelineDrawLoc[j].event_id);
                this.actMethMarkers.push(actMethMarker);
              }
            } else {
              if (timelineDrawLoc[j].actMth == 'attendance') {
                let peopleNum = timelineDrawLoc[j].people;
                if (peopleNum) {
                  totalAttendance += peopleNum;
                }
              } else {
                actMeth = this.getSelectedActivationMethod(
                  timelineDrawLoc[j].actMth
                );

                evtCode = locEveTyp?.eventTypes.find((evt: any) => {
                  if (timelineDrawLoc) {
                    if (evt.id == timelineDrawLoc[j].event_type_location_id) {
                      return evt;
                    } else {
                      let timelineDrawLocPos = timelineDrawLoc[j];
                      if (timelineDrawLocPos != undefined) {
                        let nestEv = evt.event_types.find(
                          (nevt: any) =>
                            nevt.id == timelineDrawLocPos.event_type_location_id
                        );
                        if (nestEv) {
                          return { ...nestEv, code: evt.code };
                        }
                      }
                    }
                  }
                })?.code;
                if (
                  evtCode &&
                  !actMeth.description.toLowerCase().includes('web')
                ) {
                  let svgMarker = {
                    url: `./assets/images/SVG/map-pins/map-pin--${evtCode}-${actMeth.icon}`,
                    scaledSize: new google.maps.Size(70, 70),
                    origin: new google.maps.Point(0, 0),
                  };

                  actMethMarker = new google.maps.Marker({
                    position: actMethPosition,
                    map: this.map,
                    icon: svgMarker,
                    // optimized: false,
                    // zIndex: 10,
                    // animation: google.maps.Animation.DROP
                  });
                  actMethMarker.set('id', timelineDrawLoc[j].event_id);
                  this.actMethMarkers.push(actMethMarker);
                }

                if (actMethMarker) {
                  var selfComponent =
                    this; /**It is necesary to call global variables inside the listener */
                  // google.maps.event.addListener(actMethMarker, "click", function () {
                  actMethMarker.addListener('click', function () {
                    let selfTimelineLoc =
                      selfComponent.drawnLocations[i].timeline;
                    if (selfTimelineLoc) {
                      selfComponent.eventsService.sendEventsSubs({
                        channel: 'map-timeline',
                        broadcastID: 'MapComponent',
                        payload: {
                          eventId: selfTimelineLoc[j].event_id,
                          isGlow: true,
                        },
                      });
                      setTimeout(() => {
                        if (selfTimelineLoc) {
                          selfComponent.eventsService.sendEventsSubs({
                            channel: 'map-timeline',
                            broadcastID: 'MapComponent',
                            payload: {
                              eventId: selfTimelineLoc[j].event_id,
                              isGlow: false,
                            },
                          });
                        }
                      }, 3000);
                    }
                  });
                }
              }
            }
          }
        }

        if (totalAttendance > 0) {
          let svgMarker = {
            url: `./assets/images/SVG/map-pins/map-pin--attendance.svg`,
            // path: svgIcon,
            scaledSize: new google.maps.Size(70, 70),
            origin: new google.maps.Point(0, 0),
          };
          let actMethMarker = new google.maps.Marker({
            position: this.drawnLocations[i].location,
            map: this.map,
            icon: svgMarker,
            // optimized: false,
            // zIndex: 10,
            label: {
              text: totalAttendance.toString(),
              color: 'white',
              className: 'badge-marker',
            },
          });

          actMethMarker.set('id', 'total-attendances');
          this.actMethMarkers.push(actMethMarker);
        }
        break;
      }
    }
  }

  setActivationMethods() {
    if (sessionStorage.getItem('activationMethods')) {
      let strObj = sessionStorage.getItem('activationMethods');
      if (strObj) {
        let obj = JSON.parse(strObj);
        for (let i = 0; i < obj.length; i++) {
          this.activationMethods.push(obj[i]);
        }
      }
    } else {
      let activationMethods: ActivationMethod[] = [];
      this.oneTimeService.getActivationMethods().subscribe(
        (res: any) => {
          res.map((item: any) => {
            activationMethods.push({
              id: item.id,
              description: item.description,
              backgroundColor: item.background_color,
              icon: item.icon,
            });
          });
          sessionStorage.setItem(
            'activationMethods',
            JSON.stringify(activationMethods)
          );
          this.setActivationMethods();
        },
        (error) => {
          this.openSnackBar(error.message, 'Ok');
        }
      );
    }
  }

  getSelectedActivationMethod(activationMethodId: string | undefined) {
    let selectedActivatioMethod: ActivationMethod = {
      id: 'not found',
      backgroundColor: 'yellow',
      description: 'No type',
      icon: '',
    };
    this.activationMethods?.map((item: any) => {
      if (activationMethodId === item.id) {
        selectedActivatioMethod = item;
      }
    });
    return selectedActivatioMethod;
  }

  addAddressToLocation(selectedIds: string[]) {
    let locationIds: Address[] = [];
    if (this.content === 'group-info') {
      let i = 0;
      selectedIds?.map((slLoc: any) => {
        this.locationService?.getLocation(slLoc).subscribe(
          (res: any) => {
            i++;
            locationIds.push({
              id: res.id,
              address: res.address,
              coors: new google.maps.LatLng(res.latitude, res.longitude),
              locationName: res.name,
            });
            if (i === selectedIds.length) {
              this.setMapElements(locationIds, undefined, false);
            }
          },
          (error) => {
            this.openSnackBar(error.message, 'Ok');
          }
        );
      });
    } else {
      if (this.data?.locationAddress.length > 0) {
        this.data.locationAddress?.map((aL: any) => {
          selectedIds?.map((item: any) => {
            if (aL.id === item) {
              locationIds.push({
                id: aL.id,
                address: aL.address,
                coors: aL.coors,
                locationName: aL.locationName,
              });
            }
          });
        });
        this.setMapElements(locationIds, this.data.eventsRes, false);
      } else {
        let i = 0;
        selectedIds?.map((slLoc: any) => {
          this.locationService.getLocation(slLoc).subscribe({
            next: (res: any) => {
              i++;
              locationIds.push({
                id: res.id,
                address: res.address,
                coors: new google.maps.LatLng(res.latitude, res.longitude),
                locationName: res.name,
              });
              if (i === selectedIds.length) {
                this.setMapElements(locationIds, this.data?.eventsRes, false);
              }
              if (
                this.data?.content === 'eventHistory' ||
                this.data?.content === 'drillHistory'
              ) {
                this.drawLocationPolygon(res);
                this.eventToMapMarker(res.id);
              }
            },
            error: (error) => {
              this.openSnackBar(error.message, 'Ok');
            },
          });
        });
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BlueprintsService } from "src/app/core/services/blueprints.service";

export interface DialogData {
  title: string,
  content: string,
  area: any,
  blueprintId: string,
  blueprints: Array<any>,
  event: any
  genEvType: any
  genActMeht: string
}

@Component({
  templateUrl: "./dialog-blueprint.component.html",
  styleUrls: ["./dialog-blueprint.component.scss"],
})
export class DialogBlueprintComponent {

  isImageLoading: boolean = false;
  imageToShow: any;
  blueprint: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogBlueprintComponent>,
    private bluepintsService: BlueprintsService) { }


  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
      //  console.log("image to show", this.imageToShow);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getBlueprintImg(blueprintId: string) {
    this.isImageLoading = true;
    this.bluepintsService.getbBlueprintImg(this.data.area.location_id, this.data.area.id, blueprintId).subscribe((res: any) => {
      this.createImageFromBlob(res);
      this.isImageLoading = false;
    }, error => {
      // console.log(error);
      this.openSnackBar(error.message, 'Ok');
      this.isImageLoading = true;
    })
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  closeDialog(msg: string): void {
    this.dialogRef.close(msg);
  }



  ngOnInit() {
    console.log("data",  this.data)
    this.blueprint = this.data.blueprints.find((b: any)  => b.id == this.data.blueprintId);
    this.getBlueprintImg(this.data.blueprintId);

  }

}





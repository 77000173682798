import { animate, state, style, transition, trigger } from "@angular/animations";

/// Collapse animation trigger
export const toggleCollapseTrigger = trigger("toggleCollapse", [
    transition(':enter', [
        style({ height: 0, opacity: 0}),
        animate('200ms ease-out', style({height: "*", opacity: 1}))
    ]),
    transition(':leave', [
        style({ height: "*", opacity: 1 }),
        animate('200ms ease-in', style({ height: 0, opacity: 0}))
    ])
])

/// Rotates an icon by 180 degrees
export const rotateIcon180Trigger = trigger("rotateIcon180", [
    state("default", style({ transform: 'rotate(0)' })),
    state("rotated", style({ transform: 'rotate(180deg)' })),
    transition('default => rotated', animate('200ms ease-out')),
    transition("rotated => default", animate("200ms ease-in"))
])
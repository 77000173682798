<div class="container_donuts">
    <div>
        <span *ngIf="dataShared.isGroup" class="img-donut">
            <img width="30" src="./assets/images/SVG/icon_siteGroup.svg" alt="">
        </span>
        <span *ngIf="!dataShared.isGroup" class="img-donut">
            <img width="30" src="./assets/images/SVG/Icon_Location.svg" alt="">
        </span>
        <span class="donut-title">{{ dataShared.name }}</span>
    </div>
    <div class="groups-chart-box">
        <div class="groups__chart-legends">
            <ul>
                <li class="groups__chart-legend" *ngFor="let event of eventLabels; index as i">
                    <div class="groups_chart-color" [style.background-color]="backgroundColor[i]"></div>
                    {{ eventCounts[i] }} <span *ngIf="percentage">%</span>
                </li>
                <li class="groups__chart-legend" *ngIf="eventLabels.length <= 0">
                    <div class="groups_chart-color" [style.background-color]="backgroundColor[0]"></div>
                    0 <span *ngIf="percentage">%</span>
                </li>
            </ul>
        </div>
        <div *ngIf="eventCounts.length > 0" class="groups__chart">
            <canvas
                baseChart
                [type]="'doughnut'"
                [datasets]="[{ data: eventCounts, backgroundColor: backgroundColor }]"
                [labels]="eventLabels"
                [options]="lineChartOptions"
                [legend]="false">
            </canvas>
            <div class="whole-total-groups">
                <div>{{ totalEvents }}</div>
            </div>
        </div>
    </div>
</div>

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { NewEventComponent } from '../new-event/new-event.component';
import { EventsService } from 'src/app/core/services/events.service';
import { WebsocketService } from '../../../core/services/websocket.service';
import { LocationService } from '../../../core/services/location.service';
import { DrillService } from 'src/app/core/services/drills.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { SelectedLocationsService } from 'src/app/core/services/selected-locations.service';
import { CreateClearEventComponent } from '../create-clear-event-dialog/create-clear-event.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  EventTypesLocation,
  eventTypesWithCustomEvents,
  mapEventTypestoModel,
} from 'src/app/core/models/event-types-location';
import { Account, UserProfile } from 'src/app/core/models/user-profile.model';
import { UsersService } from 'src/app/core/services/users.service';
import { AuthInterceptorInterceptor } from 'src/app/core/interceptor/auth-interceptor.interceptor';

interface DataWBContent {
  id: string;
  user_id: string;
  location_id: string;
  type: number;
}

interface DataWebsocket {
  token: Token;
}

interface Token {
  baseUrl: string;
  token: string;
  url: string;
}

interface EventMessageWS {
  type: string;
  from?: string;
  fromUserId?: null;
  group?: string;
  dataType?: string;
  data?: string;
  event?: string;
  userId?: string;
  connectionId?: string;
}

@Component({
  selector: 'hub-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tooltip')
  tooltip!: MatTooltip;

  token: string | null = '';
  userInfo: any = null;

  userProfile: any;
  name = '';
  fullName: string;
  passwordResetAuthority = `https://${environment.azure_b2c.tenant}.b2clogin.com/${environment.azure_b2c.tenant}.onmicrosoft.com/${environment.azure_b2c.userFlows.passwordReset}`;
  supports = [
    'HHUB',
    'Alert-Mobile',
    'Connect-Display',
    'Detect-Amberbox',
    'Detect-Thermal',
    'Feedback',
    'Other',
  ];
  mailtoMessage = 'mailto:support@havrion.com';
  locations: any = [];
  activeEvents = [];
  multipleEvents = false;
  maxEvent: any;
  timelineRes: any;
  eventTypes: any = [];
  activeDrills: any;
  events: EventTypesLocation[] = [];
  disableActivateEvent = true;
  selectedLocId: string = '';
  allLocInfo = [];
  ws: any = undefined;
  location_id: string = '';
  drillInfo: any;
  userAccounts: Account[] = [];
  profileUser: UserProfile | null = null;

  messageOnTooltip =
    'Activate Event not available for multiple locations. Please select a single location';

  private refreshHeaderSubscription: Subscription;
  private locationsSubscription: Subscription | null = null;
  private drillsSubscription: Subscription;
  private systemModeSubscription: Subscription;

  eventInstance: any;
  eventsTypes: any;
  loadingData: boolean = false;
  nestedEvents: any;
  disabled: boolean = false;

  constructor(
    private authService: MsalService,
    public router: Router,
    private dialog: MatDialog,
    private _wsService: WebsocketService,
    private eventsService: EventsService,
    private _locationService: LocationService,
    private drillsService: DrillService,
    private selectedLocationsService: SelectedLocationsService,
    private _cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private usersService: UsersService,
    private authInterceptorInterceptor: AuthInterceptorInterceptor
  ) {
    this.token = this.authInterceptorInterceptor.idToken;
    if (this.token) this.userInfo = jwt_decode(this.token);
    this.name = this.userInfo.name;

    let ssUP = sessionStorage.getItem('user-profile');
    if (ssUP) this.userProfile = JSON.parse(ssUP);
    this.fullName = this.userProfile.full_name;

    this.refreshHeaderSubscription = this.eventsService
      .getRefreshHeader()
      .subscribe(() => {
        this.getTimeLinePriority();
      });

    this.drillsSubscription = this.drillsService
      .getActivateHeaderDrillMode()
      .subscribe((res) => {
        if (res.activate) {
          this.drillInfo = res.info;
        } else {
          this.drillInfo = undefined;
        }
      });

    this.systemModeSubscription = this._locationService
      .getSystemModeChange()
      .subscribe(() => {
        this.getLocations();
      });
  }

  getLocationsWithIssues() {
    this._locationService.getLocationWithIssues().subscribe({
      next: (res: any) => {
        // let newLoc = [
        //     "8E67AE2F-0432-45D4-BE85-94C3498560B6",
        //     "74C035F5-8E5C-491D-A7C2-21F148DC8E6B",
        //     "D3D1CB18-AD47-410A-9F32-2F385A139E40",
        //     "CC213EE2-4BE7-4893-B387-82AD727AFD76"]
        // let newLoc = ['3FF30CEC-3ECD-4FD7-B724-7B799AF5AF00', '5FFAD2A8-CE61-47D8-B0D0-C5A1A1D48BB6'];
        this._locationService.sendLocIssuesArr(res.location_ids);
        sessionStorage.setItem('locIssues', JSON.stringify(res.location_ids));
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      },
    });
  }

  resetPassword() {
    // this.authService.authority = this.passwordResetAuthority;
    // this.authService.loginRedirect();
    this.authService.loginRedirect({
      scopes: ['openid'],
      authority: this.passwordResetAuthority,
    });
  }

  ngAfterViewInit() {
    this.locationsSubscription =
      this.selectedLocationsService.selectedLocationsObservable.subscribe(
        (selectedLocIds) => {
          if (selectedLocIds.length === 1) {
            this.disableActivateEvent = false;
            this.selectedLocId = selectedLocIds[0];
          } else {
            this.disableActivateEvent = true;
          }

          if (selectedLocIds.length >= 1) {
            this.loadingData = true;
            setTimeout(() => {
              this.loadingData = false;
            }, 2000);
          } else {
            this.loadingData = false;
          }
        }
      );
    this._cdr.detectChanges();
  }

  showingEvents(locationId: string) {
    this.events = [];
    let locationFound = this.eventInstance.locations.find(
      (evLocs: any) => evLocs.location_id == locationId
    );
    let locationDrillFound = this.drillInfo?.activeLocations.find(
      (actLoc: any) => actLoc.location == locationId
    );
    if (locationFound) {
      let allEvents: any = this.eventTypes.find(
        (ev: any) => ev.locationId == locationId
      );

      allEvents?.eventTypes?.map((ev: any) => {
        if (
          ev.priority >= locationFound.event_type_priority &&
          ev.id !== locationFound.event_type_location_id
        ) {
          /** to disable shelter events buttons */
          if (ev.event_types.length > 0) {
            ev.event_types.map((shtrEv: any) => {
              if (locationFound.event_type_location_id === shtrEv.id) {
                shtrEv.disabled = true;
              }
            });
          }

          this.events.push({ ...ev, disabled: false });
        } else {
          this.events.push({ ...ev, disabled: true });
        }
      });
    } else if (this.drillInfo && locationDrillFound) {
      let eventType: any;
      this.drillInfo.eventTypes.map((item: any) => {
        if (item.id == locationDrillFound.eventId) {
          eventType = item;
        }
        if (item.event_types.length > 0) {
          item.event_types.map((nestev: any) => {
            if (nestev.id == locationDrillFound.eventId) {
              eventType = nestev;
            }
          });
        }
      });

      this.drillInfo.eventTypes.map((item: any) => {
        if (
          (item.priority < eventType.priority &&
            item.location_id == locationDrillFound.location) ||
          item.id == eventType.id
        ) {
          this.events.push({ ...item, disabled: true });
        } else {
          if (item.location_id == locationDrillFound.location) {
            this.events.push({ ...item, disabled: false });
          }
        }
      });
      this.events.map((ev: any) => {
        if (ev.event_types.length > 0) {
          ev.event_types.map((nestEv: any) => {
            if (nestEv.id == eventType.id) {
              nestEv.disabled = true;
            } else {
              nestEv.disabled = false;
            }
          });
        }
      });

      this.events = eventTypesWithCustomEvents(this.events);
    } else {
      let payload = {
        location_ids: [locationId],
      };
      /**Show all active event buttons as enable button */
      this.eventsService.getEventTypes(payload).subscribe({
        next: (allEvents: any) => {
          let singleEvents = this.events;
          allEvents.map((item: any) => {
            item.event_types.map((ev: any) => {
              let event = mapEventTypestoModel(ev);
              singleEvents.push(event);
            });
          });
          this.events = eventTypesWithCustomEvents(singleEvents);
        },
        error: (error) => {
          this.openSnackBar(error.message, 'Ok');
        },
      });
    }
  }

  ngOnDestroy() {
    this.refreshHeaderSubscription?.unsubscribe();
    this.locationsSubscription?.unsubscribe();
    this.drillsSubscription?.unsubscribe();
    this.systemModeSubscription?.unsubscribe();
    this.ws?.close();
  }

  websocketFunc(userData: any, group: any) {
    this._wsService.negotiate().subscribe({
      next: (data: any) => {
        if (!this.ws) {
          this.ws = new WebSocket(data.token.url, 'json.webpubsub.azure.v1');
          this.ws.onopen = () => {
            this.ws.send(
              JSON.stringify({
                type: 'joinGroup',
                group: group,
              })
            );
          };
        }

        this.ws.onmessage = (event: any) => {
          let message: EventMessageWS = JSON.parse(event.data);
          // console.log('ws message', message);

          if (message.type === 'message') {
            this.dataWScheck(message, userData);
          }
        };
      },
    });
  }

  async dataWScheck(message: any, userData: any) {
    await this.getTimeLinePriority();
    let dataContent: DataWBContent = JSON.parse(message.data);
    // console.log('dataContent WS', dataContent);

    if (dataContent.user_id != userData.id) {
      if (dataContent.type == 2) {
        if (
          this.router.url === '/secured/home' ||
          this.router.url ==
            `/secured/home?locationId=${dataContent.location_id}`
        ) {
          this.eventsService.sendRefreshHome();
        }
        if (
          this.router.url === '/secured/drill' ||
          this.router.url ==
            `/secured/drill?locationId=${dataContent.location_id}` ||
          this.router.url == '/secured/drill/active-drills'
        ) {
          this.eventsService.sendRefreshHome();
        }
      } else {
        await this.getDrillEvents(dataContent.location_id);
        this.eventsService
          .getEventInstance(dataContent.location_id, dataContent.id)
          .subscribe({
            next: (event: any) => {
              this.eventsService
                .getEventType(
                  dataContent.location_id,
                  event.event_type_location_id
                )
                .subscribe({
                  next: (alert: any) => {
                    let isDrill = false;
                    this.activeDrills?.map((actDrl: any) => {
                      if (
                        actDrl.event_instance_group_id ===
                        event.event_instance_group_id
                      ) {
                        isDrill = true;
                      }
                    });
                    this._locationService
                      .getLocation(dataContent.location_id)
                      .subscribe({
                        next: (location: any) => {
                          const dialogRef = this.dialog.open(
                            NewEventComponent,
                            {
                              data: {
                                name: alert,
                                locationId: dataContent.location_id,
                                locationName: location.name,
                                type: dataContent.type,
                                isDrill: isDrill,
                              },
                              disableClose: true,
                            }
                          );

                          dialogRef.afterClosed().subscribe((result) => {
                            if (result.msg === 'dismiss') {
                              if (!isDrill) {
                                if (this.router.url === '/secured/home' || this.router.url == `/secured/home?locationId=${dataContent.location_id}`) {
                                  // this.eventsService.sendRefreshHome();
                                  this.router.navigateByUrl('/', { skipLocationChange: true })
                                  .then(() => {
                                    this.router.navigate(['/secured/home']);
                                  });
                                }
                                if (this.router.url === '/secured/settings') {
                                  this.eventsService.sendRefreshSolutionManagement();
                                }
                              } else {
                                if (this.router.url === '/secured/drill') {
                                  this.router
                                    .navigateByUrl('/', {
                                      skipLocationChange: true,
                                    })
                                    .then(() => {
                                      this.router.navigate(['/secured/drill']);
                                    });
                                }
                              }
                            } else if (result.msg === 'view') {
                              if (result.type === 1) {
                                if (!isDrill) {
                                  if (
                                    this.router.url ===
                                    '/secured/events-history'
                                  ) {
                                    this.router
                                      .navigateByUrl('/', {
                                        skipLocationChange: true,
                                      })
                                      .then(() => {
                                        this.router.navigate([
                                          '/secured/events-history',
                                        ]);
                                      });
                                  } else {
                                    this.router.navigateByUrl(
                                      '/secured/events-history'
                                    );
                                  }
                                  setTimeout(() => {
                                    this.eventsService.sendOpenHistory(
                                      event.event_instance_group_id
                                    );
                                  }, 5000);
                                } else {
                                  if (this.router.url === '/secured/drill') {
                                    this.router
                                      .navigateByUrl('/', {
                                        skipLocationChange: true,
                                      })
                                      .then(() => {
                                        this.router.navigate([
                                          '/secured/drill',
                                        ]);
                                      });
                                  } else {
                                    this.router.navigateByUrl('/secured/drill');
                                  }

                                  setTimeout(() => {
                                    this.eventsService.sendOpenDrillHistory(
                                      event.event_instance_group_id,
                                      dataContent.location_id
                                    );
                                  }, 5000);
                                }
                              } else {
                                if (!isDrill) {
                                  if (this.router.url != '/secured/home' || this.router.url != `/secured/home?locationId=${dataContent.location_id}`) {
                                    this.router.navigateByUrl('/secured/home');
                                  }
                                } else {
                                  if (this.router.url === '/secured/drill' || this.router.url == `/secured/drill?locationId=${dataContent.location_id}`) {
                                    this.router
                                      .navigateByUrl('/', {
                                        skipLocationChange: true,
                                      })
                                      .then(() => {
                                        this.router.navigate([
                                          '/secured/drill/active-drills',
                                        ]);
                                      });
                                  } else {
                                    this.router.navigateByUrl(
                                      '/secured/drill/active-drills'
                                    );
                                  }
                                }
                                this.eventsService.sendRefreshHomeComponents(
                                  '',
                                  result.locationId,
                                  true
                                );
                              }
                            } else {
                              console.log('The dialog was closed');
                            }
                          });
                        },
                        error: (error) => {
                          this.openSnackBar(error.message, 'Ok');
                        },
                      });
                  },
                  error: (error) => {
                    this.openSnackBar(error.message, 'Ok');
                  },
                });
            },
            error: (error) => {
              this.openSnackBar(error.message, 'Ok');
            },
          });
      }
    } else {
      if (
        this.router.url === '/secured/home' ||
        this.router.url == `/secured/home?locationId=${dataContent.location_id}`
      ) {
        this.eventsService.sendRefreshHome();
      }
      if (
        this.router.url === '/secured/drill' ||
        this.router.url ==
          `/secured/drill?locationId=${dataContent.location_id}` ||
        this.router.url == '/secured/drill/active-drills'
      ) {
        this.eventsService.sendRefreshHome();
      }
    }
    this.getLocationsWithIssues();
  }

  ngOnInit() {
    this.timelineRes = undefined;
    this.getTimeLinePriority();
    let userData: any = sessionStorage.getItem('user-profile');
    userData = JSON.parse(userData);

    let group = userData.account_id.replaceAll('-', '');

    // Letter "H" should be added at the beggining of the group, the reason is
    // because the WebPubSub validates the group with a regex and this one
    // does not allow to start a group with numbers. This change was worked
    // with the ticket HH-957
    group = 'H' + group;

    this.websocketFunc(userData, group);
    this.getLocationsWithIssues();
    this.getAccounts();
  }

  getUserProfile() {
    this.usersService.getUserProfile().subscribe({
      next: (res: any) => {
        sessionStorage.setItem('user-profile', JSON.stringify(res));
        window.location.reload();
      },
      error: (error) => {
        this.openSnackBar(error, 'Ok');
      },
    });
  }

  getAccounts() {
    let ssUP = sessionStorage.getItem('user-profile');
    if (ssUP) {
      this.profileUser = JSON.parse(ssUP);
      if (this.profileUser) this.userAccounts = this.profileUser.accounts;
    }
  }

  changeAccount(id: string) {
    let payload = {
      new_account_id: id,
    };
    this.usersService.updateAccount(payload).subscribe({
      next: (res) => {
        this.getUserProfile();
      },
      error: (error) => {
        this.openSnackBar(error, 'Ok');
      },
    });
  }

  async getDrillEvents(locationId: string) {
    let payload = {
      location_ids: [locationId],
    };

    try {
      this.activeDrills = await firstValueFrom(
        this.drillsService.getDrills(payload)
      );
    } catch (error: any) {
      this.openSnackBar(error.message, 'Ok');
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  getLocations() {
    this._locationService.getLocations().subscribe({
      next: (res) => {
        this.allLocInfo = res;
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      },
    });
  }

  async getTimeLinePriority() {
    this.timelineRes = undefined;
    try {
      let locations: any = await firstValueFrom(
        this._locationService.getLocations()
      );
      this.allLocInfo = locations;
      locations.map((location: any) => {
        this.locations.push(location.id);
      });

      if (locations.length > 0) {
        await this.getEventPriority();
      }
    } catch (error: any) {
      this.openSnackBar(error.message, 'Ok');
    }
  }

  async getEventPriority() {
    try {
      let eventInstance: any = await firstValueFrom(
        this.eventsService.getEventPriority()
      );
      this.eventInstance = eventInstance;
      if (eventInstance.locations.length > 1) {
        this.multipleEvents = true;
      } else {
        this.multipleEvents = false;
      }

      if (eventInstance.locations.length > 0) {
        let locationsIds: any[] = [];
        let evPriority = [];
        eventInstance.locations.map((aeLocations: any) => {
          locationsIds.push(aeLocations.location_id);
          evPriority.push(aeLocations.event_type_priority);
        });

        await this.getEventTypes(
          eventInstance.general_event_type_location_id,
          locationsIds
        );
      } else {
        this.maxEvent = undefined;
      }
    } catch (error: any) {
      this.openSnackBar(error.message, 'Ok');
    }
  }

  async getEventTypes(eventTypeId: any, locationIds: any) {
    this.eventTypes = [];
    let payload = {
      location_ids: locationIds,
    };
    try {
      let res: any = await firstValueFrom(
        this.eventsService.getEventTypes(payload)
      );
      res.map((item: any) => {
        let locEvents: EventTypesLocation[] = [];
        item.event_types.map((ev: any) => {
          let event = mapEventTypestoModel(ev);
          locEvents.push(event);
        });
        let eventsWithCustoms = eventTypesWithCustomEvents(locEvents);
        this.eventTypes.push({
          locationId: item.location_id,
          eventTypes: eventsWithCustoms,
        });
      });

      this.eventTypes.map((locEvt: any) => {
        locEvt.eventTypes.map((evt: any) => {
          if (evt.event_types.length > 0) {
            evt.event_types.map((ele: any) => {
              if (ele.id === eventTypeId) {
                this.maxEvent = ele;
                this.timelineRes = true;
              }
            });
          }
          if (evt.id === eventTypeId) {
            this.timelineRes = true;
            this.maxEvent = evt;
          }
        });
      });
    } catch (error: any) {
      this.openSnackBar(error.message, 'Ok');
    }
  }

  /** To tranform hexadecimal color to RGB */
  hexToRgb(hex: any) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  generateEmail(index: any) {
    this.mailtoMessage = `mailto:support@havrion.com?Subject=Customer%20Support%20REQUEST:%20${encodeURIComponent(
      this.supports[index]
    )}&body=Contact%20Name%3A%0D%0A%0D%0AGroup%2FLocation%20Name%3A%0D%0A%0D%0AContact%20Name%3A%0D%0A%0D%0AContact%20Info%3A%0D%0A%0D%0AEmail%3A%0D%0A%0D%0APhone%20Number%20%3A%0D%0A%0D%0APlease%20provide%20a%20detailed%20description%20of%20the%20challenge%20or%20issue%20you%20are%20experiencing.%20Please%20also%20include%20when%20the%20challenge%20or%20issue%20started%3A%0D%0A%0D%0A`;
  }

  logout() {
    this.authService.logout();
  }

  openDialog(type: string, index?: number, nestedEvent?: any) {
    let event = nestedEvent
      ? nestedEvent
      : index != undefined
      ? this.events[index]
      : null;

    if (type === 'createEvent') {
      let location: any;
      let system_mode;
      this.allLocInfo.map((loc: any) => {
        if (loc.id === this.selectedLocId) {
          system_mode = loc.system_mode;
          let locInDrillEmpty = this.drillInfo?.locsWithoutEvents?.find(
            (l: any) => l == this.selectedLocId
          );
          let locInDrill = this.drillInfo?.activeLocations?.find(
            (l: any) => l.location == this.selectedLocId
          );
          if (locInDrillEmpty || locInDrill) {
            location = loc.system_mode != 2 ? { ...loc, system_mode: 2 } : loc;
          } else {
            location = loc;
          }
        }
      });
      // console.log('evelocationnt', location);

      if (system_mode && system_mode != 0) {
        const dialogRef = this.dialog.open(CreateClearEventComponent, {
          width: '900px',
          disableClose: true,
          data: {
            title: 'CONFIRM ACTIVATION',
            content: 'confirmEvent',
            location: location,
            event: {
              ...event,
            },
            button: 'Confirm',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          console.log('The dialog was closed', result);
        });
      } else {
        this.openSnackBar(
          'Cannot activate events when a Location is Disarmed',
          'Ok'
        );
      }
    }
  }

  capitalize(str: string): string {
    const words = str.split(' ');

    for (let i = 0; i < words.length; i++) {
      const lower = words[i].toLowerCase();
      words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1);
    }

    return words.join(' ');
  }
}

<div class="section-container container-fluid dashboard-event-breakdown">
    <hh-progress-bar *ngIf="loadingData"></hh-progress-bar>
    <div class="d-flex locations-metrix-title">
        <div class="img-header">
            <img width="40" src="./assets/images/SVG/Icon_Location.svg">
        </div>

        <div class="locations_metrix__header">
            <p class="locations_metrix__header-title">Location(s)</p>
            <p class="locations_metrix__header-sub">Event Breakdown</p>
            <p class="locations_metrix__header-date">{{ dataShared.date }}</p>
        </div>

        <!-- <div class="expand-all-btn">
            <button mat-stroked-button color="primary" (click)="expandAllGroups()">Expand all</button>
        </div> -->

        <div class="search-input">
            <mat-form-field class="search-input-field" appearance="fill">
                <mat-label>Search Location Names</mat-label>
                <input matInput type="text" [(ngModel)]="searchValue" (ngModelChange)="searchLocation(searchValue)">
                <button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''; searchLocation('')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="table-locations slim-scrollbar">
        <div class="accordeon">
            <div *ngFor="let group of groups; let groupIndex = index">
                <div class="information__event" (click)="toggleGroup(groupIndex)">
                    <!-- <div class="information__event-stripe"></div> -->
                    <div class="row" style="width: 95%;">
                        <div class="col-md-6 d-flex align-items-center">
                            <div class="icon-group-img">
                                <img width="30" src="./assets/images/SVG/icon_siteGroup.svg">
                            </div>
                            <div class="information__event-location text-dark-iptv ms-3">{{ group.name }}</div>
                        </div>
                        <div class="col-md-5 kpis">
                            <div class="data-cell">
                                <div class="data-cell-number">{{ group.total_locations }}</div>
                                <div class="data-cell-text">Locations</div>
                            </div>
                            <div class="data-cell">
                                <div>{{ group.total_group_events }}</div>
                                <div class="data-cell-text">Total Events</div>
                            </div>
                        </div>
                        <div class="col-md-1 expand-arrows align-items-center">
                            <mat-icon [@rotateIcon180]="groupIconState(groupIndex)" class="text-dark-iptv">expand_more
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <div [@toggleCollapse] *ngIf="openedGroupIndex === groupIndex" class="locations-information-container">
                    <div *ngFor="let location of group.locations; let locationIndex = index">
                        <div class="information__event location-accordion" (click)="toggleLocation(locationIndex)">
                            <div class="information__event-stripe"></div>
                            <div class="row" style="width: 95%;">
                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="icon-loc-img">
                                        <img width="30" src="./assets/images/SVG/Icon_Location.svg">
                                    </div>
                                    <div class="information__event-location text-dark-iptv ms-3">{{ location.name }}
                                    </div>
                                </div>
                                <div class="col-md-5 kpis">
                                    <div class="data-cell">
                                        <div>{{ location.total_location_events }}</div>
                                        <div class="data-cell-text">Total Events</div>
                                    </div>
                                </div>
                                <div class="col-md-1 expand-arrows align-items-center">
                                    <mat-icon [@rotateIcon180]="eventIconState(locationIndex)" class="text-dark-iptv">
                                        expand_more
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                        <div [@toggleCollapse] *ngIf="openedLocationIndex === locationIndex" class="locations-charts">
                            <!-- <cardevent [data]="hubEvent.event_breakdown"
                                [percentage]="{p: percentage, total: hubEvent.total_events}"></cardevent> -->
                            <div class="row chart-location-row">
                                <div class="col-md-4">
                                    <event-breakdown
                                        [locationData]="{ events_breakdown: location.events_breakdown, activation_methods: location.activation_methods, eventTypes: dataShared.eventTypesUniques }"
                                        [percentage]="percentage" [content]="'locationChart'" [sorting]="sorting"></event-breakdown>
                                </div>
                                <div class="col-md-8">
                                    <event-breakdown-time
                                        [locationData]="{ events_timeline: location.events_timeline, eventTypes: dataShared.eventTypesUniques, range: dataShared.range }"
                                        [percentage]="percentage" [content]="'locationChart'">
                                    </event-breakdown-time>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let location of locations; let groupLocIndex = index">
                <div class="information__event" (click)="toggleGroupLoc(groupLocIndex)">
                    <!-- <div class="information__event-stripe"></div> -->
                    <div class="row" style="width: 95%;">
                        <div class="col-md-6 d-flex align-items-center">
                            <div class="icon-group-img">
                                <img width="30" src="./assets/images/SVG/Icon_Location.svg">
                            </div>
                            <div class="information__event-location text-dark-iptv ms-3">{{ location.name }}</div>
                        </div>
                        <div class="col-md-5 kpis">
                            <div class="data-cell">
                                <div>{{ location.total_location_events }}</div>
                                <div class="data-cell-text">Total Events</div>
                            </div>
                        </div>
                        <div class="col-md-1 expand-arrows align-items-center">
                            <mat-icon [@rotateIcon180]="groupoLocIconState(groupLocIndex)" class="text-dark-iptv">expand_more
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <div [@toggleCollapse] *ngIf="openedGroupLocIndex === groupLocIndex" class="locations-charts">
                    <!-- <cardevent [data]="hubEvent.event_breakdown"
                        [percentage]="{p: percentage, total: hubEvent.total_events}"></cardevent> -->
                    <div class="row chart-location-row">
                        <div class="col-md-4">
                            <event-breakdown
                                [locationData]="{ events_breakdown: location.event_breakdown, activation_methods: location.activation_methods, eventTypes: dataShared.eventTypesUniques }"
                                [percentage]="percentage" [content]="'locationChart'" [sorting]="sorting"></event-breakdown>
                        </div>
                        <div class="col-md-8">
                            <event-breakdown-time
                                [locationData]="{ events_timeline: location.events_timeline, eventTypes: dataShared.eventTypesUniques, range: dataShared.range }"
                                [percentage]="percentage" [content]="'locationChart'">
                            </event-breakdown-time>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div *ngIf="(groups?.length === 0 || locations?.length === 0) && !loadingData" class="empty-state"> </div>
</div>
export interface Role{
    id: string
    name: string
    description: string
    role_type: number
}


export function mapRolesToModel(res: any): Role[] {
    let roles: Role[] = [];
    res.map((item: any) => {
        roles.push({
          id: item.id,
          description: item.description,
          name: capitalize(item.name),
          role_type: item.role_type
        })
      })
    return roles
}

function capitalize(str: string): string {
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
        const lower = words[i].toLowerCase()
        words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
    }

    return words.join(" ");
}

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { SubscriptionModel } from '../models/subscriptionModel';

@Injectable({
  providedIn: 'root'
})
export class DrillService {

  private subject = new Subject<any>();
  private subjecActivateHeaderDrillMode = new Subject<any>();

  private subjectDrillsSubs = new Subject<any>();

  sendDrillsSubs(data: SubscriptionModel){
    this.subjectDrillsSubs.next(data);
  }

  getDrillsSubs(){
    return this.subjectDrillsSubs.asObservable();
  }

  callDrillsEvent(drillId: string){
    // console.log("entra al call del service")
    this.subject.next(drillId);
  }
  
  getDrillsEvent():Observable<any>{
    return this.subject.asObservable();
  }

  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) { }

  private subjectChangeTab = new Subject<any>();

  sendActivateHeaderDrillMode(info:{activate: boolean, info: any}){
    this.subjecActivateHeaderDrillMode.next(info);
  }

  getActivateHeaderDrillMode(){
    return this.subjecActivateHeaderDrillMode.asObservable();
  }

  sendChangeTab(){
    this.subjectChangeTab.next('');
  }

  getChangeTab(){
    return this.subjectChangeTab.asObservable();
  }

  // getDrills(selectedId: string) {
  //   let api = `${this.endpoint}/location/${selectedId}/drills`;
  //   return this.http
  //     .get(api, { headers: this.headers, responseType: "json" },)
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  getDrills(payload: Object) {
    let api = `${this.endpoint}/drills`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDrill(selectedId: string, drillId: string) {

    let api = `${this.endpoint}/location/${selectedId}/drill/${drillId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }
  
  updateDrill(selectedId: string, drillId: string, payload: object) {

    let api = `${this.endpoint}/location/${selectedId}/drill/${drillId}`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  createDrill(selectedId: string, payload: object){
    let api = `${this.endpoint}/location/${selectedId}/drills`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  deleteDrill(locationId: string, drillId: string){
    let api = `${this.endpoint}/location/${locationId}/drill/${drillId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  activateDrill(selectedId: string, drillId: string) {
    let api = `${this.endpoint}/location/${selectedId}/drill/${drillId}/activate`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  deactivateDrill(selectedId: string, drillId: string) {
    let api = `${this.endpoint}/location/${selectedId}/drill/${drillId}/deactivate`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error.message
      };
    }
    return throwError(msg);
  }
}

<mat-card appearance="outlined" class="h-hub-drill-table-card mat-elevation-z0 mx-4">
  <hh-progress-bar *ngIf="loadingData" class="drill-progress-bar"></hh-progress-bar>
  <mat-card-content>
    <mat-slide-toggle class="mx-3 pt-3 mb-3 text-dark-iptv" [(ngModel)]="isChecked" (change)="filterEvents(isChecked)">Show
      Drill History</mat-slide-toggle>

    <table mat-table [dataSource]="dataSource" matSort class="col-12 h-hub-table">

      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
      </ng-container>

      <!-- time Column -->
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Time
        </th>
        <td mat-cell *matCellDef="let element"> {{element.time}} </td>
      </ng-container>

      <!-- location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Location
        </th>
        <td mat-cell *matCellDef="let element"> {{element.location}} </td>
      </ng-container>

      <!-- eventType Column -->
      <ng-container matColumnDef="eventType">
        <th mat-header-cell *matHeaderCellDef> Event Type </th>
        <td mat-cell *matCellDef="let element">

          <div [ngStyle]="{ 'backgroundColor': element.type.backgroundColor }" class="p-2 d-flex">

            <img width="44px" [src]="'../../assets/images/icons/' + element.type.icon" alt="">
            <span
              style="font-weight: 500; line-height: 1.3; font-size: 16px;"
              [ngStyle]="{ 'color': element.type.color }"
              class="ms-2 flex-grow-1 my-auto">
              {{element.type.name}}
            </span>

          </div>

        </td>
      </ng-container>

      <!-- activationMethod Column -->
      <ng-container matColumnDef="activationMethod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Activation Method </th>
        <td mat-cell *matCellDef="let element"> {{element.activationMethod}} </td>
      </ng-container>

      <!-- mobile Column -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
      </ng-container>

      <!-- sensor Column -->
      <ng-container matColumnDef="sensor">
        <th mat-header-cell *matHeaderCellDef> Sensor </th>
        <td mat-cell *matCellDef="let element"> {{element.sensor}} </td>
      </ng-container>

      <!-- button Column -->
      <ng-container matColumnDef="button">
        <th mat-header-cell *matHeaderCellDef> Button </th>
        <td mat-cell *matCellDef="let element"> {{element.button}} </td>
      </ng-container>

      <!-- hub Column -->
      <ng-container matColumnDef="hub">
        <th mat-header-cell *matHeaderCellDef> Hub </th>
        <td mat-cell *matCellDef="let element"> {{element.hub}} </td>
      </ng-container>

      <!-- createdBy Column -->
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef> Created By </th>
        <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
      </ng-container>

      <!-- button Column -->
      <ng-container matColumnDef="activate">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!element.history">
            <button *ngIf="!element.isActive" [disabled]="element.activeLocation || (element.roleLocUser == 'viewer')"
              mat-raised-button color="primary"
              (click)="openDialog('activateDrill', element.id, element.locationId)">ACTIVATE DRILL</button>
            <button *ngIf="element.isActive" [disabled]="element.roleLocUser == 'viewer'" mat-stroked-button
              color="primary" (click)="openDialog('deactivateDrill', element.id, element.locationId)">DEACTIVATE
              DRILL</button>
          </span>
        </td>
      </ng-container>

      <!-- menu Column -->
      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
            [disabled]="element.roleLocUser == 'viewer'">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <span *ngIf="!element.history">
              <button [disabled]="element.isActive"
              mat-menu-item (click)="openDialog('edit', element.id)">
                <span>Edit Drill</span>
              </button>
            </span>
            <span *ngIf="element.history">
              <button [disabled]="element.isActive"
              mat-menu-item (click)="openDialog('view', element.eventInstanceGroupId, element.locationId)">
                <span>View Details</span>
              </button>
            </span>
            <button [disabled]="element.history.length == 0 || element.isActive" mat-menu-item
              (click)="openDialog('deleteDrill', element.id)">
              <span>Delete Drill</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <div *ngIf="events?.length === 0 && !loadingData" class="empty-state-hhub"> </div>
  </mat-card-content>

  <mat-card-footer >
    <mat-paginator class="paginator-table" [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons></mat-paginator>
  </mat-card-footer>

</mat-card>

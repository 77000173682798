import { Component, } from "@angular/core";

@Component({
    selector: "home",
    templateUrl: "home.component.html",
    styleUrls: [
        "home.component.scss"
    ]
})
// export class HomeComponent implements OnInit, OnDestroy {
export class HomeComponent{

    // locationSuscription: Subscription;
    // selectedLocationsId: String[];

    // constructor(private locationsService: SelectedLocationsService) {
    //     this.locationSuscription = this.locationsService.selectedLocationsObservable.subscribe(selectedIds => {
    //         // console.log(selectedIds);
    //     })
    // }

    // ngOnInit(){
    // this.locationSuscription =  this.locationsService.selectedLocationsObservable.subscribe(selectedIds => {
    //     this.selectedLocationsId = selectedIds;
    //     console.log(this.selectedLocationsId);
    // })
    // }

    // ngOnDestroy() {
    //     this.locationSuscription?.unsubscribe();
    // }

}
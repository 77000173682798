import { Component, Input, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { ActivationMethod } from "src/app/core/models/activation-method";
import { Drill } from "src/app/core/models/drill";
import { DrillService } from "src/app/core/services/drills.service";
import { EventsService } from "src/app/core/services/events.service";
import { DialogComponent } from "src/app/feature/drill/dialog/dialog.component";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { OneTimeService } from "src/app/core/services/onetime.service";
import { EventTypesLocation } from "src/app/core/models/event-types-location";
@Component({
    selector: "drillevents",
    templateUrl: "drillevents.component.html",
    styleUrls: [
        "drillevents.component.scss"
    ]
})
export class EventsDrillComponent implements OnInit, OnDestroy {

    data: any;

    @ViewChild(MatSort) sort: MatSort | undefined;
    @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

    @Input('data') set dataSet(value: any) {
        this.loadingData = true

        this.data = value;
        if (this.data) {
            if (this.data.selectedLocations) {
                this.selectedLocationIds = this.data.selectedLocations;
                this.eventTypes = this.data.eventTypes;
                this.getDrills();
            }

            if (this.selectedLocationIds.length > 1) {
                this.activateEnable = false;
            } else {
                this.activateEnable = true;
            }

        } else {
            this.loadingData = false
        }
    }

    get dataSet(): any {
        return this.data;
    }

    private callDrillsSuscription: Subscription;

    allEvents: Drill[] = [];
    events: Drill[] = [];
    eventTypes = [];
    activationMethods: ActivationMethod[] = [];

    isChecked = false;
    selectedLocationIds: string[] = [];

    activateEnable = false;
    cantActiveButton = true;

    displayedColumns: string[] = ['date', 'time', 'location', 'eventType', 'activationMethod', 'mobile', 'sensor', 'button', 'hub', 'createdBy', 'activate', 'menu'];
    dataSource: any

    drillsRes: any;

    loadingData = true;



    private clickOpenDrillHisorySubscription: Subscription;
    private clickDeactivateDrillSubscription: Subscription;

    constructor(public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private drillsService: DrillService,
        private eventsService: EventsService,
        private oneTimeService: OneTimeService) {
        this.callDrillsSuscription = this.drillsService.getDrillsEvent().subscribe(() => {
            this.loadingData = true
            this.allEvents = [];
            this.events = [];
            this.getDrills();
        });

        this.clickOpenDrillHisorySubscription = this.eventsService.getOpenDrillHistory().subscribe((info: any) => {
            this.openDialog('view', info.eventId, info.locationId);
        });

        this.clickDeactivateDrillSubscription = this.eventsService.getClickDeactivateDrill().subscribe((res: any) => {
            let ev_id: string = '';

            this.drillsRes?.map((drill: any) => {
                if (drill.event_instance_group_id == res.hubEvent.id) {
                    ev_id = drill.id
                }
            })
            if (!ev_id) {
                setTimeout(() => {
                    this.drillsRes.map((drill: any) => {
                        if (drill.event_instance_group_id == res.hubEvent.id) {
                            ev_id = drill.id
                        }
                    })
                    this.openDialog(res.elem, ev_id, res.hubEvent.locationId, res.hubEvent.type);
                }, 100);
            } else {
                this.openDialog(res.elem, ev_id, res.hubEvent.locationId, res.hubEvent.type);
            }


            // this.events.map(el => {
            //     if (el.eventInstanceGroupId === res.hubEvent.id) {
            //         ev_id = el.id;
            //     }
            // })

        })
    }

    roleHandle(locId: string): string {
        let roleLocUser: string = '';
        let roleProfile = JSON.parse(sessionStorage.getItem('user-profile') ?? '');
        roleProfile?.locations.map((loc: any) => {
            if (loc.location_id === locId) {
                roleLocUser = loc.role_name;
            }
        })
        return roleLocUser
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }

    openDialog(elem: string, id: string, locationId?: string, eventype?: any): void {
        let evType;
        let locId = "";
        this.events.map((ev: any) => {
            if (id === ev.id) {
                evType = ev.type;
                locId = ev.locationId;
            }
        })

        if (elem == "edit") {
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '900px',
                data: { title: "EDIT DRILL", content: "edit", button: "SAVE", id: id, locationId: locId }
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    if (result.msg === "deleteDrill") {
                        console.log('The dialog was closed');
                        this.deleteDrill(result.id);
                    }
                    if (result === "edit") {
                        console.log('The dialog was closed');
                        this.getDrills();
                        this.openSnackBar('The drill was updated', 'OK');
                    }
                } else {
                    console.log('The dialog was closed');
                }



            });
        }

        if (elem == "view") {
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '90%',
                data: { title: "DRILL HISTORY", content: "drillview", button: "Exit", id: id, locationId: locationId }
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log('The dialog was closed');
            });
        }

        if (elem == "activateDrill" || elem == "deactivateDrill") {
            let title = elem == "activateDrill" ? "CONFIRM DRILL ACTIVATION" : "CONFIRM DRILL DEACTIVATION";
            let content = elem == "activateDrill" ? "activateDrill" : "deactivateDrill";
            if (eventype) {
                evType = eventype;
            }
            if (elem == "deactivateDrill") {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '600px',
                    data: {
                        title: title,
                        content: content,
                        button: "Confirm",
                        id: id,
                        locationId: locationId,
                        eventType: evType
                    }
                });

                dialogRef.afterClosed().subscribe((result) => {
                    console.log('The dialog was closed....');
                });
            } else {
                this.events.map((ev: any) => {
                    if (id === ev.id) {
                        this.cantActiveButton = ev.enableActiveButton ?? false
                    }
                })
                if (this.cantActiveButton) {
                    const dialogRef = this.dialog.open(DialogComponent, {
                        width: '600px',
                        data: {
                            title: title,
                            content: content,
                            button: "Confirm",
                            id: id,
                            locationId: locationId,
                            eventType: evType
                        }
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        console.log('The dialog was closed....');
                    });
                } else {
                    this.openSnackBar('Cannot active more than one drill at a time', 'OK')
                }
            }

        }


        if (elem == "deleteDrill") {
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '800px',
                data: { title: "CONFIRM", content: "deleteDrillParent", button: "Delete", id: id, locationId: "" }
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log('The dialog was closed');
                if (result === "deleteDrill") {
                    this.deleteDrill(id);
                }

            });
        }

    }

    ngOnInit() {
        this.setActivationMethods();
    }

    ngOnDestroy() {
        this.callDrillsSuscription?.unsubscribe();
        this.clickDeactivateDrillSubscription?.unsubscribe();
        this.clickOpenDrillHisorySubscription?.unsubscribe();
    }

    deleteDrill(drillId: string) {
        let locId = "";
        this.events.map((ev: any) => {
            if (drillId === ev.id) {
                locId = ev.locationId;
            }
        })
        this.drillsService.deleteDrill(locId, drillId).subscribe((res: any) => {
            this.openSnackBar('The drill was deleted', 'OK');
            this.getDrills();
        }, error => {
            this.openSnackBar(error.message, 'Ok');
        })
    }

    filterEvents(ischecked: any): void {
        this.events = [];
        if (ischecked) {
            this.events = this.allEvents;
        } else {
            for (let elem of this.allEvents) {
                if (!elem.history) {
                    this.events.push(elem);
                }
            }
        }
        for (let i = 0; i < this.events.length; i++) {
            let count = 0;
            for (let j = 0; j < this.events.length; j++) {
                if (i != j) {
                    if (this.events[i].locationId == this.events[j].locationId) {
                        if (this.events[i].isActive || this.events[j].isActive) {
                            count++;
                            if (count > 0) {
                                this.events[i].enableActiveButton = false;
                                break;
                            }

                        }
                    }
                }
            }
        }
        this.dataSource = new MatTableDataSource(this.events);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    getSelectedEventType(eventTypeId: string) {
        let selectedEventType: EventTypesLocation | undefined;
        this.eventTypes.map((item: any) => {
            if (eventTypeId === item.id) {
                selectedEventType = item;
            } else {
                item.event_types.map((nev: any) => {
                    if (eventTypeId === nev.id) {
                        selectedEventType = nev;
                    }
                })
            }
        })
        return selectedEventType
    }

    getSelectedActivationMethod(activationMethodId: string) {
        let activatioMethod = "not found";
        this.activationMethods.map((item: any) => {
            if (activationMethodId === item.id) {
                activatioMethod = item.description;
            }
        })
        return activatioMethod
    }

    setDrillEvents() {
        this.allEvents = [];
        this.cantActiveButton = true;
        this.drillsRes.map((item: any) => {
            let date = (item.start_dte.split(" ", 2))[0];
            let hour = (item.start_dte.split(" ", 2))[1].split(":", 2)
            let history = false;
            let locationActive = false;

            this.data.activeLocations?.map((actLoc: any) => {
                if (item.location_id === actLoc) {
                    locationActive = true;
                }
            })

            if (item.status === "closed") {
                history = true;
            }

            this.allEvents.push({
                id: item.id,
                date: `${date}`,
                time: `${hour[0]}:${hour[1]}`,
                location: item.location_name,
                type: this.getSelectedEventType(item.event_type_location_id),
                activationMethod: this.getSelectedActivationMethod(item.activation_method_id),
                mobile: item.mobile,
                sensor: item.sensor,
                button: item.button,
                hub: item.web,
                createdBy: item.user_name,
                history: history,
                locationId: item.location_id,
                isActive: item.status === "active",
                activeLocation: locationActive,
                eventInstanceGroupId: item.event_instance_group_id,
                enableActiveButton: true,
                roleLocUser: this.roleHandle(item.location_id)
            })
        })
        this.loadingData = false;
        this.filterEvents(this.isChecked);

    }

    getDrills() {
        this.allEvents = [];
        this.events = [];
        let payload = {
            location_ids: this.selectedLocationIds
        }
        let locationsIds: any[] = [];
        this.drillsService.getDrills(payload).subscribe((res: any) => {
            this.drillsRes = res;
            res.map((item: any) => {
                if (!locationsIds.includes(item.location_id)) {
                    locationsIds.push(item.location_id)
                }
            })
            this.setDrillEvents();
        }, error => {
            this.openSnackBar(error.message, 'Ok');
        });
    }

    hexToRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    setActivationMethods() {
        if (sessionStorage.getItem('activationMethods')) {
            let strObj = sessionStorage.getItem('activationMethods');
            let obj = JSON.parse(strObj ?? '');
            for (let i = 0; i < obj.length; i++) {
                this.activationMethods.push(obj[i])
            }
        } else {
            let activationMethods: any[] = [];
            this.oneTimeService.getActivationMethods().subscribe((res: any) => {
                res.map((item: any) => {
                    activationMethods.push({
                        id: item.id,
                        description: item.description,
                        backgroundColor: item.background_color,
                        icon: item.icon
                    })
                })
                sessionStorage.setItem('activationMethods', JSON.stringify(activationMethods));
                this.setActivationMethods();
            }, error => {
                this.openSnackBar(error.message, 'Ok');
            });
        }
    }


}

<div class="container__dash-buttons d-flex">
    <div class="event me-3">
        <!-- <p class="titles">Events</p> -->
        <ng-multiselect-dropdown [placeholder]="'Select Event Type'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
            (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
    <div class="time__frame">
        <!-- <p class="titles">Time Frame</p> -->
        <div class="container__dates">
            <div class="me-3">
                <mat-form-field>
                    <mat-label>Show As</mat-label>
                    <mat-select [(value)]="selectedRange" (selectionChange)="setRange(selectedRange)">
                        <mat-option *ngFor="let range of timeRanges" [value]="range.value">
                            {{range.viewValue}}
                        </mat-option>

                    </mat-select>
                    <mat-icon class="show-icon" matDatepickerToggleIcon>expand_more</mat-icon>

                </mat-form-field>
            </div>
            <!-- <div class="date-range">
                <mat-form-field>
                    <mat-label>Enter a range date</mat-label>
                    <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="rangeDate" class="form-control"
                        autocomplete="off" [locale]="{format: 'MM/DD/YYYY'}" opens="center"
                        (change)="rangeDateChanged($event)" [showDropdowns]="true"/>
                </mat-form-field>
            </div> -->
            <div class="me-3">
                <mat-form-field class="example-full-width">
                    <mat-label>Start Date Range</mat-label>
                    <input id="startDateRef" #startDateRef matInput [matDatepicker]="startDate"
                        (dateChange)="setStartDate(startDateRef.value)" [(ngModel)]="startFrame" disabled>
                    <mat-datepicker-toggle matSuffix [for]="startDate">
                        <mat-icon matDatepickerToggleIcon>expand_more</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDate [startView]="calendarView" disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="me-3">
                <mat-form-field class="example-full-width">
                    <mat-label>End Date Range</mat-label>
                    <input id="endDataRef" #endDataRef matInput [matDatepicker]="endDate"
                        (dateChange)="setEndDate(endDataRef.value)" [(ngModel)]="endFrame" disabled>
                    <mat-datepicker-toggle matSuffix [for]="endDate">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDate [startView]="calendarView" disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="reset">
        <button class="reset__button" (click)="resetFilters()">Reset all filters</button>
    </div>
    <div class="percentage ms-auto">
        <p class="percentage__title">Show as Percentage</p>
        <mat-slide-toggle [(ngModel)]="isChecked" (change)="changed()">
            <span class="percentage__icon">%</span>
        </mat-slide-toggle>
    </div>
    <div class="sorting ms-auto">
        <p class="sorting__title">Sort alphabetically</p>
        <mat-slide-toggle [(ngModel)]="isSorted" (change)="sortingChanged()">
            <mat-icon class="sorting__icon" aria-hidden="false" aria-label="Example home icon">sort_by_alpha</mat-icon>
        </mat-slide-toggle>
    </div>
    <div class="more ms-auto">
        <button mat-icon-button aria-label="vetical button" [matMenuTriggerFor]="menu">
            <mat-icon class="more__icon">more_vert</mat-icon>
        </button>
        <mat-menu class="mat__menu" #menu="matMenu">
            <!-- <button mat-menu-item (click)="openDialog('edit')"> -->
            <button mat-menu-item (click)="print()">
                <span>Print Dashboard</span>
            </button>
        </mat-menu>
    </div>
</div>
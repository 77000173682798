import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventTypesLocation, mapEventTypestoModel } from 'src/app/core/models/event-types-location';
import { DrillService } from 'src/app/core/services/drills.service';
import { EventsService } from 'src/app/core/services/events.service';
import { LocationService } from 'src/app/core/services/location.service';
import { SelectedLocationsService } from 'src/app/core/services/selected-locations.service';
import { DialogComponent } from 'src/app/feature/drill/dialog/dialog.component';
@Component({
  selector: 'drill',
  templateUrl: 'drill.component.html',
  styleUrls: ['drill.component.scss'],
})
export class DrillComponent implements OnInit, OnDestroy {

  private locationsSubscription: Subscription | undefined;
  private callChangeTab: Subscription
  private callDrillsSuscription: Subscription;

  createEnable = false;
  selectedLocationId: string = '';
  selectedLocationIds: string[] = [];
  allLocationsIds: string[] = [];
  timelineRes: any;
  dataShare: any;
  activeLocations: string[] = [];
  // tabIndex: number = 0;
  selected = new FormControl(0);
  headerInfo: any[] = [];

  roleLocUser: string = '';

  @ViewChild('tabGroup') tabGroup: any;

  activesDrill: any[] = [];
  eventTypes: any[] = [];
  maxEvent: EventTypesLocation | undefined;
  drill: any;
  msg = "Drill";


  constructor(public dialog: MatDialog,
    private callDrillsService: DrillService,
    private locationsService: SelectedLocationsService,
    private _snackBar: MatSnackBar,
    private drillsService: DrillService,
    private eventService: EventsService,
    private locationService: LocationService,
    public router: Router,
    private _cdr: ChangeDetectorRef) {
    this.callChangeTab = this.callDrillsService.getChangeTab().subscribe(() => {
      this.selected.setValue(1);
    })

    this.callDrillsSuscription = this.drillsService.getDrillsEvent().subscribe(() => {
      this.maxEvent = undefined;
      this.getAllLoations();
    })
  }

  sendTabChanged(event: number | null) {
    this.drillsService.sendDrillsSubs({
      channel: "drills-map",
      broadcastID: "DrillComponent",
      payload: {
        tab: event
      }
    })
  }

  roleHandle() {
    let roleProfile = JSON.parse(sessionStorage.getItem('user-profile') ?? '');
    if (this.selectedLocationIds?.length === 1) {
      roleProfile.locations?.map((loc: any) => {
        if (loc.location_id === this.selectedLocationIds[0]) {
          this.roleLocUser = loc.role_name
        }
      })
    }
  }

  showMaxEvent() {
    if (this.activesDrill.length > 0) {
      let eventTypeId = this.activesDrill[0].general_event_type_id;
      this.eventTypes?.map((evt: any) => {
        if (evt.id === eventTypeId) {
          this.maxEvent = evt;
        } else {
          evt.event_types.map((nev: any) => {
            if (nev.id === eventTypeId) {
              this.maxEvent = nev;
            }
          });
        }
      })

      if (this.activesDrill.length > 1) {
        this.msg = "Multiple Drills"
        this.activesDrill?.map((tm: any) => {
          this.eventTypes?.map((evt: any) => {
            if (evt.id === tm.general_event_type_id) {
              if(this.maxEvent)
              if (this.maxEvent.priority <= evt.priority) {
                this.maxEvent = evt;
              }
            } else {
              evt.event_types.map((nev: any) => {
                if (nev.id === tm.general_event_type_id) {
                  if(this.maxEvent)
                  if (this.maxEvent.priority <= nev.priority) {
                    this.maxEvent = nev;
                  }
                }
              })
            }
          })
        })

      } else {
        this.msg = "Drill"
      }
    }

  }

  hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  capitalize(str: string): string {
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
      const lower = words[i].toLowerCase()
      words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
    }

    return words.join(" ");
  }

  getEventTypes(locationIds: string[]) {
    this.eventTypes = [];
    let payload = {
      location_ids: locationIds
    }
    this.eventService.getEventTypes(payload).subscribe((allEvents: any) => {
      allEvents.map((item: any) => {
        item.event_types.map((ev: any) => {
          let event = mapEventTypestoModel(ev);
          this.eventTypes.push({ ...event, location_id: item.location_id, });
        })
      })
    }, error => {
      this.openSnackBar(error.message, 'Ok');
    });


  }

  getEventTimelines(locationIds: string[], onInit?: boolean) {
    this.activesDrill = [];
    let payload = {
      location_ids: locationIds,
      show: "active-drill"
    }

    let payloadActiveLocations = {
      location_ids: locationIds,
      show: "active"
    }

    this.eventService.getEventTimelines(payloadActiveLocations).subscribe(res => {
      this.activeLocations = []; // I need to know the active ones in order to enable or disable the active drill button

      if (res.length > 0) {
        res?.map((item: any) => {
          this.activeLocations.push(item.location_id);
        })
      }

      let locationsTimeline = [];
      this.eventService.getEventTimelines(payload).subscribe(resDrill => {
        this.headerInfo = [];
        let locsWithoutEvents: string[] = [];
        if (resDrill.length > 0) {
          resDrill.map((item: any) => {
            this.activesDrill.push(item);
            locationsTimeline.push(item.location_id);
            if (item.event_timeline.length > 1) {
              this.headerInfo.push({
                location: item.location_id,
                eventId: item.general_event_type_id
              })
              this.showMaxEvent();
            }else{
              locsWithoutEvents.push(item.location_id)
            }
          })
          this.timelineRes = resDrill;
        }
        if(onInit){
          this.drillsService.sendActivateHeaderDrillMode({
            activate: true,
            info: {
              locsWithoutEvents: locsWithoutEvents,
              activeLocations: this.headerInfo,
              eventTypes: this.eventTypes
            }
          });
        }

        this.dataShare = {
          ...this.dataShare,
          allLocations: this.allLocationsIds,
          selectedLocations: this.selectedLocationIds,
          activeLocations: this.activeLocations,
          eventTypes: this.eventTypes
        }

      }, error => {
        this.openSnackBar(error.message, 'Ok');
      });

    }, error => {
      this.openSnackBar(error.message, 'Ok');
    });
  }

  openDialog(): void {

    if (this.createEnable) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '900px',
        data: { title: "ADD DRILL", content: "create", button: "ADD DRILL", locationId: this.selectedLocationId }
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed', result);
        if (result === "create") {
          this.getDrills();
          this.openSnackBar('Drill created', 'OK')
        }
        if (result === "edit") {
          this.getDrills();
          this.openSnackBar('Drill updated', 'OK')
        }

      });
    } else {
      this.openSnackBar('You have to choose ONE location', 'OK')
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  getDrills() {
    this.callDrillsService.callDrillsEvent("");
  }

  getAllLoations() {
    let locationIds: string[] = [];
    this.locationService.getLocations().subscribe((res: any) => {
      if (res.length > 0) {
        res?.map((item: any) => {
          locationIds.push(item.id);
        })
        this.allLocationsIds = locationIds
        this.getEventTimelines(locationIds, true);
      }
    }, error => {
      this.openSnackBar(error.message, 'Ok');
    })
  }

  ngOnInit() {
    this.locationsSubscription = this.locationsService.selectedLocationsObservable.subscribe(selectedIds => {
      if (selectedIds.length > 0) {
        if (this.eventTypes.length == 0) {
          this.getEventTypes([]);
        }
        this.selectedLocationIds = selectedIds;
        this.getEventTimelines(this.selectedLocationIds);
        if (selectedIds.length < 2) {
          this.createEnable = true;
          this.selectedLocationId = selectedIds[0];
          this.roleHandle();
        } else {
          this.createEnable = false;
        }
      } else {
        this.createEnable = false;
        this.selectedLocationIds = selectedIds;
      }
    });

    this.roleHandle();
  }

  ngOnDestroy() {
    // this.locationsSubscription?.unsubscribe();
    this.callDrillsSuscription?.unsubscribe();
    this.callChangeTab?.unsubscribe();
    this.drillsService?.sendActivateHeaderDrillMode({ activate: false, info: {} });
  }

  ngAfterViewInit() {
    if (this.selectedLocationIds.length == 0) {
      this.getEventTypes([]);
      this.getAllLoations();
    }
    if (this.router.url === '/secured/drill/active-drills') {
      this.selected.setValue(1);
      this._cdr.detectChanges();
    }
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalBroadcastService, MsalGuard, MsalModule, MsalService} from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';



import { } from "googlemaps";
import { environment } from '../environments/environment';
import { AuthInterceptorInterceptor } from './core/interceptor/auth-interceptor.interceptor';
import { BlobErrorHttpInterceptor } from './core/interceptor/blobErrorHttp.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './feature/home/home.module';
import { DashModule } from './feature/dash/dash.module';
import { DrillModule } from './feature/drill/drill.module';
import { Oidc2Component } from './core/components/oidc2/oidc2.component';
import { NgChartsModule } from 'ng2-charts';

const msalModule = MsalModule.forRoot(new PublicClientApplication(
  {
    auth: {
      clientId: environment.azure_b2c.clientId,
      authority: `https://${environment.azure_b2c.tenant}.b2clogin.com/${environment.azure_b2c.tenant}.onmicrosoft.com/${environment.azure_b2c.userFlows.signIn}`,
      redirectUri: environment.azure_b2c.redirectUri,
      postLogoutRedirectUri: environment.azure_b2c.postLogoutRedirectUri,
      knownAuthorities: [
        `https://${environment.azure_b2c.tenant}.b2clogin.com/${environment.azure_b2c.tenant}.onmicrosoft.com`,
        // ...environment.azure_b2c.otherKnownAutorities// Add other authorities in case it is necessary
      ],
      // navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
  }),
  environment.azure_b2c.guardConfig,
  environment.azure_b2c.interceptorConfig
)


@NgModule({
  declarations: [
    AppComponent,
    Oidc2Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HomeModule,
    DashModule,
    DrillModule,
    HttpClientModule,
    msalModule,
    NgChartsModule
  ],
  providers: [
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

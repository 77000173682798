import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgChartsModule } from "ng2-charts";
import { MaterialModule } from "./material.module";
// import Swiper from 'swiper';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { createCustomElement } from "@angular/elements";


import { HubLocationsComponent } from "./components/hub-locations/hub-locations.component";
import { LoggedSkeletonComponent } from "./components/logged-skeleton/logged-skeleton.component";
import { FooterComponent } from './components/footer/footer.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { HeaderComponent } from "./components/header/header.component";
import { SimpleDividerComponent } from "./components/simple-divider/simple-divider.component";
import { EventsComponent } from "./components/events/events.component";
import { EventsTimelineComponent } from "./components/events-timeline/events-timeline.component";
import { MapComponent } from "./components/map/map.component";
import { AttendanceComponent } from "./components/attendance/attendance.component";
import { ManageSitesDialogComponent } from "./components/manage-sites-dialog/manage-sites-dialog.component";
import { SiteFormComponent } from "./components/site-form/site-form.component";
import { AlertComponent } from './components/alert/alert.component';
import { RealTimeComponent } from './components/real-time/real-time.component';
import { LimitCharactersPipe } from './pipes/limit-characters.pipe';
import { NewEventComponent } from './components/new-event/new-event.component';
import { CreateClearEventComponent } from "./components/create-clear-event-dialog/create-clear-event.component";
import { EventsInfoComponent } from "./components/events-whole-info/eventsinfo.component";
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TimeRangeComponent } from './components/time-range/time-range.component';
import { EventBreakdownComponent } from './components/event-breakdown/event-breakdown.component';
import { EventBreakdownTimeComponent } from './components/event-breakdown-time/event-breakdown-time.component';
import { GroupsDonutsComponent } from './components/groups-donuts/groups-donuts.component';
import { DonutChartComponent } from './components/groups-donuts/donut-chart/donut-chart.component';
import { LocationsChartComponent } from './components/locations-chart/locations-chart.component';
import { LocationMetricsComponent } from './components/location-metrics/location-metrics.component';
import { MapOverlaysComponent } from './components/map-overlays/map-overlays.component';
import { DialogBlueprintComponent } from "./components/map-overlays/dialog-blueprint/dialog-blueprint.component";
import { DialogDisplayComponent } from "./components/events-timeline/dialog-display/dialog-display.component";


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // Swiper,
        NgChartsModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,  
        NgMultiSelectDropDownModule.forRoot(), 
    ],
    declarations: [
        LoggedSkeletonComponent,
        HubLocationsComponent,
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        SimpleDividerComponent,
        MapComponent,
        EventsComponent,
        EventsTimelineComponent,
        AttendanceComponent,
        ManageSitesDialogComponent,
        SiteFormComponent,
        AlertComponent,
        RealTimeComponent,
        LimitCharactersPipe,
        NewEventComponent,
        CreateClearEventComponent,
        EventsInfoComponent,
        ProgressSpinnerComponent,
        ProgressBarComponent,
        TimeRangeComponent,
        EventBreakdownComponent,
        EventBreakdownTimeComponent,
        GroupsDonutsComponent,
        DonutChartComponent,
        LocationsChartComponent,
        LocationMetricsComponent,
        MapOverlaysComponent,
        DialogBlueprintComponent,
        DialogDisplayComponent
    ],
    exports: [
        MaterialModule,
        LoggedSkeletonComponent,
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        SimpleDividerComponent,
        // Swiper,
        NgChartsModule,
        FormsModule,
        RealTimeComponent,
        MapComponent,
        EventsComponent,
        AttendanceComponent,
        ManageSitesDialogComponent,
        AlertComponent,
        LimitCharactersPipe,
        NgxMaterialTimepickerModule,
        EventsInfoComponent,
        ProgressBarComponent,
        TimeRangeComponent,
        EventBreakdownComponent,
        EventBreakdownTimeComponent,
        GroupsDonutsComponent,
        DonutChartComponent,
        LocationsChartComponent,
        LocationMetricsComponent,
        DialogBlueprintComponent,
        DialogDisplayComponent
    ],
    // entryComponents: [ // Note that migration to Ivy should eliminate this requirement
    //     ManageSitesDialogComponent,
    //     MapOverlaysComponent
    // ],
})
export class SharedModule {
    constructor(injector: Injector){
        const el = createCustomElement(MapOverlaysComponent, {injector: injector});
        if (!customElements.get('map-overlays')){
            customElements.define('map-overlays', el);
        }
        
    }
}
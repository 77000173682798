import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FlatLocation } from "src/app/core/models/flat-location";
import { SelectedLocationsService } from "src/app/core/services/selected-locations.service";
import { ManageSitesDialogComponent } from "../manage-sites-dialog/manage-sites-dialog.component";
import { Console } from "console";

/** Component used to display the available locations for the current user, and notify active events */
@Component({
    selector: "hub-locations",
    templateUrl: "hub-locations.component.html",
    styleUrls: [
        "hub-locations.component.scss"
    ]
})
export class HubLocationsComponent implements OnInit, OnDestroy {

    // MARK: Properties, getters and setters

    /** True if there is an ongoing event */
    isEventActive = false


    /** True if there is an ongoing  */
    isDrillActive = false

    /** True if the curren user is admin */
    isAdmin = true

    // TODO: Make a more complete management
    get eventColor(): string {
        return this.isDrillActive ? '#525252' : this.isEventActive ? '#C6230E' : '#ffffff'
    }

    /** The references to the url segments subscription */
    private segmentsSubscription: Subscription | null = null;

    /** Stores the current hub page */
    currentPage: HubPage | null = "home"

    /** Filtered [flattenedLocations] */
    filteredFlatLocations: FlatLocation[] = []

    /** The text used to filter the locations */
    filterText: string = ""

    /** Stores the ids of the selected locations */
    selectedLocationIds = new Set<string>()

    /** The shared group path of the selected locations */
    sharedLocationsPath: string = ''

    /** The root shared path of the locations */
    rootSharedPath: string = ''

    controlSelectCounter = 0;

    /** The txt used for number of diplayed locations */
    // displayedLocations: number;

    /**Return the selected Ids */
    get allSelectedLocationIds(): Set<string> {
        return this.selectedLocationIds;
    }

    /** Returns the text to be shown in the select all menu button */
    get selectAllText(): string {
        return this.filterText.trim() === '' ? "SELECT ALL" : "SELECT ALL FILTERED"
    }

    /** Returns the title for the locations */
    get selectTitle(): string | undefined {
        // this.displayedLocations = this.locationsService.flatLocations.length;

        if (this.selectedLocationIds.size === 1) {
            const selectedLocation = this.selectedLocationsService.flatLocations.find((location: any) => this.selectedLocationIds.has(location.id))
            return selectedLocation?.name
        }

        if (this.selectedLocationsService.flatLocations.length === 0) {
            return 'NO LOCATIONS'
        }
        // console.log("")
        // console.log("sharedLoationsPath",this.sharedLocationsPath.length)
        // console.log("root share pah", this.rootSharedPath)
        switch (this.sharedLocationsPath) {
            case '':
                if (this.rootSharedPath === '/') {
                    return 'MULTIPLE LOCATIONS'
                } else if (this.rootSharedPath === '') {
                    if (this.sharedLocationsPath.length == 0 && this.selectedLocationsService.flatLocations.length == 1) {
                        const showLocation = this.selectedLocationsService.flatLocations[0];
                        return showLocation.name
                    } else {
                        return 'MULTIPLE LOCATIONS'
                    }
                } else {
                    return this.rootSharedPath
                }
            case '/':
                return 'MULTIPLE LOCATIONS'
            default:
                return this.sharedLocationsPath
        }
    }

    /** True if all the locations are selected */
    get areAllFilteredSelected(): boolean {
        return this.selectedLocationIds.size === this.filteredFlatLocations.length
    }

    // MARK: Constructor and lifecycle

    constructor(
        private selectedLocationsService: SelectedLocationsService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private dialog: MatDialog
    ) {   }

    ngOnInit() {
        // Subscribe to the url segments
        this.segmentsSubscription = this.activeRoute.url.subscribe(_ => {
            const urlTree = this.router.parseUrl(this.router.url)
            const urlSegments = urlTree.root.children['primary'].segments.map((segment: any) => segment.path)
            this.currentPage = urlSegments.length > 1 ? <HubPage>urlSegments[1] : null
        })


        this.buildFilteredLocations()
        const allLocationIds = new Set<string>()
        for (const location of this.selectedLocationsService.flatLocations) {
            allLocationIds.add(location.id)
        }
        this.rootSharedPath = this.commonGroupPath(allLocationIds, this.selectedLocationsService.flatLocations)

        this.notifyLocationsChanged();

        this.controlSelectCounter = 1;

    }

    ngOnDestroy() {
        this.segmentsSubscription?.unsubscribe();
    }

    // MARK: Inteface methods

    /** Used to prevent focus loose on the mat menu input */
    refocus(focusEvent: FocusEvent) {
        setTimeout(() => {
            const inputElement = focusEvent.target as HTMLInputElement
            inputElement.focus()
        }, 20)
    }

    /** Sets the [filterText] to an empty string and rebuilds the flattened locations */
    clearFilterText() {
        this.filterText = ""
        this.buildFilteredLocations()
        // this.firstTimeSelected();
    }



    /** Builds the [filteredFlatLocations]  */
    buildFilteredLocations() {
        const refinedFilterText = this.filterText.trim().toLowerCase()
        this.filteredFlatLocations = this.selectedLocationsService.flatLocations.filter((flatLocation: any) => {
            const refinedSearchElements = flatLocation.fullPath.toLowerCase().split("/").filter((pathElement: any) => pathElement !== '')

            for (const searchElement of refinedSearchElements) {
                if (searchElement.includes(refinedFilterText)) {
                    // console.log("aqui 1")
                    return true
                }
            }
            // console.log("aqui 2")
            return false
        })
    }

    /** Called when a menu item is clicked */
    menuItemClicked(clickEvent: Event, item: FlatLocation) {
        //clickEvent.preventDefault()
        clickEvent.stopPropagation()

        if (this.selectedLocationIds.has(item.id)) {
            this.selectedLocationIds.delete(item.id)
        } else {
            this.selectedLocationIds.add(item.id)
        }
        this.sharedLocationsPath = this.commonGroupPath(this.selectedLocationIds, this.selectedLocationsService.flatLocations)
        // console.log("aqui 3")
        this.notifyLocationsChanged()

    }

    /** Selects all the filtered locations */
    selectAllFiltered(clickEvent: Event) {
        clickEvent.preventDefault()
        clickEvent.stopPropagation()

        // Add the ids of all the filtered locations
        this.selectedLocationIds.clear()
        for (const flatLocation of this.filteredFlatLocations) {
            this.selectedLocationIds.add(flatLocation.id)
        }
        this.sharedLocationsPath = this.commonGroupPath(this.selectedLocationIds, this.selectedLocationsService.flatLocations)
        // console.log("aqui 4")
        this.notifyLocationsChanged()
    }

    /** Removes all the selected locations */
    clearSelectedLocations(clickEvent: Event) {
        clickEvent.preventDefault()
        clickEvent.stopPropagation()

        this.selectedLocationIds.clear()
        this.sharedLocationsPath = this.commonGroupPath(this.selectedLocationIds, this.selectedLocationsService.flatLocations)
        // console.log("aqui 5")
        this.notifyLocationsChanged()
    }

    showManageSiteDialog() {
        // console.log("aqui 6")
        this.dialog.open(ManageSitesDialogComponent)
    }

    // To select all the ids at the fisrt time open the home
    // firstTimeSelected(){
    //     console.log("entra")
    //     if(this.controlSelectCounter === 1){
    //         document.getElementById('buttonSelectAll').click();
    //     }
    //     this.controlSelectCounter++;
    // }

    // MARK: Private methods

    /**
     * Extracts the top path of the [locations] with the given [ids]
     * @param ids The ids of the selected locations
     * @param locations The locations available
     * @returns '/' if there is no common root path, '' if there are no selected locations or the common group path
     */
    private commonGroupPath(ids: Set<string>, locations: FlatLocation[]): string {
        const selectedLocations = locations.filter((location: any) => ids.has(location.id))
        if (selectedLocations.length === 0) {
            return ''
        }

        let sharedPathComponents = selectedLocations[0].groupPath.split("/")
        for (const location of selectedLocations) {
            const locationPathComponents = location.groupPath.split("/")
            sharedPathComponents = this.extractSharedPathComponents(locationPathComponents, sharedPathComponents)

        }

        // console.log("aqui 7")

        return sharedPathComponents.length === 1 ? "/" : sharedPathComponents.join("/")
    }

    /**
     * Compares the [pathComponentsA] with [pathComponentsB], and extracs a reduced version of the shared components
     * Note an empty array will be returned if there are no shared components
     */
    private extractSharedPathComponents(pathComponentsA: string[], pathComponentsB: string[]): string[] {
        const sharedComponents = new Array<string>()
        const smallestSize = pathComponentsA.length > pathComponentsB.length ? pathComponentsB.length : pathComponentsA.length

        for (let i = 0; i < smallestSize; i++) {
            if (pathComponentsA[i] === pathComponentsB[i]) {
                sharedComponents.push(pathComponentsA[i])
            }
        }
        // console.log("aqui 8")
        return sharedComponents
    }

    /** Called each time the selected locations change... Sets the selected locations in the [locationsService] */
    private notifyLocationsChanged() {

        // Pass all locations if none are selected
        // if (this.selectedLocationIds.size === 0) {
        //     const selectedIds = this.locationsService.flatLocations.map(location => location.id)
        //     this.locationsService.selectedLocations = selectedIds
        //     return
        // }

        const selectedLocations = this.selectedLocationsService.flatLocations.filter((location: any) => this.selectedLocationIds.has(location.id)) // This step is used to preserve order if needed
        const selectedIds = selectedLocations.map((location: any) => location.id)

        // Set the selected locations in the service
        this.selectedLocationsService.selectedLocations = selectedIds
        // console.log("aqui 9")
        this.setLocationQueryParam(selectedIds)
    }

    /** Sets the locationId query parameter, based on the given [locationIds] */
    private setLocationQueryParam(locationIds: string[]) {
        if (locationIds?.length === 1) {
            const locationId = locationIds[0]
            this.router.navigate([], {
                relativeTo: this.activeRoute,
                queryParams: {
                    locationId: locationId
                },
                queryParamsHandling: "merge"
            })
            // console.log("aqui 10")
        } else {
            this.router.navigate([], {
                relativeTo: this.activeRoute,
                queryParams: {},
            })
            // console.log("aqui 11")
            this.controlSelectCounter = 1;
        }

    }

}

/** The page of the hub */
type HubPage = "drill" | "home" | "dash" | "solutions-management"

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FlatLocation } from "../models/flat-location";
import { generateFlatLocations } from "../utils/utility-functions";
import { catchError, retry, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LocationGroup } from "../models/location-group";
import { HubLocation } from '../models/hub-location';
// import { locationGroups } from "../utils/static-datasource";

/** Service used to store and write the selected location ids */
@Injectable({ providedIn: 'root' })
export class SelectedLocationsService {

    endpoint: string = environment.havrionHubApi.url;
    headers = new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: "json"
    });

    retry = 0;

    public locationGroupsRes: LocationGroup[] = [];


    /// The source producing the selected location events
    private selectedLocationsSource = new BehaviorSubject<string[]>([])

    /** The backup property containing the selected locations */
    private _selectedLocations: string[] = []

    /** The backup property for the flattened locations */
    private _flatLocations: FlatLocation[] = []

    /** Observable providing the list of selected locations... Don't forget to unsubscribe on ngDestroy */
    readonly selectedLocationsObservable: Observable<string[]> = this.selectedLocationsSource.asObservable()

    /** Returns the selected locations */
    get selectedLocations(): string[] {
        return this._selectedLocations
    }

    /** Returns the flat locations available to all components */
    get flatLocations(): FlatLocation[] {
        return this._flatLocations
    }

    /** Sets the [selectedLocations], emitting an event in the [selectedLocationsObservable] */
    set selectedLocations(locationIds: string[]) {
        this._selectedLocations = locationIds

        if (locationIds == null) {
            this.selectedLocationsSource.error("Location ids are null!")
        }

        this.selectedLocationsSource.next(locationIds)
    }
    /* Locations */

    getLocations() {
        // console.log("Entro al get locations");
        let api = `${this.endpoint}/location-groups`;
        let resApi = this.http
            .get(api, { headers: this.headers, responseType: "json" })
            .pipe(retry(this.retry), catchError(this.handleError));

        let list: LocationGroup[] = [];
        resApi.subscribe({
            next: (res: any) => {
                list = this.recursiveGroup(res);
                this.locationGroupsRes = list
                this._flatLocations = generateFlatLocations(this.locationGroupsRes);
                // console.log("flat locations", this._flatLocations);
            }
        })
    }

    recursiveGroup(res: any): LocationGroup[] {
        // console.log("res", res);
        let locationGroup: LocationGroup[] = [];
        if (res.length > 0) {
            res.map((item: any) => {
                let locations: HubLocation[] = [];
                item.locations.map((location: any) => {
                    locations.push({
                        id: location.location_id,
                        name: location.location_name
                    })
                })
                let groups: LocationGroup[] = []
                if (item.groups.length > 0) {
                    let aGroup = this.recursiveGroup(item.groups)
                    if (aGroup.length > 0) {
                        groups = aGroup;
                    }
                }

                locationGroup.push({
                    id: item.id,
                    name: item.name,
                    locations: locations,
                    groups: groups
                })

            })
        }
        return locationGroup
    }

    /* Handle Error */

    handleError(error: HttpErrorResponse) {
        let msg = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
            // client-side error
            msg = error.error.message;
        } else {
            // server-side error
            msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(msg);
    }

    // load(): Promise<any> {
    //     return this.getLocations()
    //         .toPromise()
    //         .then(
    //             data => {
    //                 // this.dictionaries.set("DISCARD_REASONS",data);
    //                 // let i = 0;
    //                 let list: LocationGroup[] = [];

    //                 // console.log("res", res)
    //                 // i++;
    //                 list = this.recursiveGroup(data);
    //                 this.locationGroupsRes = list
    //                 this._flatLocations = generateFlatLocations(this.locationGroupsRes);
    //                 // console.log("flat locations", this._flatLocations);

    //             }
    //         )
    // }

    constructor(private http: HttpClient) {
        // this._flatLocations = generateFlatLocations(locationGroups);
        this.getLocations();
        
        // this._flatLocations = generateFlatLocations(locationGroups);
    }

}
<div class="new-event-dialog__container">
    <div>
        <h1 mat-dialog-title
        [ngStyle]="{'background-color':event.color}"

            ><span *ngIf="data.isDrill">This is a Drill </span>{{ title }}</h1>
    </div>
    <div class="event__container d-flex mb-4">
        <div class="logo d-flex justify-content-center align-items-center">
            <img style="width: 93px;" [src]="'../../../../assets/images/icons/' + event.icon" alt="icon">
        </div>
        <div class="info ms-2">
            <p class="text">{{ text }}</p>
            <p class="text__school">{{ event.name }}</p>
            <p class="text">{{ data.locationName }}</p>
        </div>
    </div>
    <div class="new-event-button__container d-flex mt-2">
        <div class="ms-auto">
            <button class="building__buttons dismiss__button me-2" (click)="dismiss()">Dismiss</button>
            <button class="building__buttons save__button" (click)="viewEvent()"
            [ngStyle]="{'border-color': event.color, 'color': event.color}"
                >View</button>
        </div>
    </div>
</div>

<div class="main-container">
    <hub-header></hub-header>
    <hub-sidenav></hub-sidenav>
    <div class="content">
        <!-- <hub-locations></hub-locations> -->
        <div class="grey-3" style="margin-top: 64px;">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>
<hub-footer style="display:none"></hub-footer>


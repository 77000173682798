import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BlueprintsService } from "src/app/core/services/blueprints.service";
import { DevicesService } from "src/app/core/services/devices.service";

export interface DialogData {
  title: string,
  content: string,
  activation: any
}

@Component({
  templateUrl: "./dialog-display.component.html",
  styleUrls: ["./dialog-display.component.scss"],
})
export class DialogDisplayComponent {

  isImageLoading: boolean = true;
  imageToShow: any = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogDisplayComponent>,
    private devicesService: DevicesService) { }


  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
      //  console.log("image to show", this.imageToShow);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getEventImg(eventId: string, path: string) {
    this.isImageLoading = true;
    this.devicesService.getEditEventImage(this.data.activation.locationId, eventId, path).subscribe((res: any) => {
      this.createImageFromBlob(res);
      this.isImageLoading = false;
    }, error => {
      this.openSnackBar(error.message, 'Ok');
      this.isImageLoading = true;
    })
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  closeDialog(msg: string): void {
    this.dialogRef.close(msg);
  }



  ngOnInit() {
    this.getEventImg(this.data.activation.description.eventTypeId, this.data.activation.description.eventTypeImage);
  }

}





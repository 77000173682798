import { Component, Input, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'event-breakdown-time',
  templateUrl: './event-breakdown-time.component.html',
  styleUrls: ['./event-breakdown-time.component.scss']
})
export class EventBreakdownTimeComponent {

  @Input('content') content: string = "";

  loadingData: boolean = true;
  percentage: boolean = false;
  dataShared: any = undefined;
  @Input('dataShared') set dataSharedSet(value: any) {
    this.dataShared = value;
    this.lineChartData = [];
    this.loadingData = true;
    if (this.dataShared?.payload) {
      this.eventTypes = this.dataShared.eventTypesUniques;
      this.setLineChart();
    } else {
      this.loadingData = false;
    }
  }

  get dataSharedSet(): any {
    return this.dataShared;
  }

  locationData: any = undefined;
  @Input('locationData') set locationDataSet(value: any) {
    this.loadingData = true;
    this.locationData = value;
    // console.log("loc this", this.locationData);

    this.eventTypes = this.locationData.eventTypes;
    if (this.locationData.events_timeline.length > 0) {
      let events: any[] = [];
      this.locationData.events_timeline.map((evtln: any) => {
        events.push({
          breakdown: evtln.breakdown,
          id: evtln.event_type_id
        })
      })
      setTimeout(() => {
        this.setEvents(events, this.locationData.range);
      }, 1000)
    } else {
      this.loadingData = false;
    }
  }

  get locationDataSet(): any {
    return this.locationData;
  }

  @Input('percentage') set percentageSet(value: any) {
    this.percentage = value;
  }

  get percentageSet(): any {
    return this.percentage;
  }

  eventTypes: any = undefined;
  accountName: string = "";

  public lineChartData: any[] = [];
  public lineChartLabels: any[] = []
  public lineChartOptions: any = {
    pie: undefined,
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      y: {
        position: 'left',
        ticks: {
          suggestedMax: 10
        }
      }
    },
    annotation: {}
  };
  public lineChartColors: any[] = [];
  public lineChartLegend: boolean = false;
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective | null = null;

  constructor(private eventService: EventsService,
    private _snackBar: MatSnackBar) { }


  capitalize(str: string) {
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
      const lower = words[i].toLowerCase()
      words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
    }

    return words.join(" ");
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  setLineChart() {
    let payload = {
      ...this.dataShared.payload,
      date_interval: this.dataShared.range,
      is_drill: this.dataShared.content === 'drill' ? true : false
    }
    this.eventService.getDashboardGeneralInfoGraph(payload).subscribe((res: any) => {
      this.accountName = res.account_name;
      this.setEvents(res.event_types, this.dataShared.range);
    }, error => {
      this.openSnackBar(error.message, 'Ok');
    })

  }


  getLineChartData(): any {
    return this.lineChartData || [];
  }

  setEvents(res: any, range: string) {
    this.lineChartColors = [];
    this.lineChartData = [];
    let months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let datesLabel: any[] = [];
    res.map((evt: any) => {
      this.eventTypes.map((evUniques: any) => {
        if (evUniques.event_type_id === evt.id) {
          let data: any[] = []
          datesLabel = [];
          evt.breakdown.map((br: any) => {
            data.push(br.count);
            datesLabel.push(new Date(br.date));
          })
          this.lineChartData.push({
            data: data,
            label: this.capitalize(evUniques.name),
            lineTension: 0,
            backgroundColor: 'transparent',
            borderColor: evUniques.color,
            pointBackgroundColor: evUniques.color,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
          })
          this.lineChartColors.push({
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
          })
        }
      })
    })

    this.lineChartLabels = [];
    switch (range) {
      case 'hour':
        datesLabel?.map((dl: any) => {
          this.lineChartLabels.push(`${dl.getHours()}:${dl.getMinutes()}`)
        });
        break;
      case 'day':
        datesLabel?.map((dl: any) => {
          this.lineChartLabels.push(`${days[dl.getDay()]}`);
        });
        break;
      case 'week':
        datesLabel?.map((dl: any) => {
          this.lineChartLabels.push(`${dl.toDateString()}`);
        });
        break;
      case 'month':
        datesLabel?.map((dl: any) => {
          this.lineChartLabels.push(`${months[dl.getMonth()]}`);
        });
        break;
      case 'year':
        datesLabel?.map((dl: any) => {
          this.lineChartLabels.push(`${dl.getFullYear()}`);
        });
    }
    this.loadingData = false;
  }

  public chartClicked({ event, active }: { event?: any, active?: object[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: any, active: {}[] }): void {
    // console.log(event, active);
  }

}

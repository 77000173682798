import { HubLocation } from "./hub-location"

export class FlatLocation {

    /** The path containing the full path and the location name */
    get fullPath(): string {
        return `${this.groupPath}/${this.name}`
    }

    /** Returns the path of groups to which the [location] belongs */
    get groupPath(): string {
        const path = this.groups.map((group: any) => group.name).join("/")
        return `/${path}`
    }

    /** Returns the name of the current location */
    get name(): string {
        return this.location.name
    }

    /** Returns the id of the location in this component */
    get id(): string {
        return this.location.id
    }

    constructor(public groups: GroupData[], public location: HubLocation) { }
}

export interface GroupData {
    name: string
    id: string
}

<div class="container_groups slim-scrollbar">
    <hh-progress-bar *ngIf="loadingData"></hh-progress-bar>
    <div class="d-flex groups-title">
        <div class="img-header">
            <img width="50" src="./assets/images/SVG/icon_siteGroup.svg">
        </div>

        <div class="group__header">
            <p class="group__header-title">Group(s)</p>
            <p class="group__header-sub">Event Breakdown</p>
            <p class="group__header-date">{{ dataShared.date }}</p>
        </div>
        <div class="search-input">
            <mat-form-field class="search-input-field" appearance="fill">
                <mat-label>Search Group Names</mat-label>
                <input matInput type="text" [(ngModel)]="searchValue" (ngModelChange)="searchGroup(searchValue)">
                <button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''; searchGroup('')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
        </div>
    </div>
    <div *ngIf="originalDonuts.length > 0" class="row groups_donuts">
        <div *ngFor="let chart of donuts" class="col-md-6">
            <donut-chart [dataShared]="chart" [percentage]="percentage"></donut-chart>
        </div>
    </div>
    <div *ngIf="originalDonuts?.length == 0 && !loadingData" class="d-flex empty-state"></div>
</div>
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EventTypesLocation } from 'src/app/core/models/event-types-location';
import { DrillService } from 'src/app/core/services/drills.service';
import { EventsService } from 'src/app/core/services/events.service';

export interface DialogData {
  id: string;
  title: string;
  button: string;
  content: string;
  eventType: EventTypesLocation;
  location: any;
  from: string;
  event: any;
}

@Component({
  selector: 'create-clear-event',
  templateUrl: './create-clear-event.component.html',
  styleUrls: ['./create-clear-event.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateClearEventComponent implements OnInit, AfterViewInit {
  actions: any;
  userProfile: any;
  user: any;

  constructor(
    public dialogRef: MatDialogRef<CreateClearEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private eventsService: EventsService,
    private fb: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private drillsService: DrillService
  ) {}

  confirmButton = false;

  myForm: FormGroup = this.fb.group({
    code: [, [Validators.required]],
  });

  getErrorMessage(campo: string) {
    this.enableButton();
    return (
      this.myForm.controls[campo].errors && this.myForm.controls[campo].touched
    );
  }

  enableButton() {
    if (this.myForm.valid) {
      this.confirmButton = true;
    } else {
      this.confirmButton = false;
    }
  }

  ngOnInit(): void {
    // console.log("this data", this.data);
    if (this.data.event) {
      this.actions = this.data.event.event_actions;
    }
    this.userProfile = sessionStorage.getItem('user-profile');
    this.user = JSON.parse(this.userProfile);
  }

  ngAfterViewInit() {
    if (this.data.event) {
      let button = <HTMLButtonElement>document.getElementById('confirmButton');
      button.disabled = false;
      button.classList.remove('disabled__button');
    }
  }

  capitalize(str: string): string {
    const words = str.split(' ');

    for (let i = 0; i < words.length; i++) {
      const lower = words[i].toLowerCase();
      words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1);
    }

    return words.join(' ');
  }

  confirm() {
    let loadBar = document.getElementById('loading-bar');
    if (loadBar) loadBar.style.display = 'block';
    let button = <HTMLButtonElement>document.getElementById('confirmButton');
    button.disabled = true;
    button.classList.add('disabled__button');

    if (this.data.event) {
      let time = new Date().toISOString();
      let event = {
        user_id: this.user.id,
        event_type_location_id: this.data.event.id,
        event_datetime_utc: time,
      };
      this.eventsService.createEvent(this.data.location.id, event).subscribe({
        next: (res: any) => {
          if (res.status === '') {
            this._snackBar.open('Event successfully added!', 'Ok', {
              duration: 3000,
            });
            this.dialogRef.close('');
            if (this.data.location.system_mode === 2) {
              
              this.drillsService.callDrillsEvent('');
              if (this.router.url == '/secured/drill/active-drills' || this.router.url == `/secured/drill/active-drills?locationId=${this.data.location.id}`) {
                // this.router
                //   .navigateByUrl('/', {
                //     skipLocationChange: true,
                //   })
                //   .then(() => {
                //     this.router.navigate(['/secured/drill/active-drills']);
                //   });
                this.eventsService.sendRefreshHomeComponents(
                  '',
                  this.data.location.id,
                  true
                );
              } else {
                this.router.navigateByUrl('/secured/drill/active-drills');
              }
            } else {
              if (
                this.router.url == '/secured/home' ||
                this.router.url ==
                  `/secured/home?locationId=${this.data.location.id}`
              ) {
                setTimeout(() => {
                  this.eventsService.sendRefreshHomeComponents(
                    '',
                    this.data.location.id,
                    true
                  );
                }, 1000);
              } else {
                this.router.navigateByUrl('/secured/home');
              }
            }
          } else {
            button.disabled = false;
            button.classList.remove('disabled__button');
            this._snackBar.open(res.status, 'Ok', {
              duration: 7000,
            });
          }
        },
        error: (error) => {
          if (loadBar) loadBar.style.display = 'none';
          button.disabled = false;
          button.classList.remove('disabled__button');

          this.openSnackBar(error.message, 'Ok');
        },
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  dismiss(msg: string) {
    this.dialogRef.close(msg);
  }

  clearEvent() {
    let loadBar = document.getElementById('loading-bar');
    if (loadBar) loadBar.style.display = 'block';
    let cmfBtn = <HTMLButtonElement>document.getElementById('confirmButton');
    cmfBtn.disabled = true;
    let code = <HTMLInputElement>document.getElementById('code');
    let payload = {
      event_instance_group_id: this.data.id,
      location_id: this.data.location.id,
      code: code.value,
    };
    this.eventsService.clearEvent(payload).subscribe(
      (res: any) => {
        this.dismiss('cleared');        
        if (this.data.from != 'settings') {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/secured/home']);
            });
        }
      },
      (error) => {
        let loadBar = document.getElementById('loading-bar');
        let button = <HTMLButtonElement>(
          document.getElementById('confirmButton')
        );
        if (loadBar) loadBar.style.display = 'none';
        button.disabled = false;
        button.classList.remove('disabled__button');

        this.openSnackBar(error.message, 'Ok');
      }
    );
  }
}

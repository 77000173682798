<div class="container slim-scrollbar" style="height: 100%;">
    <div class="timeline">
        <div *ngIf="!data.clearedBy && data.activations.length > 0" class="d-flex justify-content-center">
            <div class="timeline__status mb-4">EVENT IN PROGRESS</div>
        </div>
        <div *ngIf="data.clearedBy && data.activations.length > 0" class="d-flex justify-content-center">
            <div class="timeline__status mb-5">EVENT CLEARED</div>
            <div class="event-cleared">
                <span class="time-label__time-span">by {{ data.clearedBy}}</span>
            </div>
        </div>

        <div [id]="'event-row' + activationIndex"
            *ngFor="let activation of data.activations; let activationIndex = index" class="event__row">
            <div *ngIf="activationIndex == (data.activations.length - 1); else regularTimeLabel"
                class="time-label__container time-label__container--activated"
                [class.time-label--left]="timeLabelClassMap(activationIndex)['time-label--left']"
                [class.time-label--right]="timeLabelClassMap(activationIndex)['time-label--right']">
                <span class="time-label__time-span">{{ activation.time | date:'yyyy-MM-dd hh:mm a'}}</span>
            </div>
            <ng-template #regularTimeLabel>
                <div class="time-label__container"
                    [class.time-label--left]="timeLabelClassMap(activationIndex)['time-label--left']"
                    [class.time-label--right]="timeLabelClassMap(activationIndex)['time-label--right']">
                    <span class="time-label__time-span">{{ activation.time | date:'yyyy-MM-dd hh:mm a' }}</span>
                </div>
            </ng-template>
            <span class="event-row__dot"></span>
            <!-- <div class="event-card d-flex" [ngClass]="cardClassMap(activationIndex)"> -->
            <div id="ev-crd-{{activation.id}}" class="event-card d-flex" #cardEvent
                [ngClass]="activation.title === 'Attendance' && activation?._rank > 1 ? 'old-attendance' : ''"
                [class.event-card--right]="cardClassMap(activationIndex)['event-card--right']"
                [class.event-card--left]="cardClassMap(activationIndex)['event-card--left']"
                (mouseover)="highlightCard(cardEvent, activation, activationIndex)" (mouseout)="undoHighlightCard(cardEvent)"
                >
                <div class="p-2 d-flex flex-column justify-content-center align-items-center info-card-gen"
                    [style.background-color]="activation.backgroundColor">
                    <img width="40px"
                        [src]="'./assets/images/icons/timeline_' + activation.activationIcon">
                    <div *ngIf="activation.title == 'Attendance'" class="people-num"> {{ activation.description.people }} </div>
                    <div *ngIf="activation.title == 'Attendance'" class="reported-text">REPORTED</div>
                </div>
                <div *ngIf="isWhite(activation.backgroundColor)"
                    style="width: 1px; content: ''; background-color: #DADADA;"></div>
                <div class="event-card__text flex-grow-1 mx-3 my-2 text-dark-iptv-grey">
                    <div style="font-weight: 500;line-height: 1.1; text-transform: uppercase;">{{ activation.title }}</div>
                    <div class="events" *ngIf="activation.title == 'Attendance' && activation.isEvent">
                        <strong>{{ activation.description.area_name }}</strong> <br>
                        <span style="font-size: 16px;">{{ activation.description.activatedBy }}</span> <br>
                        <span style="font-size: 12px;">{{ activation.description.details }}</span>
                    </div>
                    <div class="events" *ngIf="activation.title != 'Attendance' && activation.isEvent">
                        <strong>{{ activation.description.area_name }}</strong> <br>
                        <span *ngIf="activation.description.activatedBy" style="font-size: 16px;">{{ activation.description.activatedBy }}</span> <br>
                        <span style="font-size: 14px;">{{ activation.description.eventType }}</span> <br>
                        <button *ngIf="activation.dialogData" mat-stroked-button color="warn" (click)="openDialog(activation)">
                            View Content <img width="25px" src="./assets/images/icons/icon_havrion_connect.svg">
                        </button>
                    </div>
                    <div class="noEvents" *ngIf="!activation.isEvent">
                        <!-- <p><strong>Requested by:</strong> {{ activation.description.activatedBy }}</p> -->
                        <p *ngIf="activation.record =='communication' || activation.record =='event_instances_communications'; else toClear">
                            <strong>{{ activation.description.text }}</strong>
                        </p>
                        <ng-template #toClear>
                            <p >{{ activation.description.activatedBy }}</p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="timeline__line-container d-flex">
            <div [style.margin-bottom.px]="lastCardHeight" class="timeline__line"
                [class.timeline__line--event-in-progress]="!data.clearedBy"></div>
        </div>
    </div>
</div>

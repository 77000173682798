import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

export const environment = {
  production: false,
  havrionHubApi: {
    url: 'https://hhuiapi-01-dev.azurewebsites.net/api',
    codeGet: 'pzYgQ9Xjc3jTrggA/YN67CYbdCxoOPeT6eufcKR07qwqFweMiWiWpQ=='
  },
  mapsAPI: {
    urlGeocoding: 'https://maps.googleapis.com/maps/api/geocode/json',
    key: "AIzaSyAJ6W_TdAASjcAxvDTaHEwtJDlBE-XZx0k" // Key de prueba
    // key: 'AIzaSyC3a7d0ftbekd3XpuSdSAtIaqKofR4HbnU'
  },
  azure_b2c: {
    tenant: "havrionhubb2c",
    clientId: "5db828a1-f301-442d-86de-c2a7c3b67b87",
    redirectUri: "https://hub-dev.havrion.com/login",
    postLogoutRedirectUri: "https://hub-dev.havrion.com/login",
    redirectUriLogin: "https://hub-dev.havrion.com/login",
    userFlows: {
      signIn: `b2c_1a_signup_signin_totp_phone`,
      signUp: `B2C_1_Signup`,
      passwordReset: `b2c_1a_passwordreset_totp_phone`,
    },
    otherKnownAutorities: <string[]>[],
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
    guardConfig: <MsalGuardConfiguration>{
      // Needed only if using MsalGuard
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['openid'],
      },
      loginFailedRoute: '/login',
    },
    interceptorConfig: <MsalInterceptorConfiguration>{
      // Needed only if using msal interceptor
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        ['http://localhost:3500', ['openid']],
        // Add other resources required by the Application
      ]),
    },
  }, 
};

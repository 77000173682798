import { Component, Input, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EventDataDash } from "src/app/core/models/event-data-dash";
import { SortingDirection } from "src/app/core/models/global-types";
import { EventsService } from "src/app/core/services/events.service";
import { rotateIcon180Trigger, toggleCollapseTrigger } from "src/app/core/utils/collapse-animations";

@Component({
    selector: 'location-metrics',
    templateUrl: './location-metrics.component.html',
    styleUrls: ['./location-metrics.component.scss'],
    animations: [toggleCollapseTrigger, rotateIcon180Trigger]
})
export class LocationMetricsComponent {

    searchValue = '';

    @Input('percentage') percentage: boolean = false;

    dataShared: any;

    @Input('dataShared') set dataSharedSet(value: any) {
        this.dataShared = value;
        this.loadingData = true;
        // console.log("llega datashare", this.dataShared);

        if (this.dataShared?.payload) {
            this.searchValue = ''
            // this.events = [];
            this.eventTypes = this.dataShared.eventTypesUniques;
            this.getLocationsDashInfo()

        } else {
            this.loadingData = false;
            this.groups = [];
            this.locations = [];
        }
    }

    get dataSharedSet(): any {
        return this.dataShared;
    }

    readonly tableSections: string[] = [
        "Location", "Total Events"
    ]

    // /** The list of sorted activation methods */
    // public sortedEvents: EventDataDash[]

    // events: EventDataDash[] = [];
    eventTypes: any;

    // private locationsSubscription: Subscription;
    selectedLocationsIds: string[] = [];

    /** The index of the currently opened event */
    openedLocationIndex: number | null = null;
    openedGroupIndex: number | null = null;
    openedGroupLocIndex: number | null = null;

    dataPercentage: any;

    sortingIndex: number = 0;
    sortingDirection: SortingDirection = "ascending"

    loadingData = true;

    groups: any[] = [];
    locations: any[] = []
    originalData: any;
    sortedArray: any;

    sorting: boolean = false;

    @Input('sorting') set sortingSet(value: any) {
        this.sorting = value;
        this.openedGroupIndex = null;
        this.openedGroupLocIndex = null;
        this.openedLocationIndex = null;
        this.sortingCheck();
    }

    get sortingSet(): any {
        return this.sorting;
    }

    constructor(private eventService: EventsService,
        private _snackBar: MatSnackBar) { }

    sortingCheck() {
        if (this.sorting) {
            this.sortedArray.groups.sort(function (a: any, b: any) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (b.name.toLowerCase() < a.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

            this.sortedArray.groups.map((gr: any) => {
                gr.locations.sort(function (a: any, b: any) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (b.name.toLowerCase() < a.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            })

            this.sortedArray.locations.sort(function (a: any, b: any) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (b.name.toLowerCase() < a.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        } else {
            if (this.sortedArray) {
                this.sortedArray.groups.sort(function (a: any, b: any) { return b.total_group_events - a.total_group_events });
                this.sortedArray.groups.map((gr: any) => {
                    gr.locations.sort(function (a: any, b: any) { return b.total_location_events - a.total_location_events });
                })
                this.sortedArray.locations.sort(function (a: any, b: any) { return b.total_location_events - a.total_location_events });
            }
        }
        if (this.sortedArray) {
            this.groups = this.sortedArray.groups;
            this.locations = this.sortedArray.locations;
        }

    }

    searchLocation(name: string) {
        this.sortedArray = this.originalData;
        if (name != '') {
            let newGroups = this.sortedArray.groups.filter((item: any) => (item.name.toLowerCase()).includes(name.toLowerCase()));
            let newLocations = this.sortedArray.locations.filter((item: any) => (item.name.toLowerCase()).includes(name.toLowerCase()));
            this.sortedArray = {
                groups: newGroups,
                locations: newLocations
            };
        }
        this.groups = this.sortedArray.groups;
        this.locations = this.sortedArray.locations;

    }

    getLocationsDashInfo() {
        let payload = {
            ...this.dataShared.payload,
            date_interval: this.dataShared.range,
            is_drill: this.dataShared.content === 'drill' ? true : false
        }
        this.eventService.getDashboardLocationMetrics(payload).subscribe((res: any) => {
            // console.log("res loc metrix", res);
            this.groups = res.groups;
            this.locations = res.locations;
            // this.setGroups(res.groups);
            this.sortedArray = {
                groups: res.groups.sort(function (a: any, b: any) { return b.total_group_events - a.total_group_events }),
                locations: res.locations.sort(function (a: any, b: any) { return b.total_location_events - a.total_location_events }),
            }
            this.sortingCheck();
            this.originalData = this.sortedArray;
            this.loadingData = false;
        })
    }



    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 7000,
        });
    }

    // expandAllGroups() {
    //     for (let i = 0; i < this.groups.length; i++) {
    //         this.toggleGroup(i); 
    //     }

    // }

    /** Toggles the accordion of the event at the given [eventIndex] */
    toggleLocation(locationIndex: number) {
        this.openedLocationIndex = locationIndex === this.openedLocationIndex ? null : locationIndex
    }

    toggleGroup(groupIndex: number) {
        this.openedGroupIndex = groupIndex === this.openedGroupIndex ? null : groupIndex
    }

    toggleGroupLoc(groupLocIndex: number) {
        this.openedGroupLocIndex = groupLocIndex === this.openedGroupLocIndex ? null : groupLocIndex
    }

    /** The state of the rotation icon for the event at the [eventIndex] */
    eventIconState(locationIndex: number): string {
        return this.openedLocationIndex === locationIndex ? 'rotated' : 'default'
    }

    groupIconState(groupIndex: number): string {
        return this.openedGroupIndex === groupIndex ? 'rotated' : 'default'
    }

    groupoLocIconState(groupLocIndex: number): string {
        return this.openedGroupLocIndex === groupLocIndex ? 'rotated' : 'default'
    }



    // /** Called when a section in the header is clicked */
    // public headerSectionClicked(sectionIndex: number) {
    //     console.log("entra", sectionIndex);
    //     console.log("sorted index", this.sortingIndex)
    //     if (this.sortingIndex === sectionIndex) {
    //         this.sortingDirection = this.sortingDirection === 'ascending' ? 'descending' : 'ascending'
    //     } else {
    //         this.sortingIndex = sectionIndex
    //         this.sortingDirection = 'ascending'
    //     }

    //     // this.rebuildSortedActivations()
    // }

    /** Rebilds the [sortedActivations] based on the sorting index and sorting direction */
    // private rebuildSortedActivations() {
    //     const directionMultiplier = this.sortingDirection === "ascending" ? 1 : -1 // Reverses the direction if necessary
    //     this.sortedEvents = [...this.events].sort((event1, event2) => {

    //         switch (this.sortingIndex) {
    //             case 0:
    //                 return event1.location_name.localeCompare(event2.location_name) * directionMultiplier
    //             case 1:
    //                 return (event1.total_events - event2.total_events) * directionMultiplier
    //         }

    //         return 0
    //     })
    // }

    // setEvents(res: any) {
    //     res.map(item => {
    //         let eventTypes: any[] = [];
    //         item.event_types.map(evt => {
    //             let evType: any;
    //             this.eventTypes.map(et => {
    //                 if (et.event_type_id === evt.event_type_id) {
    //                     evType = et;
    //                 }
    //             })
    //             eventTypes.push({
    //                 event_name: this.capitalize(evt.event_type_location_name),
    //                 color: evType.color,
    //                 event_count: evt.total_events,
    //                 backgroundColor: evType.background_color,
    //                 icon: "../../../../assets/images/icons/" + evType.icon,
    //                 breakdown_date: [
    //                     { start: "mm/dd", end: "mm/dd", count: 3 },
    //                     { start: "mm/dd", end: "mm/dd", count: 4 },
    //                     { start: "mm/dd", end: "mm/dd", count: 5 },
    //                 ]
    //             })
    //         })
    //         this.events.push({
    //             location_name: item.location_name,
    //             total_events: item.location_total_events,
    //             event_breakdown: eventTypes
    //         })
    //     })
    //     // this.loadingData = false;
    //     this.rebuildSortedActivations()
    // }

    capitalize(str: string) {
        const words = str.split(" ");

        for (let i = 0; i < words.length; i++) {
            const lower = words[i].toLowerCase()
            words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
        }

        return words.join(" ");
    }


}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) { }

  /* Negotiate */

  negotiate() {
    let api = `${this.endpoint}/negotiate`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      } 
    }else {
        // server-side error
        msg = {
          code: error.status,
          message: error.message
        };
      }
      return throwError(msg);
  }

}

<mat-card appearance="outlined" style="height: 100%; background-color: white; padding-top: 10px; padding-left: 35px; padding-right: 35px;" >
    <!-- <hh-progress-bar *ngIf="loadingData"></hh-progress-bar> -->
    <!-- <div class="d-flex flex-row text-dark-iptv attendance-head">
        <div class="event-grid-cell event-grid-cell--first">Event Type</div>
        <div class="event-grid-cell event-grid-cell--second">Location</div>
        <div class="event-grid-cell event-grid-cell--last">Attendance</div>
    </div> -->
    <simple-divider></simple-divider>
    <!-- <div *ngIf="schools?.length === 0 || (!loadingData && !schools)" class="empty-state-hhub"></div> -->
    <div *ngIf="buildings?.length === 0 || (!buildings)" class="empty-state-hhub"></div>
    <div style="overflow-y: auto; min-height: 430px;" class="schools-acoordion__scrollbar">
        <!-- <div *ngFor="let school of schools; let schoolIndex = index" style="background-color: #fff;"> -->
            <!-- <div class="d-flex flex-row click-element school-section" style="height: 80px;"
                (click)="toggleSchool(school.id)">
                <div [ngStyle]="{ 'backgroundColor': school.type.backgroundColor}" class="event-type event-type--slim">
                    <img style="opacity: 1;" [src]="'./assets/images/icons/' + school.type.icon">
                </div>
                <div id="location-name" style="font-size: 24px; line-height: 1.2;"
                    class="flex-grow-1 align-self-center px-2 text-dark-iptv">
                    {{school.name}}
                </div>
                <div class="attendace-reported bg-light-white d-flex flex-row px-3 my-2">
                    <div id="total-location-attendance" style="font-size: 52px;" class="brand-color">
                        {{school.totalReported}}</div>
                    <span class="ms-2 align-self-center text-dark-iptv-grey"
                        style="line-height: 1.2; font-size: 14px;">Total<br>reported</span>
                </div>
                <div class="px-3 align-self-center text-dark-iptv">
                    <mat-icon [@rotateIcon180]="schoolIconState(school.id)">expand_more</mat-icon>
                </div>
            </div> -->
            <!-- <div [@toggleCollapse] *ngIf="school.id === openSchoolIndex"> -->
                <!-- <div *ngFor="let building of school.buildings; let buildingIndex = index"
                    style="background-color: #EFEFEF;"> -->
                <div *ngFor="let building of buildings; let buildingIndex = index"
                    style="background-color: #EFEFEF;">
                    <!-- <div class="d-flex flex-row py-2 bg-light-white click-element building-section"
                        (click)="toggleBuilding(schoolIndex, buildingIndex)"> -->
                    <div class="d-flex flex-row py-2 bg-light-white click-element building-section"
                        (click)="toggleBuilding(buildingIndex)" [ngClass]="{'highlighted': building.highlighted}">
                        <!-- <div class="flex-grow-1 ms-4 my-auto text-dark-iptv">{{building.name}}</div> -->
                        <div id="location-name" style="font-size: 24px; line-height: 1.2;"
                            class="flex-grow-1 align-self-center ms-4 my-auto text-dark-iptv">
                            {{ building.name | titlecase}}
                        </div>
                        <!-- <div>
                            <span id="total-attendance-building" style="font-size: 24px;"
                                class="text-dark-iptv">{{building.totalReported}}</span>
                            <span style="font-size: 14px;" class="ms-2 text-dark-iptv-grey">Reported</span>
                        </div> -->
                        <div class="attendace-reported d-flex flex-row px-3 my-2">
                            <div id="total-location-attendance" style="font-size: 52px;" class="brand-color">
                                {{building.totalReported}}
                            </div>
                            <span class="ms-2 align-self-center text-dark-iptv-grey"
                                style="line-height: 1.2; font-size: 14px;">Reported</span>
                        </div>
                        <div class="ps-3 pe-4 align-self-center text-dark-iptv">
                            <mat-icon [@rotateIcon180]="buildingIconState(buildingIndex)">
                                expand_more
                            </mat-icon>
                        </div>
                    </div>
                    <!-- <div [@toggleCollapse] *ngIf="openBuildingIndices[schoolIndex] === buildingIndex"
                        style="background-color: #EFEFEF; max-height: 350px; overflow-y: auto; margin-right: 30px;"
                        class="px-3 events-table__scrollbar"> -->
                    <div [@toggleCollapse] *ngIf="openBuildingIndex === buildingIndex"
                        style="background-color: #EFEFEF; max-height: 350px; overflow-y: auto; margin-right: 30px;"
                        class="px-3 events-table__scrollbar">
                        <table style="font-size: 14px;" class="table table-borderless mb-0 pt-2 text-grey">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Reported by</th>
                                    <th>Details</th>
                                    <th>People</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let hubEvent of building.events" class="row-attendance"  (mouseover)="highlightAttend(true, hubEvent, building.id)" (mouseout)="highlightAttend(false)">
                                    <td>{{ hubEvent.time | date:'hh:mm a' }}</td>
                                    <td>{{ hubEvent.reportedBy }}</td>
                                    <td>{{ hubEvent.location }}</td>
                                    <td>{{ hubEvent.people }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <simple-divider *ngIf="buildingIndex < buildings.length - 1"></simple-divider>
                </div>
            <!-- </div> -->
            <simple-divider></simple-divider>
        <!-- </div> -->
    </div>
</mat-card>
import { LabelPoints4Size } from "../models/location.model";

export class USGSOverlayComponent extends google.maps.OverlayView {
  private bounds: google.maps.LatLngBounds | undefined;
  private text: string;
  private div?: HTMLElement;
  private content: string;
  private data?: any
  private labelPoint?: LabelPoints4Size;

  constructor(text: string, content: string, bounds?: google.maps.LatLngBounds,  data?: any, labelPoint?: LabelPoints4Size) {
    super();

    this.bounds = bounds;
    this.text = text;
    this.content = content
    this.data = data;
    this.labelPoint = labelPoint;
  }

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  override onAdd() {
    const panes = this.getPanes()!;

    if (this.content == "areaName") {
      this.div = document.createElement("div");
      this.div.style.display = "block"
      this.div.style.borderStyle = "none";
      this.div.style.borderWidth = "0px";
      this.div.style.position = "absolute";
      this.div.style.backgroundColor = "#3b3b3b"
      this.div.style.color = "#FFFFFF";
      this.div.style.textAlign = "center";
      this.div.style.borderRadius = "20px";
      this.div.style.zIndex = "99";
      this.div.style.fontSize = "16px";
      this.div.style.fontWeight = "bolder";
      this.div.style.paddingLeft = "10px";
      this.div.style.paddingRight = "10px";
      this.div.style.whiteSpace = "nowrap";
      this.div.style.overflow = "hidden";
      this.div.style.textOverflow = "ellipsis !important";


      // Create the img element and attach it to the div.
      // const img = document.createElement("img");
      const p: any = document.createElement("p");

      // img.src = this.image;
      // img.style.width = "100%";
      // img.style.height = "100%";
      // img.style.position = "absolute";
      // this.div.appendChild(img);
      p.innerHTML = this.text;
      this.div.appendChild(p);

      // Add the element to the "overlayLayer" pane.

      panes.overlayLayer.appendChild(this.div);
    }

    if (this.content == "areaInfo") {
      this.div = document.createElement("div");
      this.div.style.display = "block"
      this.div.style.position = "absolute";
      this.div.style.zIndex = "100";
      this.div.innerHTML = "<map-overlays id='customOverlay'></map-overlays>";
      panes.floatPane.appendChild(this.div);
      let customOverlay = document.getElementById('customOverlay');
      customOverlay?.setAttribute('data', JSON.stringify(this.data));
    }

    if (this.content == "labelGrid") {
      this.div = document.createElement("div");
      this.div.style.display = "block";
      this.div.style.position = "absolute";
      this.div.style.backgroundColor = "#747575";
      this.div.style.color = "#fff";
      this.div.style.textAlign = "center";
      this.div.style.fontWeight = "bold";
      // this.div.style.width = "20px";
      this.div.innerHTML = this.text;

      panes.overlayLayer.appendChild(this.div);
    }

  }

  override draw() {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    const overlayProjection = this.getProjection();

    // Retrieve the south-west and north-east coordinates of this overlay
    // in LatLngs and convert them to pixel coordinates.
    // We'll use these coordinates to resize the div.
    let sw, ne, labelPointPixel;
    if(this.bounds){
      sw = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getSouthWest()
      )!;
      ne = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getNorthEast()
      )!;
    }

    let bottomPointPixel, rightPointPixel;

    if(this.labelPoint){
      labelPointPixel = overlayProjection.fromLatLngToDivPixel(
        this.labelPoint.mainPoint
      )!;
      bottomPointPixel = overlayProjection.fromLatLngToDivPixel(
        this.labelPoint.bottomPoint
      )!;
      rightPointPixel  = overlayProjection.fromLatLngToDivPixel(
        this.labelPoint.rightPoint
      )!;
    }


    if (this.content == "areaName") {
      // Resize the image's div to fit the indicated dimensions.
      if (this.div) {
        this.div.style.left = sw?.x + "px";
        // this.div.style.top = ne.y + "px";
        this.div.style.top = sw?.y + "px";
        // this.div.style.width = ne.x - sw.x + "px";
        // this.div.style.height = sw.y - ne.y + "px";
        this.div.style.width = "100px";
        this.div.style.height = "20px";
      }
    }

    if (this.content == "areaInfo") {

      if (this.div) {
        this.div.style.left = ne?.x + "px";
        this.div.style.top = ne?.y + "px";
      }
    }

    if (this.content == "labelGrid") {
      if (this.div) {
        this.div.style.left = labelPointPixel?.x + "px";
        this.div.style.top = labelPointPixel?.y + "px";
        if(rightPointPixel && labelPointPixel && bottomPointPixel){
          this.div.style.width = (rightPointPixel.x - labelPointPixel.x) + "px";
          this.div.style.height = (bottomPointPixel.y - labelPointPixel.y) + "px";
          this.div.style.lineHeight = (bottomPointPixel.y - labelPointPixel.y) + "px";
        }
      }
    }


  }

  /**
   * The onRemove() method will be called automatically from the API if
   * we ever set the overlay's map property to 'null'.
   */
  override onRemove() {
    if (this.div) {
      (this.div.parentNode as HTMLElement).removeChild(this.div);
      delete this.div;
    }
  }

  /**
   *  Set the visibility to 'hidden' or 'visible'.
   */
  hide() {
    if (this.div) {
      this.div.style.visibility = "hidden";
    }
  }

  show() {
    if (this.div) {
      this.div.style.visibility = "visible";
    }
  }

  toggle() {
    if (this.div) {
      if (this.div.style.visibility === "hidden") {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  toggleDOM(map: google.maps.Map) {
    if (this.getMap()) {
      this.setMap(null);
    } else {
      this.setMap(map);
    }
  }
}

<div class="container-locations slim-scrollbar">
    <hh-progress-bar *ngIf="loadingData"></hh-progress-bar>
    <div class="d-flex locations-title">
        <div class="img-header">
            <img width="40" src="./assets/images/SVG/Icon_Location.svg" alt="">
        </div>

        <div class="locations__header">
            <p class="locations__header-title">Location(s)</p>
            <p class="locations__header-sub">Event Breakdown</p>
            <p class="locations__header-date">{{ dataShared.date }}</p>
        </div>

        <div class="search-input">
            <mat-form-field class="search-input-field" appearance="fill">
                <mat-label>Search Location Names</mat-label>
                <input
                    matInput
                    type="text"
                    [(ngModel)]="searchValue"
                    (ngModelChange)="searchLocation(searchValue)"
                >
                    <button
                        *ngIf="searchValue"
                        matSuffix mat-icon-button
                        aria-label="Clear"
                        (click)="searchValue='';
                        searchLocation('')"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
              </mat-form-field>
        </div>
    </div>
    <div *ngIf="barChartData.length > 0" class="row horizontal-chart">
        <div class="col-md-12" style="min-height: 360px;">
            <div style="display: block; ">
                <canvas
                    height="600"
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [type]="barChartType">
                </canvas>
            </div>
        </div>
    </div>
    <div
        *ngIf="barChartData?.length == 0 && !loadingData"
        class="d-flex empty-state"
    >
    </div>
</div>

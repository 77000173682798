<form [formGroup]="siteForm">
    <div class="row" style="width: 100%;">
        <div [class]="selectedLocationType == 'group' ? 'col-12' : 'col-md-6'">
            <mat-form-field>
                <label>Location type</label>
                <mat-select formControlName='siteType'>
                    <mat-option value="group">Location group</mat-option>
                    <mat-option value="physical">Physical location</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <label>Parent group</label>
                <mat-select formControlName="parentGroup">
                    <mat-option>Select One</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ nameInputText }}</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>
        </div>
        <div *ngIf="selectedLocationType == 'physical'" class="col-md-6">
            <div #siteMap style="height: 400px;"></div>

        </div>
    </div>
</form>
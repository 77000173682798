import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry} from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { SubscriptionModel } from '../models/subscriptionModel';
import { TimeliesResponse } from '../models/timeline-response';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    responseType: "json"
  });
  retry = 0;

  private subjectMap = new Subject<any>();
  private subjectTotalEvents = new Subject<any>();
  private subjectSaveEventType = new Subject<any>();
  private subjectCloseEventType = new Subject<any>();
  private subjectOpenHistory = new Subject<any>();
  private subjectOpenDrillHistory = new Subject<any>();
  private subjectRefreshHeader = new Subject<any>();
  private subjectRefreshSolutionManagement = new Subject<any>();
  private subjectRefreshHome = new Subject<any>();
  private subjectMapShowAllLocations = new Subject<any>();
  private subjectDeactivateDrill = new Subject<any>();
  private subjectRefreshHomeComponentes = new Subject<any>();
  private subjectEventMapHighlight = new Subject<any>();
  private subjectMapEventHighlight = new Subject<any>();
  private subjectEventsSubs = new Subject<any>();


  constructor(private http: HttpClient) { }

  sendEventsSubs(data: SubscriptionModel){
    this.subjectEventsSubs.next(data);
  }

  getEventsSubs(){
    return this.subjectEventsSubs.asObservable();
  }

  sendMapEventHighlight(data: any){
    this.subjectMapEventHighlight.next(data);
  }

  getMapEventHighlight(){
    return this.subjectMapEventHighlight.asObservable();
  }

  sendEventMapHighlight(data: any){
    this.subjectEventMapHighlight.next(data);
  }

  getEventMapHighlight(){
    return this.subjectEventMapHighlight.asObservable();
  }

  sendRefreshHome(){
    this.subjectRefreshHome.next('');
  }

  getRefreshHome(){
    return this.subjectRefreshHome.asObservable();
  }

  sendRefresheader(){
    this.subjectRefreshHeader.next('');
  }

  getRefreshHeader(){
    return this.subjectRefreshHeader.asObservable();
  }

  sendRefreshSolutionManagement(){
    this.subjectRefreshSolutionManagement.next('');
  }

  getRefreshSolutionManagement(){
    return this.subjectRefreshSolutionManagement.asObservable();
  }

  sendOpenDrillHistory(eventId:string, locationId: string){
    this.subjectOpenDrillHistory.next({eventId: eventId, locationId: locationId});
  }

  getOpenDrillHistory(){
    return this.subjectOpenDrillHistory.asObservable();
  }

  sendClickDeactivateDrill(elem: string, hubEvent: any){
    this.subjectDeactivateDrill.next({elem: elem, hubEvent: hubEvent});
  }

  getClickDeactivateDrill(){
    return this.subjectDeactivateDrill.asObservable();
  }

  sendOpenHistory(eventId:string){
    this.subjectOpenHistory.next(eventId);
  }

  getOpenHistory(){
    return this.subjectOpenHistory.asObservable();
  }

  sendCloseEventType(msg:string){
    this.subjectCloseEventType.next(msg);
  }

  getCloseEventType(){
    return this.subjectCloseEventType.asObservable();
  }
  
  sendClickSaveEventType(){
    this.subjectSaveEventType.next('');
  }

  getClickSaveEventType(){
    return this.subjectSaveEventType.asObservable();
  }

  sendClickTotalEvents(data: any) {
    this.subjectTotalEvents.next(data);
  }

  getClickTotalEvents(): Observable<any>{ 
    return this.subjectTotalEvents.asObservable();
  }

  sendRefreshHomeComponents(eventId: string, locationId: string,  updateData?: boolean){
    this.subjectRefreshHomeComponentes.next({
      eventId: eventId, locationId: locationId, updateData: updateData});
  }

  getRefreshHomeComponents(): Observable<any>{ 
    return this.subjectRefreshHomeComponentes.asObservable();
  }

  sendClickMapLocation(locationId: string, updateData: boolean) {
    // console.log("send click", eventId);
    this.subjectMap.next({locationId: locationId, updateData: updateData});
  }

  getClickMapLocation(): Observable<any>{ 
    return this.subjectMap.asObservable();
  }

  sendShowAllLocations(){
    this.subjectMapShowAllLocations.next('');
  }

  getShowAllLocations(){
    return this.subjectMapShowAllLocations.asObservable();
  }

  // getEventTimelines(payload: Object){
  //   let api = `${this.endpoint}/event-instance-groups/timelines`;
  //   return this.http
  //     .post(api, payload, { headers: this.headers, responseType: "json" },)
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  /**Version 2 of Timelines */
  getEventTimelines(payload: Object): Observable<TimeliesResponse[]>{
    let api = `${this.endpoint}/v2/event-instance-groups/timelines`;
    return this.http
      .post<TimeliesResponse[]>(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventPriority(){
    let api = `${this.endpoint}/event-instance-groups/priority`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventAttendances(payload: Object){
    let api = `${this.endpoint}/event-instance-groups/attendances`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  // getSingleEventTimelines(payload: Object){
  //   let api = `${this.endpoint}/event-instance-group/timeline`;
  //   return this.http
  //     .post(api, payload, { headers: this.headers, responseType: "json" },)
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  /**Version 2 of Timelines */
  getSingleEventTimelines(payload: Object){
    let api = `${this.endpoint}/v2/event-instance-group/timeline`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getSingleEventAttendances(payload: Object){
    let api = `${this.endpoint}/event-instance-group/attendance`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  // getEventTypes(locationId: string) {
  //   let api = `${this.endpoint}/location/${locationId}/event-types`;
  //   return this.http
  //     .get(api, { headers: this.headers, responseType: "json" },)
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  getEventTypes(payload: Object) {
    let api = `${this.endpoint}/event-types`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventInstance(locationId: string, eventInstanceId: string) {
    let api = `${this.endpoint}/location/${locationId}/event-instance/${eventInstanceId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventTypesLocationGroup(locationGroupId: string) {
    let api = `${this.endpoint}/location-group/${locationGroupId}/event-types`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventType(locationId: string, eventId: string) {
    let api = `${this.endpoint}/location/${locationId}/event-type/${eventId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  setEventType(locationId: string, eventId: string, payload: Object) {
    let api = `${this.endpoint}/location/${locationId}/event-type/${eventId}`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  clearEventRequest(payload: Object){
    let api = `${this.endpoint}/event-instance-group/clear-request`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  createEvent(locationId: string, event: any) {
    let api = `${this.endpoint}/location/${locationId}/event-instances`;
    return this.http
      .post(api, event, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  clearEvent(payload: Object){
    let api = `${this.endpoint}/event-instance-group/clear`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventHistory(payload: Object){
    let api = `${this.endpoint}/event-instance-groups/history`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventTypesDashboard(payload: Object){
    let api = `${this.endpoint}/locations/event-types/dashboard`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventTypesUniques(payload: Object){
    let api = `${this.endpoint}/event-types/locations/uniques`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getActivationMethodsDashboard(payload: Object){
    let api = `${this.endpoint}/locations/activation-methods/dashboard`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDashboardGeneralInfo(payload: Object){
    let api = `${this.endpoint}/dashboard/general-info`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDashboardGeneralInfoGroups(payload: Object){
    let api = `${this.endpoint}/dashboard/general-info/groups`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDashboardGeneralInfoLocations(payload: Object){
    let api = `${this.endpoint}/dashboard/general-info/locations`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDashboardGeneralInfoGraph(payload: Object){
    let api = `${this.endpoint}/dashboard/general-info/graph`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDashboardLocationMetrics(payload: Object){
    let api = `${this.endpoint}/dashboard/locations/metrics`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      console.log('error', error)
      if(error.error?.message){
        msg = {
          code: error.status,
          message: error.error.message
        };
      }else{
        msg.code = error.status;
      }
      
    }
    return throwError(msg);
  }
}

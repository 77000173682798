import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {

  lineChartOptions:  ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    maintainAspectRatio: false
  }

  eventLabels: string[] = [];
  eventCounts: number[] = [];
  backgroundColor: any;

  totalActMeth: any;
  totalEvents: number| null = null;
  loadingData = true;

  dataShared: any;
  eventCountsAux: any;
  eventCountsPercentage: any;
  @Input('dataShared') set dataSharedSet(value: any) {
    this.dataShared = value;
    this.loadingData = true;
    this.totalActMeth = undefined
    this.totalEvents = null;
    this.eventLabels = [];
    this.eventCounts = [];
    this.backgroundColor = [];
    this.setChart();
  }

  get dataSharedSet(): any {
    return this.dataShared;
  }

  percentage: boolean = false;
  @Input('percentage') set percentageSet(value: any) {
    this.percentage = value;
    this.changetoPercentage();
  }

  get percentageSet(): any {
    return this.percentage;
  }

  constructor() { }

  ngOnInit(): void {
  }

  changetoPercentage() {
    if (this.percentage) {
      this.eventCountsAux = this.eventCounts;
      if (this.eventCountsPercentage) {
        this.eventCounts = this.eventCountsPercentage;
      } else {
        let sumEvents = 0
        this.eventCountsPercentage = [];
        this.eventCounts.map((value: any) => {
          sumEvents = sumEvents + value;
        })
        if (sumEvents != 0) {
          this.eventCounts.map((value: any) => {
            this.eventCountsPercentage.push((value * 100 / sumEvents).toFixed());
          })
          this.eventCounts = this.eventCountsPercentage;
        }
      }
    } else {
      this.eventCounts = this.eventCountsAux;
    }
  }


  setChart() {
    if (this.dataShared.eventCounts.length > 0) {
      this.eventCounts = this.dataShared.eventCounts;
      this.eventLabels = this.dataShared.eventLabels;
      this.backgroundColor = this.dataShared.backgroundColor
      this.totalEvents = this.dataShared.totalEvents;
      this.eventCountsAux = this.eventCounts;
    } else {
      this.eventCounts = [100];
      this.backgroundColor = ['rgb(0, 0, 0, 0.1)']
      this.totalEvents = 0;
    }
    this.eventCountsAux = this.eventCounts;
  }
}

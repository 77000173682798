import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { mapRolesToModel, Role } from '../../models/roles';
import { OneTimeService } from '../../services/onetime.service';
import { UsersService } from '../../services/users.service';
import { ActivationMethod } from '../../models/activation-method';


@Component({
  selector: 'app-oidc2',
  templateUrl: './oidc2.component.html',
  styleUrls: ['./oidc2.component.scss']
})
export class Oidc2Component implements OnInit {

  constructor(
    private router: Router,
    private oneTimeService: OneTimeService,
    private userService: UsersService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    // console.log("entra al oidc");

    this.userService.getUserProfile().subscribe({
      next: (user: any) => {
        // console.log("res user profile", user);
        if (!user.is_web) {
          // this.authService.logout();
          this.userService.sendLogout();
        } else {
          sessionStorage.setItem('user-profile', JSON.stringify(user));
          if (!user.has_accepted_tc_web) {
            this.router.navigate(['/terms-of-use']);
          } else if (!user.has_accepted_pp_web) {
            // console.log('entro al has_accepted_pp_web en login');
            this.router.navigate(['/terms-of-use']);
          } else {
           
            
            // Roles
            this.oneTimeService.getRoles().subscribe({
              next: (res: any) => {
                let roles = mapRolesToModel(res);
                sessionStorage.setItem('roles', JSON.stringify(roles));
              },
              error: error => {
                this.openSnackBar(error.message, 'Ok');
              }
            })
  
            // Activatio Methods
            let activationMethods: ActivationMethod[] = [];
            this.oneTimeService.getActivationMethods().subscribe({
              next: (res: any) => {
                res.map((item: any) => {
                  activationMethods.push({
                    id: item.id,
                    description: item.description,
                    backgroundColor: item.background_color,
                    icon: item.icon
                  })
                })
                sessionStorage.setItem('activationMethods', JSON.stringify(activationMethods));
              },
              error: error => {
                this.openSnackBar(error.message, 'Ok');
              }
            })
  
            this.router.navigateByUrl('/secured/home');
          }
        }
      },
      error: error => {
        this.openSnackBar(error.message, 'Ok');
      }
    })

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

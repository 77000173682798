import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { HomeRoutingModule } from "./home-routing.module";
import { HomeComponent } from "./home.component";
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        CommonModule,
        HomeRoutingModule
    ],
    declarations: [
        HomeComponent,
    ],
    exports: [
        HomeComponent
    ]
})
export class HomeModule { } 
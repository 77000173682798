<h1 mat-dialog-title>{{ data.title }}</h1>
<!-- <hh-progress-bar id="loading-bar"></hh-progress-bar> -->
<div mat-dialog-content>

  <!-- Content for "create a drill" -->

  <div *ngIf="data.content=='view-blueprints'" class="container-blp-dialog">
    <div [ngStyle]="{ 'backgroundColor': data.genEvType ? data.genEvType.backgroundColor : '#e6e4e1'}"
      class="p-4 d-flex area-loc-event-info">
      <img width="50px" src="./assets/images/icons/icon-blueprint.svg">
      <span class="ms-3 blp-loc-area-info">
        <p class="location-name">{{ data.area.location_name }}</p>
        <p class="area-name">{{ data.area.name }}: <strong>{{ blueprint.name }}</strong></p>
      </span>
      <span *ngIf="data.genEvType" class="d-flex blp-event-active">
        <img width="44px" [src]="'./assets/images/icons/' + data.genEvType.icon">
        <span style="font-weight: 500; line-height: 1.3; font-size: 16px;" class="ms-2 flex-grow-1 my-auto">{{
          data.genEvType.name }} <br>
          <span class="blp-act-met"><strong>via:</strong> {{ data.genActMeht }}</span>
        </span>
      </span>
    </div>

    <div class="d-flex blp-list-container-image">
      <div class="blp-image" *ngIf="!isImageLoading">
        <img id="image-tag" [src]="imageToShow">
      </div>
      <hh-progress-bar *ngIf="isImageLoading"></hh-progress-bar>
      <div class="blp-list">
        <p>
          <span class="head-list">Select Blueprint</span>
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" vertical>
            <mat-button-toggle *ngFor="let blp of data.blueprints" [value]="blp.id" (click)="getBlueprintImg(blp.id)" [checked]="blp.id == blueprint.id">
              {{ blp.name }}
            </mat-button-toggle>
            <!-- <mat-button-toggle value="italic">Italic</mat-button-toggle>
            <mat-button-toggle value="underline">Underline</mat-button-toggle> -->
          </mat-button-toggle-group>
        </p>
      </div>
    </div>
    
  </div>



</div>


<div mat-dialog-actions align="end" class="dialog-buttons">
  <button mat-button (click)="closeDialog('')">Close</button>
</div>
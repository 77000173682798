import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry, map } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlueprintsService {

  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    responseType: "json"
  });
  retry = 0;

  private subjectSaveDeviceBlueprint = new Subject<any>();
  private subjectCloseDialog = new Subject<any>();

  constructor(private http: HttpClient) { }

  sendClickCloseDialog(msg: string) {
    this.subjectCloseDialog.next(msg);
  }

  getClickCloseDialog(): Observable<any>{ 
    return this.subjectCloseDialog.asObservable();
  }

  sendClickSaveDeviceBlueprint() {
    this.subjectSaveDeviceBlueprint.next('');
  }

  getClickSaveDeviceBlueprint(): Observable<any> {
    return this.subjectSaveDeviceBlueprint.asObservable();
  }

  getBlueprints(locationId: string, buildingId: string){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprints`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getBlueprintDisplays(locationId: string, buildingId: string, blueprintId: string){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprint/${blueprintId}/displays`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getbBlueprintImg(locationId: string, buildingId: string, blueprintId: string){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprint-image?blueprint_id=${blueprintId}`;
    return this.http
      .get(api, { responseType: 'blob' })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getBlueprintSensors(locationId: string, buildingId: string, blueprintId: string){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprint/${blueprintId}/sensors`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateBlueprintDisplays(locationId: string, buildingId: string, blueprintId: string, payload: Object){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprint/${blueprintId}/displays`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateBlueprintSensors(locationId: string, buildingId: string, blueprintId: string, payload: Object){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprint/${blueprintId}/sensors`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  createBlueprint(locationId: string, buildingId: string, payload: any) {
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprints`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateBlueprint(locationId: string, buildingId: string, blueprintId: string, payload: any) {
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprint/${blueprintId}`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  deleteBlueprint(locationId: string, buildingId: string, blueprintId: string) {
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/blueprint/${blueprintId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error.message
      };
    }
    return throwError(msg);
  }
}

<!-- <div class="fixed-div"> -->
    <div class="progress-bar-container">
        <mat-progress-bar 
        mode="indeterminate" 
        color="accent"         
        strokeWidth='3' 
        >
        </mat-progress-bar>
    </div>
<!-- </div> -->

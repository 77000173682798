import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TermsGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let data: string = sessionStorage.getItem('user-profile') ?? '';
      let userProfile = JSON.parse(data);
      if (userProfile.has_accepted_pp_web && userProfile.has_accepted_tc_web) {
        this.router.navigate(['/secured/home']);
        return false;
      }

    return true;
  }
  
}

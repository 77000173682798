<!-- <div class="pantone-black-c">
    test
</div> -->
<mat-toolbar class="havrion-toolbar" [ngStyle]="{ 'backgroundColor': maxEvent?.color }">
  <button mat-icon-button class="d-sm-block d-md-none">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="logo__container">
    <a href="/secured/home">
      <img src="../assets/images/HH-logo.png" style="width: 120px;margin: 10px 0 0 10px;" alt="Havrion Hub Logo"
        class="d-sm-block d-md-none" />
      <img src="../assets/images/HH-logo.png" style="width: 160px;" alt="Havrion Hub Logo"
        class="d-none d-md-block h-thermo-logo" />
      <img src="../assets/images/HH-logo.png" style="width: 160px;" alt="Havrion Hub Logo"
        class="h-thermo-logo-print" />
    </a>
  </div>
  <hub-locations class="ms-4" *ngIf="!(router.url.includes('settings') || router.url.includes('dash'))"></hub-locations>

  <div *ngIf="!(router.url.includes('settings') || router.url.includes('dash'))"
    class="activate__event d-flex me-5 justify-content-center align-items-center"
    [ngClass]="{'act-button-disabled': disableActivateEvent}">
    <div class="div-tooltip d-flex" #tooltip='matTooltip' [matTooltip]="disableActivateEvent ? messageOnTooltip : ''"
      matTooltipPosition="below" matTooltipClass="customMatTooltip">
      <!-- <div class="div-tooltip d-flex"> -->
      <img class="logo" src="../../../../assets/images/icons/icon_menu_disarmed2.svg" alt="">
      <p>Activate Event</p>
      <!-- <span class="tooltip">I am a tooltip!</span> -->
    </div>
    <div class="action ms-auto">
      <button class="btn__action" [matMenuTriggerFor]="menu" (click)="showingEvents(selectedLocId)"
        [disabled]="disableActivateEvent">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <span *ngFor="let item of events; let i = index;">
          <span *ngIf="item.event_types?.length === 0">
            <button mat-menu-item class="d-flex align-items-center btn_event_main" (click)="openDialog('createEvent', i)"
              [disabled]="item.disabled">
              <span class="btn_title">{{ item.name }}</span>
              <img class="ms-auto" style="width: 38px;" [src]="'../../../../assets/images/icons/'+item.icon" alt=""
                [ngClass]="{'act-button-disabled': item.disabled}">
            </button>
          </span>
          <span *ngIf="item.event_types && item.event_types.length > 0">
            <button mat-menu-item class="d-flex align-items-center btn_event" id="btn_event"
              [matMenuTriggerFor]="shtrEvs" [matMenuTriggerData]="{nestedEvents: item.event_types}"
              [disabled]="item.disabled" [ngClass]="{'mat-menu-item[disabled]::after': item.disabled}">
              <span class="btn_title">{{ item.name }}</span>
              <img class="ms-auto" style="width: 38px;margin-right:-16px;"
                [src]="'../../../../assets/images/icons/'+item.icon" alt=""
                [ngClass]="{'img-button-disabled': item.disabled}">
            </button>
          </span>

        </span>
      </mat-menu>
      <mat-menu class="mat__menu-system" #shtrEvs="matMenu">
        <ng-template matMenuContent let-nestedEvents="nestedEvents">
          <span *ngFor="let elem of nestedEvents; let i = index;">
            <button mat-menu-item class="d-flex align-items-center" (click)="openDialog('createEvent', i, elem)"
              [disabled]="elem.disabled">
              <span>{{ elem.name }}</span>
            </button>
          </span>
        </ng-template>
      </mat-menu>
    </div>
  </div>

  <div id="navbar-main" class="d-flex justify-content-center align-items-center">
    <div class="events__progress d-flex" *ngIf="timelineRes">

      <img *ngIf="multipleEvents" style="width: 35px;" class="mt-1"
        src="../../../../assets/images/icons/armed_white.svg" alt="Multiple Events">
      <div *ngIf="!multipleEvents" class="icons-events-active">
        <img style="width: 35px;" [src]="'../../../../assets/images/icons/' + maxEvent.icon" alt="event icon">
      </div>

      <div class="ms-2">
        <p class="event__name">{{ multipleEvents ? 'multiple events' : maxEvent?.name }}</p>
        <p class="progress__text">in progress</p>
      </div>
    </div>
    <div class="loadingData" *ngIf="loadingData">
      <hh-progress-bar></hh-progress-bar>
    </div>
    <div *ngIf="timelineRes" class="border__separator ms-3"></div>
    <ul class="nav navbar-nav">

      <li>
        <button mat-button aria-label="Menu with option to Logout" [matMenuTriggerFor]="menu" style="line-height: 50px; height: 50px;"
          [ngStyle]="{ 'backgroundColor': maxEvent?.color}" [ngClass]="{'profile__button': !maxEvent}">
          <ng-template>
            <mat-icon>account_circle</mat-icon>
          </ng-template>
          &nbsp;
          <span class="d-none d-md-inline-block">{{ name }}</span>
          <mat-icon iconPositionEnd class="d-none d-md-inline-block">keyboard_arrow_down</mat-icon>
          <mat-icon iconPositionEnd class="d-sm-inline-block d-md-none">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <span class="d-sm-block d-md-none" style="display: block; width: 100%; padding: 15px;">
            <mat-icon>account_circle</mat-icon>&nbsp;{{ fullName }}
          </span>
          <button mat-menu-item routerLink="/secured/profile">
            <mat-icon>account_circle</mat-icon>&nbsp;
            <span>Profile</span>
          </button>
          <button *ngIf="userAccounts.length > 1" mat-menu-item [matMenuTriggerFor]="accountsMenu">
            <mat-icon>group</mat-icon>
            <span>Accounts</span>
          </button>
          <button mat-menu-item [matMenuTriggerFor]="support">
            <mat-icon>support_agent</mat-icon>
            <span>Support</span>
          </button>
          <!-- <a [href]="passwordResetAuthority"> -->
          <a>
            <button mat-menu-item (click)="resetPassword()">
              <!-- <button mat-menu-item> -->
              <mat-icon>lock_open</mat-icon>
              <span>Reset Password</span>&nbsp;&nbsp;
            </button>
          </a>
          <button (click)="logout()" mat-menu-item type="button">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>&nbsp;&nbsp;
          </button>

          <!-- <button (click)="openDialog()" mat-menu-item  type="button">
              <span>new event</span>&nbsp;&nbsp;
            </button> -->

        </mat-menu>
      </li>
    </ul>
    <mat-menu #accountsMenu="matMenu">
      <span class="accounts-menu" *ngFor="let item of userAccounts">
        <button mat-menu-item (click)="changeAccount(item.id)" [disabled]="item.id == profileUser?.account_id">
            {{ item.name }}
        </button>
      </span>
    </mat-menu>
    <mat-menu #support="matMenu">
      <span *ngFor="let item of supports; let i = index;">
        <button mat-menu-item (click)="generateEmail(i)">
          <a [href]="mailtoMessage" class="ms-3 support__link">
            {{ item }}
          </a>
        </button>
      </span>
    </mat-menu>
  </div>
</mat-toolbar>
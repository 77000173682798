import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/app/shared/shared.module";
import { DrillComponent } from "./drill.component";
import { CommonModule } from '@angular/common';
import { DrillRoutingModule } from "./drill-routing.module";
import { EventsDrillComponent } from "./drillevents/drillevents.component";
import { DialogComponent } from "./dialog/dialog.component";
import { MaterialModule } from "src/app/shared/material.module";
import { DrillDashboardComponent } from './drill-dashboard/drill-dashboard.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        FormsModule,
        CommonModule,
        DrillRoutingModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    declarations: [
        DrillComponent,
        EventsDrillComponent,
        DialogComponent,
        DrillDashboardComponent
    ],
    exports: [
        DrillComponent
    ]
})
export class DrillModule { }
import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { Subscription } from "rxjs";
import { SelectedLocationsService } from '../../../core/services/selected-locations.service';
import { EventsService } from 'src/app/core/services/events.service';
import { MatSnackBar } from '@angular/material/snack-bar';
// import moment from 'moment';
// import { MatDatepickerInputEvent } from '@angular/material/datepicker';


interface Range {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeRangeComponent implements OnInit {

  timeRanges: Range[] = [
    { value: 'hour', viewValue: 'Hour' },
    { value: 'day', viewValue: 'Day' },
    { value: 'week', viewValue: 'Week' },
    { value: 'month', viewValue: 'Month' },
    { value: 'year', viewValue: 'Year' },
  ];

  selectedRange = 'month';

  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  startFrame: any = undefined;
  endFrame: any = undefined;
  itemArr = [];
  itemCountArr = [];

  events: any[] = [];
  allevents: any [] = []

  isChecked = false;
  isSorted = false;
  calendarView: "month" | "year" | "multi-year" = 'year';

  constructor(
    private selectedLocationsService: SelectedLocationsService,
    private eventService: EventsService,
    private _snackBar: MatSnackBar) {

  }

  @Output() toggleCheck = new EventEmitter<any>();
  @Output() toggleCheckSort = new EventEmitter<any>();

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      noDataAvailablePlaceholderText: 'Please select one or more locations',
      itemsShowLimit: 6,
      allowSearchFilter: false
    };
    this.getLastMonthRange();
    this.getEventTypesUniques();
  }

  getEventTypesUniques() {
    let payload = {
      location_ids: []
    }
    this.eventService.getEventTypesUniques(payload).subscribe((eventTypes: any) => {
      // console.log(eventTypes);
      this.allevents = [];
      eventTypes.map((item: any) => {
        this.allevents.push({
          id: item.code,
          description: item.name,
          eventTypeId: item.event_type_id
        })
      })

      this.allevents.map( l => {
        this.events.push(l);
      })
      /**This variable helps to keep the id of the events in order to send the data to the components */
      this.dropdownList = this.allevents;
      /** It is necessary to use another variable to control the item on the dropdown */
      this.selectedItems = this.allevents
    }, error => {
      this.openSnackBar(error.message, 'Ok');
    });
  }

  setRange(range: string) {
    if (range) {
      if (range == 'month') {
        this.calendarView = 'year';
      } else if (range == 'year') {
        this.calendarView = 'multi-year';
      } else {
        this.calendarView = 'month'
      }
      this.sendDataToComponents();
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  changed() {
    // console.log("check", this.isChecked);
    this.toggleCheck.emit(this.isChecked);
  }

  sortingChanged() {
    // console.log("check", this.isChecked);
    this.toggleCheckSort.emit(this.isSorted);
  }

  getLastMonthRange() {
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 2);
    lastMonth.setDate(1);
    let current = new Date();
    // this.rangeDate = {
    //   startDate: moment(`${lastMonth.getMonth() + 1}/${lastMonth.getDate()}/${lastMonth.getFullYear()}`),
    //   endDate: moment(`${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`)
    // }
    let start_date = `${lastMonth.getMonth() + 1}/${lastMonth.getDate()}/${lastMonth.getFullYear()}`;
    let end_date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
    this.startFrame = new Date(start_date);
    this.endFrame = new Date(end_date);
  }

  // rangeDateChanged(event) {
  //   if (event.startDate && event.endDate) {
  //     // console.log("event", event.startDate.format("YYYY-MM-DD"), event.endDate.format("YYYY-MM-DD"))
  //     this.sendDataToComponents();
  //   }
  // }

  setStartDate(date: any) {
    let end = <HTMLInputElement>document.getElementById('endDataRef');
    // console.log("show value", date, end.value);
    if (end.value && this.selectedRange && this.events.length > 0) {
      this.sendDataToComponents();
    }
    // console.log("start frame", this.startFrame)
  }

  setEndDate(date: any) {
    let start = <HTMLInputElement>document.getElementById('startDateRef');
    // console.log("end date", date, start.value);
    if (start.value && this.selectedRange && this.events.length > 0) {
      this.sendDataToComponents();
    }
  }

  print() {
    window.print();
  }

  sendDataToComponents() {
    // if (this.rangeDate.startDate && this.rangeDate.endDate && this.selectedRange && this.events.length > 0) {
    //   let data = {
    //     events: this.events,
    //     startDate: this.rangeDate.startDate.format("YYYY-MM-DD"),
    //     endDate: this.rangeDate.endDate.format("YYYY-MM-DD"),
    //     range: this.selectedRange,
    //   }
    //   this.eventService.sendClickTotalEvents(data);
    // }
    // console.log("send this.events", this.events)
    let start = <HTMLInputElement>document.getElementById('startDateRef');
    let end = <HTMLInputElement>document.getElementById('endDataRef');
    if (start.value && end.value && this.selectedRange && this.events.length > 0) {
      let data = {
        events: this.events,
        startDate: start.value,
        endDate: end.value,
        range: this.selectedRange
      }
      // console.log("data sent", data)
      this.eventService.sendClickTotalEvents(data);
    }
  }

  onItemSelect(item: any) {
    // console.log("item", item);
    // console.log("this.dropdownList", this.dropdownList);
    // console.log("this.events", this.events);
    this.dropdownList.map((dl: any) => {
      if (dl.id === item.id) {
        this.events.push(dl)
        this.sendDataToComponents();
      }
    })

  }

  onSelectAll(items: any) {
    // console.log("items select all", items);
    this.events = [];
    items.map((i: any) => {
      this.dropdownList.map((dl: any) => {
        if (dl.id === i.id) {
          this.events.push(dl);
        }
      })
    });
    this.sendDataToComponents();
  }

  onItemDeSelect(item: any) {
    // console.log("deselect this.dropdownlist", this.dropdownList)
    // console.log("item", item);
    let indexEvent = this.events.findIndex(ev => ev.id === item.id);
    // console.log("this.events", this.events)
    // console.log("indexEvent", indexEvent)
    this.events.splice(indexEvent, 1)
    // console.log("this.events", this.events)
    if (this.events.length === 0) {
      this.events = []
      this.eventService.sendClickTotalEvents(undefined);
    } else {
      this.sendDataToComponents();
    }
  }

  onDeSelectAll(items: any) {
    this.events = [];
    this.eventService.sendClickTotalEvents(undefined);
  }

  resetFilters() {
    this.getLastMonthRange();
    this.selectedItems = this.allevents
    this.events = [];
    this.allevents.map( l => {
      this.events.push(l);
    })
    this.selectedRange = "month";
    this.calendarView = 'year';
    setTimeout(() => {
      this.sendDataToComponents();
    }, 500);
  }

}

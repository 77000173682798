import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorInterceptor implements HttpInterceptor {


  public get idToken(): string | null {
    const account = this.authService.instance.getAllAccounts()?.[0];
    if (!account) {
      return null;
    }

    const [homeAccountId, environment] = [
      account.homeAccountId,
      account.environment,
    ];

    let audience, tid;
    if(account.idTokenClaims){
      audience = account.idTokenClaims?.['aud'];
      tid = account.idTokenClaims?.['tid'];
      sessionStorage.setItem('audience', audience ?? '');
      sessionStorage.setItem('tid', tid ?? '');
    }else{
      audience = sessionStorage.getItem('audience');
      tid = sessionStorage.getItem('tid');
    }

    if (!homeAccountId || !environment || !audience) {
      return null;
    }

    let tokenKey;

    if (tid) {
      tokenKey = `${homeAccountId}-${environment}-idtoken-${audience}-${tid}---`;
    } else {
      tokenKey = `${homeAccountId}-${environment}-idtoken-${audience}----`;
    }

    // const tokenKey = `${homeAccountId}-${environment}-idtoken-${audience}-${tid}---`;
    // console.log('tokenKey', tokenKey);

    let tokenStored = sessionStorage.getItem(tokenKey);

    let idTokenData: any = null

    if(tokenStored){
      idTokenData = JSON.parse(tokenStored);
    }else{
      return null
    }


    // console.log('idTokenData', idTokenData);

    return idTokenData?.['secret'];
  }

  constructor(private router: Router, private _snackBar: MatSnackBar,
              private authService: MsalService) {}

  // refreshToken() {
  //   this.authService.acquireTokenSilent({ scopes: ["openid","profile"] }).then(token => {
  //     sessionStorage.setItem('msal.idtoken', token.idToken.rawIdToken);
  //     location.reload();
  //   });
  // }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /** OLD STUFF */
    // this.token = sessionStorage.getItem('msal.idtoken');

    // let req = request;

    // if (!req.url.includes('maps.googleapis.com')) {
    //   if (this.token) {
    //     request = req.clone({
    //       setHeaders: {
    //         authorization: `Bearer ${ this.token }`
    //       }
    //     });
    //   }
    // }
    /** OLD STUFF */

    const jwt = this.idToken;
    // console.log('jwt', this.idToken);

    if (!jwt) {
      return next.handle(request);
    }

    const authRequest = request.clone({
      setHeaders: {
        Authorization: request.headers.get('Authorization') ?? `Bearer ${jwt}`,
      },
    });
    

    return next.handle(authRequest).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          err.error.message = "Your session has expired. You are being redirected to the login page."
          this.authService.logout();
        } else if (err.status === 400) {
          this._snackBar.open(err.message, 'Ok', {
            duration: 3000
          });
        } else if (err.status >= 500) {
          // this._snackBar.open('The system had an internal error. If you do not see the expected results, contact support.', '', {
          //   duration: 3000
          // });
          err.error.message = "The system had an internal error. If you do not see the expected results, contact support."
        }

        return throwError(() => err);

      })
    );
  }
}

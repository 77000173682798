<!-- <div class="d-flex flex-row align-items-start container__hub container__hub--event-lockdown" [style.background]="eventColor">
    <div class="d-flex flex-row align-items-center mb-1 ps-4 pe-3 flex-grow-1">
        <div class="locations-select d-flex align-items-center no-select" mat-button [matMenuTriggerFor]="locationsMenu" (click)="clearFilterText()" style="background-color: lightgreen;">
            <div class="flex-grow-1 one-line-text ms-2">{{ selectTitle }}</div>
            <mat-icon>expand_more</mat-icon>
        </div>
        <button *ngIf="currentPage === 'solutions-management' && isAdmin"  class="me-2 brand-color" mat-stroked-button (click)="showManageSiteDialog()">MANAGE SITES</button>
        <p style="font-size: 24px;" class="ms-2 my-auto text-grey"><span style="font-weight: bold;">{{ displayedLocations }}</span> DEPLOYED LOCATIONS</p>
        <mat-menu #locationsMenu="matMenu" yPosition="below" class="slim-scrollbar">
            <div class="px-3 pt-3 pb-2 menu__header">
                <input class="form-control text-dark-iptv" [(ngModel)]="filterText" type="text" placeholder="Filter locations" (ngModelChange)="buildFilteredLocations()" (click)="$event.stopPropagation(); $event.preventDefault();" (focusout)="refocus($event)">
                <div class="mt-2">
                    <button *ngIf="!areAllFilteredSelected" mat-button (click)="selectAllFiltered($event)" class="me-2">{{ selectAllText }}</button> 
                    <button *ngIf="selectedLocationIds.size !== 0" mat-button (click)="clearSelectedLocations($event)">Clear all</button>
                </div>
            </div>
            <div class="py-2 px-3 d-flex align-items-end menu__location-item no-select" *ngFor="let location of filteredFlatLocations" (click)="menuItemClicked($event, location)">
                <mat-checkbox [checked]="selectedLocationIds.has(location.id)" (click)="menuItemClicked($event, location)"></mat-checkbox> 
                <div class="ms-2">
                    <div [title]="location.groupPath" style="font-size: 10px;" class="text-grey one-line-text">{{ location.groupPath }}</div>
                    <div style="font-size: 16px; font-weight: 600;" class="text-dark-iptv one-line-text" [title]="location.name">{{ location.name }}</div>
                </div>
            </div>
        </mat-menu>
    </div>
    TODO: Modify based on event
    <div class="pe-4 ps-3 my-auto d-flex flex-row event-warning"> 
        <img class="me-2" src="./assets/images/warning-icon.svg">
        <span class="icon-warning icon me-2 bg-white my-auto" style="height: 40px; width: 45px;"></span>
        <div class="text-white">
            <span style="font-size: 14px;">{{ isEventActive ? 'MULTIPLE EVENTS IN PROGRESS' : isDrillActive ? 'DRILL IN PROGRESS' : '...'}}</span>
            <br>
            TODO: Bind based on active event
            <span>ACTIVE SHOOTER</span>
        </div>
    </div>
</div> -->

<div class="hub-locations-section">
    <div class="locations-select d-flex align-items-center no-select" mat-button [matMenuTriggerFor]="locationsMenu" (click)="clearFilterText();">
        <div class="flex-grow-1 one-line-text ms-2">
            <p class="locations__text">{{ selectTitle }}</p>
        </div>
        <mat-icon>expand_more</mat-icon>
    </div>
    <mat-menu #locationsMenu="matMenu" yPosition="below" class="slim-scrollbar">
        <div class="px-3 pt-3 pb-2 menu__header">
            <input class="form-control text-dark-iptv" [(ngModel)]="filterText" type="text" placeholder="Filter locations" (ngModelChange)="buildFilteredLocations();" (click)="$event.stopPropagation(); $event.preventDefault();" (focusout)="refocus($event)">
            <div class="mt-2">
                <button id="buttonSelectAll" *ngIf="!areAllFilteredSelected" mat-button (click)="selectAllFiltered($event)" class="me-2">{{ selectAllText }}</button> 
                <button *ngIf="selectedLocationIds.size !== 0" mat-button (click)="clearSelectedLocations($event)">Clear all</button>
            </div>
        </div>
        <div class="py-2 px-3 d-flex align-items-end menu__location-item no-select" *ngFor="let location of filteredFlatLocations" (click)="menuItemClicked($event, location)">
            <mat-checkbox [checked]="selectedLocationIds.has(location.id)" (click)="menuItemClicked($event, location)"></mat-checkbox> 
            <div class="ms-2">
                <div [title]="location.groupPath" style="font-size: 10px;" class="text-grey one-line-text">{{ location.groupPath }}</div>
                <div style="font-size: 16px; font-weight: 600;" class="text-dark-iptv one-line-text" [title]="location.name">{{ location.name }}</div>
            </div>
        </div>
    </mat-menu>
</div>

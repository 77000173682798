    <!-- <div *ngIf="content!='eventHistory'" class="events-info-container">
        <app-real-time></app-real-time>
    </div> -->
    <div class="home-flex" style="margin-left: 0px; margin-right: 0px;">
        <div class="" style="width: 100%;padding-left: 0px; padding-right: 0px">
            <map [data]="dataShareMap"></map>
            <!-- <div class="mt-3">
                <attendance [data]="dataShareAt"></attendance>
            </div> -->
        </div>
        <div class="timeline-container slim-scrollbar" style="padding-left: 0px; padding-right: 0px;">
            <events [data]="dataShareEv"></events>
        </div>
    </div>

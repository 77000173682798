<h1 mat-dialog-title>{{ data.title }}</h1>
<hh-progress-bar id="loading-bar"></hh-progress-bar>
<div mat-dialog-content>

  <!-- Content for "create a drill" -->

  <div *ngIf="data.content==='create' || data.content==='edit'">
    <form (ngSubmit)="send()" [formGroup]="myForm">
      <div class="create__container d-flex addUser">
        <div class="create__form-first d-flex flex-column">
          <div class="create__form-select">
            <mat-form-field appearance="fill">
              <mat-label>Activation Method:</mat-label>
              <mat-select id="activation" formControlName="selectedAct" name="activation">
                <mat-option *ngFor="let activation of activations" [value]="activation.value">
                  {{ activation.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" id="date" formControlName="selectedDate" readonly required />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-error *ngIf="getErrorMessage('selectedDate')">You must enter a value</mat-error>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>
        <div class="create__form-second d-flex flex-column">
          <div class="create__form-select">
            <mat-form-field appearance="fill">
              <mat-label>Event Scheduled:</mat-label>
              <mat-select id="event" #selector formControlName="selectedEvt" name="event" (selectionChange)="onSelectionChange($event.value)">
                <mat-select-trigger>
                  <span *ngFor="let event of events">
                    <span *ngIf="event.id === myForm.controls['selectedEvt'].value">
                      {{ event.name }} <img class="img-icon" width="25px"
                        [src]="'./assets/images/icons/' + event.icon">
                    </span>
                    <span *ngIf="event.id != myForm.controls['selectedEvt'].value">
                      <span *ngFor="let shelEvt of event.event_types">
                        <span *ngIf="shelEvt.id === myForm.controls['selectedEvt'].value">
                          {{ shelEvt.name }} <img class="img-icon-evt" width="30px"
                            [src]="'./assets/images/icons/' + shelEvt.icon">
                        </span>
                      </span>
                    </span>
                  </span>
                </mat-select-trigger>
                <span class="events-options" *ngFor="let event of events">
                  <mat-option [value]="event.id" (click)="event.event_types.length > 0 ? selector.open() : undefined">
                    <span *ngIf="event.event_types.length === 0">
                      {{ event.name }} <img class="img-icon" style=" top: 10px;" width="44px"
                        [src]="'./assets/images/icons/' + event.icon">
                    </span>
                    <span *ngIf="event.event_types.length > 0" style="color:black">
                      {{ event.name }} <img class="img-icon" style=" top: 10px;" width="44px"
                        [src]="'./assets/images/icons/' + event.icon">
                      <button id="nestedEventsButton" mat-icon-button [matMenuTriggerFor]="menu" class="buttonShelter" (click)="setNestedEvents(event.event_types)">
                        <mat-icon>arrow_right</mat-icon>
                      </button>
                    </span>
                  </mat-option>
                </span>     
                <mat-menu #menu="matMenu">
                  <mat-option *ngFor="let item of nestedEvents" [value]="item.id" style="width: 200px">
                    {{ item.name }}
                  </mat-option>
                </mat-menu>       
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Choose a time</mat-label>
            <input id="time" matInput aria-label="24hr format" formControlName="selectedTime" [disableClick]="true"
              [ngxTimepicker]="fullTime" [format]="24" readonly required>
            <ngx-material-timepicker-toggle matSuffix [for]="fullTime"></ngx-material-timepicker-toggle>
            <mat-error *ngIf="getErrorMessage('selectedTime')">You must enter a value</mat-error>
            <ngx-material-timepicker #fullTime [defaultTime]="currentTime()" [enableKeyboardInput]="true" #appendedToInput
              [appendToInput]="true"></ngx-material-timepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="confirm-msg" *ngIf="data.content == 'activateDrill'">
    <div>
      Are you sure you want to activate the drill:
    </div>
    <div class="event-type et-dialog">
      <span style="font-weight: bold;" class="ms-2 flex-grow-1 my-auto">{{data.eventType?.name}}</span>
      <img width="44px" [src]="'./assets/images/icons/' + data.eventType?.icon">
    </div>
  </div>

  <div class="confirm-msg" *ngIf="data.content == 'deactivateDrill'">
    <div>
      Are you sure you want to deactivate the drill:
    </div>
    <div class="event-type et-dialog">
      <span style="font-weight: bold;" class="ms-2 flex-grow-1 my-auto">{{data.eventType?.name}}</span>
      <img width="44px" [src]="'./assets/images/icons/' + data.eventType?.icon">
    </div>
  </div>

  <div class="confirm-msg" *ngIf="data.content == 'deleteDrill' || data.content == 'deleteDrillParent'">
    <div>
      Are you sure you want to delete this drill? <br>
      This action cannot be undone
    </div>
  </div>


  <!-- End "create drill" content -->

  <!-- Contents for "drill view" -->
  <div *ngIf="data.content=='drillview'" class="mx-4">
    <!-- <div class="row">
      <div class="col-lg-6 mt-3">
        <home-map></home-map>
        <div class="mt-3">
          <attendance></attendance>
        </div>
      </div>
      <div class="col-lg-6 mt-3">
        <events></events>
      </div>
    </div> -->
    <eventsinfo [content]="'drillHistory'" [eventHistory]="drillHistory"></eventsinfo>
  </div>
  <!-- End "drill view" content -->

</div>





<div mat-dialog-actions align="end" class="dialog-buttons">
  <button *ngIf="data.content=='editSensor'" class="delete-button" mat-raised-button color="primary"
    (click)="closeDialog('')">Delete</button>
  <button *ngIf="data.content=='edit'" class="delete-button" mat-raised-button color="primary"
    (click)="openDeleteDialog()">Delete</button>
  <button *ngIf="data.content!=='drillview'" mat-button (click)="closeDialog('')">Cancel</button>
  <button id="editBtn" *ngIf="data.content==='edit'" mat-raised-button color="primary" [disabled]="!sendButton"
    (click)="send(data.id)">{{ data.button
    }}</button>
  <button id="createBtn" *ngIf="data.content==='create'" mat-raised-button color="primary" (click)="send()"
    [disabled]="!createButton">{{ data.button }}</button>
  <!--
  <button
    *ngIf="data.content!=='drillview' && data.content!=='edit' && data.content!=='create' && data.content !='deleteDrillParent' && data.content !='deleteDrill'"
    mat-raised-button color="primary">{{ data.button }} hmm</button> -->
  <button id="activateDrillBtn" *ngIf="data.content === 'activateDrill'" mat-raised-button color="primary"
    (click)="activateDrill(data.id ?? '', data.locationId)" [disabled]="!activateDrillButton">{{ data.button }}</button>
  <button id="deactivateDrillBtn" *ngIf="data.content === 'deactivateDrill'" mat-raised-button color="primary"
    (click)="deactivateDrill(data.id ?? '', data.locationId)" [disabled]="!deactivateDrillButton">{{ data.button }}</button>
  <button *ngIf="data.content =='drillview'" mat-raised-button color="primary" (click)="closeDialog('')">Exit</button>
  <button id="deleteDrillParentBtn" *ngIf="data.content==='deleteDrillParent' || data.content==='deleteDrill'"
    mat-raised-button color="primary" [disabled]="!deleteDrillButton" (click)="deleteDrill()">{{ data.button }}</button>
</div>
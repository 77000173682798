import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'groups-donuts',
  templateUrl: './groups-donuts.component.html',
  styleUrls: ['./groups-donuts.component.scss']
})
export class GroupsDonutsComponent {

  searchValue = '';

  loadingData = true;
  eventTypes: any;

  groupAux = undefined;
  locAux = undefined;

  dataShared: any;
  donuts: any[] = [];
  originalDonuts: any[] = [];
  @Input('dataShared') set dataSharedSet(value: any) {
    this.dataShared = value;

    this.loadingData = true;

    if (this.dataShared?.payload) {
      this.searchValue = '';

      this.eventTypes = this.dataShared.eventTypesUniques;
      // console.log('evtypes', this.eventTypes);
      this.getGroupsInfo();
      // this.setActMethTotals();
    } else {
      this.originalDonuts = [];
      this.loadingData = false;
    }
  }

  get dataSharedSet(): any {
    return this.dataShared;
  }

  percentage: boolean = false;
  @Input('percentage') set percentageSet(value: any) {
    this.percentage = value;
  }

  get percentageSet(): any {
    return this.percentage;
  }

  sorting: boolean = false;

  @Input('sorting') set sortingSet(value: any) {
    this.sorting = value;
    if (this.groupAux || this.locAux) {
      this.donuts = [];
    }
    if (this.groupAux) {
      let sortedGroup = this.sortData(this.groupAux);
      sortedGroup.map((gr: any) => {
        this.addDatatoDonut(gr, true);
      })
    }
    if (this.locAux) {
      let sortedLoc = this.sortData(this.locAux);
      sortedLoc.map((loc: any) => {
        this.addDatatoDonut(loc, false);
      })
    }
  }

  get sortingSet(): any {
    return this.sorting;
  }

  constructor(private eventService: EventsService,
    private _snackBar: MatSnackBar) { }

  searchGroup(groupName: string) {
    // console.log("keyup", groupName)

    if (groupName != '') {
      let newArray = this.donuts.filter((item: any) => (item.name.toLowerCase()).includes(groupName.toLowerCase()));
      this.donuts = newArray;
    } else {
      this.donuts = this.originalDonuts;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  addDatatoDonut(data: any, isGroup: boolean) {
    let eventLabels: any = [];
    let eventCounts: any = [];
    let totalEvents = 0;
    let backgroundColor: any = []
    if (data.event_breakdown.length > 0) {
      data.event_breakdown.map((evbr: any) => {
        if (evbr.count > 0) {
          eventCounts.push(evbr.count);
          this.eventTypes.map((ev: any) => {
            if (evbr.event_type_id === ev.event_type_id) {
              eventLabels.push(ev.name);
              backgroundColor.push(ev.color);
            }
          })
        }
      })
    }
    if (eventCounts.length > 0) {
      totalEvents = eventCounts.reduce(function (a: any, b: any) {
        return a + b;
      }, 0);
    }
    this.donuts.push({
      name: data.name,
      eventCounts: eventCounts,
      eventLabels: eventLabels,
      backgroundColor: backgroundColor,
      totalEvents: totalEvents,
      isGroup: isGroup
    })
  }

  getGroupsInfo() {
    // Logic to send the data to the groups donuts components#
    this.donuts = [];
    let payload = {
      ...this.dataShared.payload,
      is_drill: this.dataShared.content === 'drill' ? true : false
    }
    this.eventService.getDashboardGeneralInfoGroups(payload).subscribe((res: any) => {
      // console.log("res group", res)
      this.groupAux = this.setFullData(res.groups);
      let sortedGroup = this.sortData(this.groupAux);
      // console.log("sortedGroup", sortedGroup)
      sortedGroup.map((gr: any) => {
        this.addDatatoDonut(gr, true);
      })
      this.locAux = this.setFullData(res.locations)
      let sortedLoc = this.sortData(this.locAux);
      // console.log("sortedLoc", sortedLoc)
      sortedLoc.map((loc: any) => {
        this.addDatatoDonut(loc, false);
      })
      this.originalDonuts = this.donuts;
      // console.log("donuts", this.originalDonuts);
      this.loadingData = false
    }, error => {
      this.openSnackBar(error.message, 'Ok');
    })
  }

  setFullData(res: any): any {
    let sortedArray = [];
    for (let i = 0; i < res.length; i++) {
      let totalCount = 0;
      for (let j = 0; j < res[i].event_breakdown.length; j++) {
        totalCount = totalCount + res[i].event_breakdown[j].count;
      }
      sortedArray.push({
        ...res[i],
        totalCount: totalCount
      })
    }
    return sortedArray
  }

  sortData(res: any): any {
    if (this.sorting) {
      res.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (b.name.toLowerCase() < a.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    } else {
      res.sort(function (a: any, b: any) { return b.totalCount - a.totalCount });
      // console.log('sorted', this.sortedArray);
    }
    return res
  }
}

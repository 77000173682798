import { AfterViewInit, Component, DoCheck, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { GoogleMap, GoogleMapOptions, LocationType, MapTypeId } from "src/app/core/models/global-types";
import { LocationType } from "src/app/core/models/global-types";
import { emptyValidator } from "src/app/core/utils/utility-functions";

@Component({
    selector: "site-form",
    templateUrl: "site-form.component.html",
    styleUrls: [
        "site-form.component.scss"
    ]
})
export class SiteFormComponent implements OnInit, DoCheck {

    readonly killianLat = 25.670241
    readonly killianLng = -80.3496469

    @ViewChild("siteMap", { static: false }) siteMap: ElementRef | undefined

    /** Form containing the data a new or current site */
    siteForm: FormGroup ;

    /** The map containing displaying the google map */
    // map: GoogleMap
    map: google.maps.Map | null;

    /** Returns the selected location type for a (new location) */
    get selectedLocationType(): LocationType {
        return this.siteForm?.get('siteType')?.value as LocationType
    }

    /** The text for the name input label */
    get nameInputText(): string {
        return this.selectedLocationType === 'group' ? 'Group Name' : "Location Name"
    }

    constructor(private formBuilder: FormBuilder) {
        this.map = null;
        this.siteMap = undefined;
        this.siteForm = this.formBuilder.group({
            siteType: ['group', [
                Validators.required
            ]],
            parentGroup: ['', [
                Validators.required
            ]],
            name: ['', [
                emptyValidator
            ]]
            // TODO: Implement physical location dependent validation and groups
        })
     }

    ngOnInit() {
        this.siteForm = this.formBuilder.group({
            siteType: ['group', [
                Validators.required
            ]],
            parentGroup: ['', [
                Validators.required
            ]],
            name: ['', [
                emptyValidator
            ]]
            // TODO: Implement physical location dependent validation and groups
        })
    }

    ngDoCheck() {

        // Check if the map needs to be drawn
        if (this.selectedLocationType === 'physical' && !this.map && this.siteMap) {
            this.initializeMap()
        } else if (this.selectedLocationType === 'group' && this.map) {
            this.map = null
        }
    }


    /** Initializes the [map] */
    private initializeMap() {
        // const mapProperties: GoogleMapOptions = {
        const mapProperties: google.maps.MapOptions = {
            center: new google.maps.LatLng(this.killianLat, this.killianLng),
            zoom: 11,
            // mapTypeId: MapTypeId.ROADMAP,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            scaleControl: true,
            gestureHandling: 'greedy'
        }
        // this.map = new GoogleMap(this.siteMap.nativeElement, mapProperties)
        this.map = new google.maps.Map(this.siteMap?.nativeElement, mapProperties)
    }
}
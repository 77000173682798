import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitCharacters'
})
export class LimitCharactersPipe implements PipeTransform {

  transform(value: string) {
      if (value?.length > 22) {
        return value.slice(0, 21) + '...';
      } else {
        return value;
      }
  }

}

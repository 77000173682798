<div *ngIf="data.content == 'clearEvent'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <hh-progress-bar id="loading-bar"></hh-progress-bar>
    <div mat-dialog-content>

        <div class="confirm-msg">
            <div>
                Are you sure you want to clear:
            </div>
            <div style="font-weight: bolder; margin-top: 15px;">
                {{ data.location.name }}
            </div>
            <div class="event-type et-dialog">
                <span style="font-weight: bolder; font-size: larger; text-transform: capitalize;"
                    class="ms-2 flex-grow-1 my-auto">{{
                    data.event.name }}</span>
                <img width="44px" [src]="'./assets/images/icons/' + data.event.icon">
            </div>
            <div>
                This action will end the event and send an "All Clear" to all users at the location
            </div>
            <br>
            <div>
                A code was sent to your phone, please enter it here:
                <form class="form-code" [formGroup]="myForm">
                    <mat-form-field appearance="fill">
                        <mat-label>Code</mat-label>
                        <input id="code" matInput placeholder="Enter the code here" formControlName="code" value=""
                            required style="text-transform:uppercase">
                        <mat-error *ngIf="getErrorMessage('code')">You must enter a value</mat-error>
                    </mat-form-field>
                </form>
            </div>
        </div>

    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons">
        <button mat-button (click)="dismiss('')">Cancel</button>
        <button mat-raised-button id="confirmButton" color="primary" [disabled]="!confirmButton"
            (click)="clearEvent()">{{ data.button
            }}</button>
    </div>
</div>

<div *ngIf="data.content == 'confirmEvent'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <hh-progress-bar id="loading-bar"></hh-progress-bar>
    <div mat-dialog-content>

        <div class="confirm-msg">
            <div>
                Are you sure you want to activate:
            </div>
            <div style="font-weight: bolder; margin-top: 15px;">
                {{ data.location.name  }}
            </div>
            <div class="event-type et-dialog">
                <span style="font-weight: bolder; font-size: larger; text-transform: capitalize;"
                    class="ms-2 flex-grow-1 my-auto">{{ data.event.name ? capitalize(data.event.name) : '' }}</span>
                <img width="44px" [src]="'./assets/images/icons/' + data.event.icon">
            </div>
            <div *ngIf="data.event">
                <ul *ngFor="let item of actions">
                    <li class="action__desc">{{ item.text }}</li>
                </ul>
            </div>
            <div>
                You or another admin will need to return to the Havrion Hub to cancel this event when it is all clear.
            </div>
        </div>

    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons">
        <button mat-button (click)="dismiss('')">Cancel</button>
        <button mat-raised-button id="confirmButton" color="primary" [disabled]="!confirmButton"
            (click)="confirm()">{{ data.button }}</button>
    </div> 
</div>
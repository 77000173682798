<div class="container__event">
    <hh-progress-bar *ngIf="loadingData"></hh-progress-bar>
    <div *ngIf="content != 'locationChart'" class="d-flex ev-donut-title">
        <div class="img-header">
            <img width="50" src="./assets/images/SVG/star.svg" alt="">
        </div>

        <div class="event__header">
            <p class="event__header-title">{{ organizationName ? organizationName : 'Organization Name' }}</p>
            <p class="event__header-sub">Event Breakdown</p>
            <p class="event__header-date">{{ dataShared.date }}</p>
        </div>
    </div>

    <div class="chart-box">
        <div *ngIf="!loadingData && eventCounts.length === 0" class="d-flex empty-state"></div>
        <div *ngIf="eventCounts.length > 0" class="event__chart-legends">
            <ul>
                <li class="event__chart-legend" *ngFor="let event of eventLabels; index as i">
                    <div class="event_chart-color" [style.background-color]="backgroundColor[i]"></div>
                    {{ capitalize(event) }} ({{ eventCounts[i] }} <span *ngIf="percentage">%</span>)
                </li>
            </ul>
        </div>
        <div *ngIf="eventCounts.length > 0" class="event__chart">
            <canvas
                baseChart
                [type]="'doughnut'"
                [datasets]="[{ data: eventCounts, backgroundColor: backgroundColor }]"
                [labels]="eventLabels"
                [options]="lineChartOptions"
                [legend]="false">
            </canvas>
            <div class="whole-total-events">
                <div>{{ totalEvents }}</div>
            </div>
        </div>

    </div>
    <div *ngIf="totalActMeth">
        <p  class="acth-meth-title">Activation Methods</p>
        <div class="d-flex">

            <div class="data-cell" *ngFor="let actMeth of totalActMeth">
                <img [src]="'./assets/images/SVG/dark_' + actMeth.icon" alt="">
                <div>{{ actMeth.count }} <span *ngIf="percentage">%</span></div>
            </div>

        </div>
    </div>

</div>

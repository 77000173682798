<div class="container-time">
  <hh-progress-bar *ngIf="loadingData"></hh-progress-bar>
  <div *ngIf="content != 'locationChart'" class="d-flex ev-linechart-title">
    <div class="img-header">
      <img width="50" src="./assets/images/SVG/star.svg">
    </div>

    <div class="event__header">
      <p class="event__header-title">{{ accountName ? accountName : 'Organization Name' }}</p>
      <p class="event__header-sub">Event Breakdown by Time</p>
      <p class="event__header-date">{{ dataShared.date }}</p>
    </div>
  </div>
  <div *ngIf="getLineChartData().length > 0" class="flex line-chart-events">
    <div class="flex-item">
      <div class="canvas-container" style="display: block;">
<!--          Missing [chartType]="lineChartType" and [colors]="lineChartColors"  due to build errors-->
        <canvas
          baseChart
          width="400"
          height="400"
          [type]="lineChartType"
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)">
        </canvas>
      </div>
    </div>
  </div>
  <div *ngIf="lineChartData?.length == 0 && !loadingData" class="d-flex empty-state"></div>
</div>

import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { Address, DataShareEv, DataShareMap, EventTypeByLocation } from "src/app/core/models/data-shared";
import { EventTypesLocation, mapEventTypestoModel } from "src/app/core/models/event-types-location";
import { DrillService } from "src/app/core/services/drills.service";
import { EventsService } from "src/app/core/services/events.service";
import { LocationService } from "src/app/core/services/location.service";
import { SelectedLocationsService } from "src/app/core/services/selected-locations.service";


interface EventHistoryData {
    id: string
    locationId: string
}


// interface DataShareAt {
//     content: string
//     attendancesRes?: any
//     eventTypes: EventTypeLocation[]
//     eventIdsOpen?: string
//     locationAddress?: Address[]
//     selectedIds?: string[]
//     eventHistory?: any
// }

@Component({
    selector: "eventsinfo",
    templateUrl: "eventsinfo.component.html",
    styleUrls: [
        "eventsinfo.component.scss"
    ]
})
export class EventsInfoComponent {

    @Input('content') content: string = '';
    @Input('eventHistory') eventHistory: EventHistoryData | null = null;

    // dataShareAt: DataShareAt;
    dataShareEv: DataShareEv = {};
    dataShareMap: DataShareMap = {};

    allLocationIds: string[] = [];
    allLocationAddress: Address[] = [];
    selectedLocationIds: string[] = [];
    eventTypesLocation: EventTypeByLocation[] = [];
    eventResponse: any;
    attendanceResponse: any;
    isFromMobile: boolean = false;

    private locationsSubscription: Subscription | undefined;
    private clickRefreshActivateDrill: Subscription;
    private clickMapLocation: Subscription;
    private clickRefreshHomeComponentsSubscription: Subscription;

    private clickRefreshHome: Subscription;

    constructor(private locationService: LocationService,
        private eventsService: EventsService,
        private selectedLocationsService: SelectedLocationsService,
        private drillService: DrillService,
        private _snackBar: MatSnackBar) {

        this.clickRefreshActivateDrill = this.drillService.getDrillsEvent().subscribe((drillId: string) => {
            if (drillId) {
                this.getEventTimelines(this.selectedLocationIds, this.selectedLocationIds[0], true);
            }
        })

        this.clickRefreshHome = this.eventsService.getRefreshHome().subscribe(() => {
            this.isFromMobile = true;
            setTimeout(() => {
                if (this.isFromMobile) {
                    if (this.selectedLocationIds.length > 0) {

                        this.getEventTimelines(this.selectedLocationIds, "", true, this.dataShareMap?.clickSubscription);
                    } else {
                        this.getEventTimelines(this.allLocationIds, "", true, this.dataShareMap?.clickSubscription);
                    }
                }
            }, 3000);

        })

        this.clickRefreshHomeComponentsSubscription = this.eventsService.getRefreshHomeComponents().subscribe((res: any) => {
            this.isFromMobile = false;
            let clickSubscription;
            if (res.updateData) {
                clickSubscription = { loc: res.locationId, updateData: res.updateData }
            }
            
            if (res.locationId) {
                this.getEventTimelines(this.selectedLocationIds, res.locationId, true, clickSubscription);
            } else {
                this.dataShareEv = {
                    ...this.dataShareEv,
                    eventIdsOpen: res.eventId
                }
                
                let eventRes = this.dataShareMap?.eventsRes;
                if(eventRes){
                    for (let i = 0; i < eventRes.length; i++) {
                        if (eventRes[i].id == res.eventId) {
                            this.dataShareMap = {
                                ...this.dataShareMap,
                                clickSubscription: { loc: eventRes[i].location_id, updateData: false }
                            }
                            break;
                        }

                    }
                }
            }

        })

        this.clickMapLocation = this.eventsService.getClickMapLocation().subscribe((res: any) => {
            // if (res.updateData) {
                this.dataShareMap = {
                    ...this.dataShareMap,
                    refresh: true,
                    clickSubscription: { loc: res.locationId, updateData: res.updateData }
                }
            // } else {
            //     this.dataShareMap = {
            //         ...this.dataShareMap,
            //         refresh: true,
            //         clickSubscription: { loc: res.locationId, updateData: false }
            //     }
            // }
        });
    }

    intervalSubscription: Subscription | undefined = undefined;



    ngOnInit() {
        if (this.content === 'home' || this.content === 'drill') {
            this.locationsSubscription = this.selectedLocationsService.selectedLocationsObservable.subscribe(selectedIds => {
                if (selectedIds.length > 0) {
                    this.selectedLocationIds = selectedIds;
                    if (selectedIds.length == 1) {
                        this.getEventTimelines(this.selectedLocationIds, selectedIds[0], true, { loc: selectedIds[0], updateData: true });
                    } else {
                        this.getEventTimelines(this.selectedLocationIds, "", true, undefined);
                    }

                } else if (this.allLocationIds.length > 0) {
                    this.getEventTimelines(this.allLocationIds, "", true);
                }
            })
        }

        if (this.content === 'eventHistory' || this.content === 'drillHistory') {
            if(this.eventHistory){
                this.selectedLocationIds = [this.eventHistory.locationId];
                this.getEventTimelines(this.selectedLocationIds, "", true);
            }
        } else {
            if (this.selectedLocationIds.length === 0) {
                this.getAllLoations();
            }
        }

        // if (this.content == 'home' || this.content == 'drill') {
        //     // Call functions each 30 second in order to update the attendance
        //     const source = interval(30000);
        //     this.intervalSubscription = source.subscribe(val => {
        //         this.getEventTimelines(this.selectedLocationIds, "", false);

        //     });
        // }

    }

    ngOnDestroy() {
        // this.locationsSubscription?.unsubscribe();
        this.clickRefreshActivateDrill?.unsubscribe();
        this.clickMapLocation?.unsubscribe();
        this.clickRefreshHome?.unsubscribe();
        this.clickRefreshHomeComponentsSubscription?.unsubscribe();
        this.intervalSubscription?.unsubscribe();
    }

    // getEventAttendances(locationIds: any, refresh: boolean) {
    //     let payload: any = {};
    //     if (this.content === 'home') {
    //         payload = {
    //             location_ids: locationIds,
    //             show: "active"
    //         }
    //     }

    //     if (this.content === 'drill') {
    //         payload = {
    //             location_ids: locationIds,
    //             show: "active-drill"
    //         }
    //     }

    //     if (this.content === 'drillHistory' || this.content === 'eventHistory') {
    //         payload = {
    //             location_id: this.eventHistory.locationId,
    //             event_instance_group_id: this.eventHistory.id
    //         }
    //         this.eventsService.getSingleEventAttendances(payload).subscribe((res: any) => {
    //             this.attendanceResponse = res;
    //         }, error => {
    //             this.openSnackBar(error.message, 'Ok');
    //         });
    //     } else {
    //         this.eventsService.getEventAttendances(payload).subscribe((res: any) => {
    //             this.attendanceResponse = res;
    //             if (res.length > 0) {
    //                 // if (refresh) {
    //                     this.dataShareAt = {
    //                         ...this.dataShareAt,
    //                         attendancesRes: this.attendanceResponse,
    //                         content: this.content,
    //                     }
    //                 // }
    //             } else {
    //                 this.dataShareAt = {
    //                     ...this.dataShareAt,
    //                     selectedIds: this.selectedLocationIds,
    //                     locationAddress: this.allLocationAddress,
    //                     attendancesRes: this.attendanceResponse,
    //                     content: this.content,
    //                 }
    //             }

    //         }, error => {
    //             this.openSnackBar(error.message, 'Ok');
    //         });
    //     }
    // }

    getAllLoations() {
        let locationIds: string[] = [];
        let locationAddress: Address[] = [];
        this.locationService.getLocations().subscribe({
            next: (res: any) => {
                if (res.length > 0) {
                    res.map((item: any) => {
                        locationIds.push(item.id);
                        locationAddress.push({
                            id: item.id,
                            address: item.address,
                            coors: new google.maps.LatLng(item.latitude, item.longitude),
                            locationName: item.name
                        });
                    })
                    this.allLocationIds = locationIds;
                    this.selectedLocationIds = locationIds;
                    this.allLocationAddress = locationAddress;
                    this.getEventTimelines(locationIds, "", true);
    
                } else {
                    this.dataShareEv = {
                        ...this.dataShareEv,
                        selectedIds: []
                    }
    
                    // this.dataShareAt = {
                    //     ...this.dataShareAt,
                    //     selectedIds: []
                    // }
                    this.dataShareMap = {
                        ...this.dataShareMap,
                        selectedIds: []
                    }
                }
    
            },
            error: error => {
                this.openSnackBar(error.message, 'Ok');
            }
        })
    }

    getEventTimelines(locationIds: any, newLocId: string, refresh: boolean, clickSubscription?: any) {
        let payload: any = {};
        if (this.content === 'home') {
            payload = {
                location_ids: locationIds,
                show: "active"
            }
        }

        if (this.content === 'drill') {
            payload = {
                location_ids: locationIds,
                show: "active-drill"
            }
        }

        if (this.content === 'drillHistory' || this.content === 'eventHistory') {
            payload = {
                location_id: this.eventHistory?.locationId,
                event_instance_group_id: this.eventHistory?.id
            }

            this.eventsService.getSingleEventTimelines(payload).subscribe((res: any) => {
                this.eventResponse = [res];
                this.getEventTypes([this.eventHistory?.locationId], newLocId);
            }, error => {
                this.openSnackBar(error.message, 'Ok');
            })

        } else {
            this.eventsService.getEventTimelines(payload).subscribe({
                next: res => {
                    let locIds: any[] = [];
    
                    // hide the footer if there are active events (maximize height for timeline / map)
                    if (res.length > 0) {
                        const footerObj = document.getElementsByClassName(
                            'hh-footer',
                        ) as HTMLCollectionOf<HTMLElement>;
                        const mainContObj = document.getElementsByClassName(
                            'main-container',
                        ) as HTMLCollectionOf<HTMLElement>;
                        const mapObj = document.getElementsByClassName(
                            'home__height',
                        ) as HTMLCollectionOf<HTMLElement>;
    
    
                        footerObj[0].style.display = 'none';
                        mainContObj[0].style.minHeight = '100vh';
                        if (mapObj[0]) {
                            mapObj[0].style.height = 'calc(100vh - 64px)';
                        }
    
                    }
    
                    this.eventResponse = res;
                    let eventIdsOpen: string = '';
                    if (res.length > 0) {
                        if (res.length == 1) {
                            clickSubscription = { loc: res[0].location_id, updateData: true }
                            eventIdsOpen: res[0].id
                        }
                        res.map((item: any) => {
                            if (this.content == 'drillHistory') {
                                if (!locIds.includes(item.location_id)) {
                                    locIds.push(item.location_id)
                                }
                            } else {
                                locIds.push(item.location_id);
                            }
    
                        })
                        // if (this.content != 'eventHistory' && this.content != 'drillHistory') {
                        //     this.selectedLocationIds = locIds;
                        // }
                        if (refresh) {
                            this.getEventTypes(locIds, newLocId, clickSubscription);
    
                        } else {
                            this.dataShareEv = {
                                ...this.dataShareEv,
                                eventsRes: this.eventResponse,
                                content: this.content,
                                eventIdsOpen: eventIdsOpen
                            }
                            this.dataShareMap = {
                                ...this.dataShareMap,
                                eventsRes: this.eventResponse,
                                content: this.content,
                                refresh: true,
                                clickSubscription: clickSubscription
                            }
    
                        }
                    } else {
                        this.dataShareEv = {
                            ...this.dataShareEv,
                            selectedIds: this.selectedLocationIds,
                            locationAddress: this.allLocationAddress,
                            eventsRes: this.eventResponse,
                            content: this.content,
                        }
                        this.dataShareMap = {
                            ...this.dataShareMap,
                            selectedIds: this.selectedLocationIds,
                            locationAddress: this.allLocationAddress,
                            eventsRes: this.eventResponse,
                            content: this.content,
                            refresh: refresh,
                            clickSubscription: clickSubscription
                        }
                    }
    
                },
                error: error => {
                    this.openSnackBar(error.message, 'Ok');
                }
            });
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }

    capitalize(str: string): string {
        const words = str.split(" ");

        for (let i = 0; i < words.length; i++) {
            const lower = words[i].toLowerCase()
            words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
        }

        return words.join(" ");
    }

    getEventTypes(locationIds: any, newLocId: string, clickSubscription?: any) {

        this.eventTypesLocation = [];
        let eventTypes: EventTypesLocation[] = [];
        let payload = {
            location_ids: locationIds
        }
        this.eventsService.getEventTypes(payload).subscribe((res: any) => {

            res.map((item: any) => {
                eventTypes = [];
                item.event_types.map((ev: any) => {
                    let event = mapEventTypestoModel(ev);
                    eventTypes.push(event);
                })
                this.eventTypesLocation.push({
                    location_id: item.location_id,
                    eventTypes: eventTypes
                })
            })

            let evntHist: any = undefined;
            if (this.eventHistory) {
                evntHist = this.eventHistory;
            }
            // let locRendered = this.dataShareMap?.selectedIds.find(loc => loc == this.selectedLocationIds[0]);
            // if (!locRendered || !this.dataShareMap || (locRendered && this.dataShareMap?.selectedIds.length > 1)) {
            this.dataShareMap = {
                ...this.dataShareMap,
                eventTypes: this.eventTypesLocation,
                selectedIds: this.selectedLocationIds,
                locationAddress: this.allLocationAddress,
                eventsRes: this.eventResponse,
                content: this.content,
                eventHistory: evntHist,
                refresh: true,
                clickSubscription: clickSubscription
            }
            // }


            if (newLocId) {
                this.eventResponse.map((event: any) => {
                    if (newLocId === event.location_id) {
                        this.dataShareEv = {
                            ...this.dataShareEv,
                            eventTypes: this.eventTypesLocation,
                            selectedIds: this.selectedLocationIds,
                            locationAddress: this.allLocationAddress,
                            eventsRes: this.eventResponse,
                            content: this.content,
                            eventHistory: evntHist,
                            eventIdsOpen: event.id
                        }
                    }
                })
            } else {
                this.dataShareEv = {
                    ...this.dataShareEv,
                    eventTypes: this.eventTypesLocation,
                    selectedIds: this.selectedLocationIds,
                    locationAddress: this.allLocationAddress,
                    eventsRes: this.eventResponse,
                    content: this.content,
                    eventHistory: evntHist
                }
            }
        }, error => {
            this.openSnackBar(error.message, 'Ok');
        });
    }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hub-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  thermalProtect = 'https://thermal-protect-prd.havrion.com/login';
  contact = 'mailto:info@havrion.com';
  
  constructor() { }

  ngOnInit(): void {
  }

}

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OneTimeService {

  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) { }

  getRoles() {

    let api = `${this.endpoint}/roles`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getActivationMethods() {
    let api = `${this.endpoint}/activation-methods`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error.message
      };
    }
    return throwError(msg);
  }
}

import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ActivationMethod } from 'src/app/core/models/activation-method';
import {
  EventTypesLocation,
  mapEventTypestoModel,
} from 'src/app/core/models/event-types-location';
import { DrillService } from 'src/app/core/services/drills.service';
import { EventsService } from 'src/app/core/services/events.service';
import { OneTimeService } from 'src/app/core/services/onetime.service';

export interface DialogData {
  title: string;
  button: string;
  content: string;
  id?: string;
  locationId: string;
  eventType?: any;
}

interface Activation {
  icon: string;
  value: string;
  viewValue: string;
}

interface Events {
  icon: string;
  color: string;
  value: string;
  viewValue: string;
  background: string;
  code: string;
}

interface Times {
  value: string;
  viewValue: string;
}

interface EventTypeLocation {
  location_id: string;
  eventTypes: EventTypesLocation[];
}

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  activations: Activation[] = [];
  events: any[] = [];
  eventTypes: any[] = [];
  shelterEvents = [];

  createButton = false;
  updateButton = false;
  activateDrillButton = true;
  deactivateDrillButton = true;
  sendButton = true;
  deleteDrillButton = true;

  drillHistory: any;

  /** Reference to the locations subcription, used to unsubscribe */
  private locationsSubscription: Subscription | undefined;
  nestedEvents: EventTypesLocation[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private eventsService: EventsService,
    private drillsService: DrillService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private oneTimeService: OneTimeService
  ) {
    this.myForm = this.fb.group({
      selectedDate: [, [Validators.required]],
      selectedTime: [, [Validators.required]],
      selectedAct: [, [Validators.required]],
      selectedEvt: [, [Validators.required]],
    });
  }

  onSelectionChange(event: any) {
   let eventSelected = this.events.find((ev: any) => ev.id === event);
   if(eventSelected?.event_types.length > 0){
    this.myForm?.get('selectedEvt')?.setValue('');
    document.getElementById('nestedEventsButton').click(); 
   }
  }

  openDialog(elem: string, id: string, parentDialog: any): void {
    if (elem == 'deleteDrill') {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '800px',
        data: {
          title: 'CONFIRM',
          content: 'deleteDrill',
          button: 'Delete',
          id: id,
          locationId: '',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed', result);
        if (result === 'deleteDrill') {
          parentDialog.close({ msg: 'deleteDrill', id: this.data.id });
        }
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  openDeleteDialog(): void {
    this.openDialog('deleteDrill', this.data.id ?? '', this.dialogRef);
  }

  // Validator form

  myForm: FormGroup;

  getErrorMessage(campo: string) {
    this.enableButton();
    return (
      this.myForm?.controls[campo].errors && this.myForm.controls[campo].touched
    );
  }

  enableButton() {
    if (this.myForm?.valid) {
      if (this.data.content === 'create') {
        this.createButton = true;
      }
      if (this.data.content === 'edit') {
        this.updateButton = true;
      }
    } else {
      if (this.data.content === 'create') {
        this.createButton = false;
      }
      if (this.data.content === 'edit') {
        this.updateButton = false;
      }
    }
  }

  send(drillId?: string) {
    if (this.myForm?.invalid) {
      this.myForm.markAllAsTouched();
    } else {
      if (this.data.content === 'create') {
        let loadBar = document.getElementById('loading-bar');
        if (loadBar) loadBar.style.display = 'block';
        let cmfBtn = <HTMLButtonElement>document.getElementById('createBtn');
        cmfBtn.disabled = true;
        this.createDrill();
      }
      if (this.data.content === 'edit') {
        let loadBar = document.getElementById('loading-bar');

        if (loadBar) loadBar.style.display = 'block';
        let cmfBtn = <HTMLButtonElement>document.getElementById('editBtn');
        cmfBtn.disabled = true;
        this.updateDrill(drillId ?? '');
      }
    }
  }

  closeDialog(msg: string): void {
    this.dialogRef.close(msg);
  }

  setEventTypes() {
    this.eventTypes.map((ev: any) => {
      this.events.push(ev);
    });
  }

  setNestedEvents(events: any) {
    this.nestedEvents = events;
  }

  getSelectedActivationMethod(activationMethodId: string) {
    let selectedActivatioMethod = 'not found';
    if (sessionStorage.getItem('activationMethods')) {
      let strObj = sessionStorage.getItem('activationMethods');
      let obj = JSON.parse(strObj ?? '');
      for (let i = 0; i < obj.length; i++) {
        if (activationMethodId === obj[i].id) {
          selectedActivatioMethod = obj[i].description;
        }
      }
    }
    return selectedActivatioMethod;
  }

  setActivationMethods() {
    if (sessionStorage.getItem('activationMethods')) {
      let strObj = sessionStorage.getItem('activationMethods');
      let obj = JSON.parse(strObj ?? '');
      for (let i = 0; i < obj.length; i++) {
        this.activations.push({
          icon: '',
          value: obj[i].id,
          viewValue: obj[i].description,
        });
      }
    } else {
      let activationMethods: ActivationMethod[] = [];
      this.oneTimeService.getActivationMethods().subscribe(
        (res: any) => {
          res.map((item: any) => {
            activationMethods.push({
              id: item.id,
              description: item.description,
              backgroundColor: item.background_color,
              icon: item.icon,
            });
          });
          sessionStorage.setItem(
            'activationMethods',
            JSON.stringify(activationMethods)
          );
          this.setActivationMethods();
        },
        (error) => {
          this.openSnackBar(error.message, 'Ok');
        }
      );
    }
  }

  setDrill(res: any) {
    if (res.event_type_location_id) {
      this.eventTypes.map((ev: any) => {
        ev.event_types.map((nev: any) => {
          if (nev.id === res.event_type_location_id) {
            this.nestedEvents.push(nev);
          }
        });
      });
    }
    let date = res.start_dte.split(' ', 2)[0];
    let hour = res.start_dte.split(' ', 2)[1].split(':', 2);
    let history = false;
    if (res.is_active == 0) {
      history = true;
    }

    let fulldate = date.split('-', 3);

    this.myForm
      ?.get('selectedDate')
      ?.setValue(new Date(`${fulldate[1]}/${fulldate[2]}/${fulldate[0]}`));
    this.myForm?.get('selectedAct')?.setValue(res.activation_method_id);
    this.myForm?.get('selectedTime')?.setValue(`${hour[0]}:${hour[1]}`);
    this.myForm?.get('selectedEvt')?.setValue(res.event_type_location_id);
  }

  activateDrill(drillId: string, locationId: string) {
    let loadBar = document.getElementById('loading-bar');
    if (loadBar) loadBar.style.display = 'block';
    let cmfBtn = <HTMLButtonElement>document.getElementById('activateDrillBtn');
    cmfBtn.disabled = true;
    this.drillsService.activateDrill(locationId, drillId).subscribe(
      (res: any) => {
        this.openSnackBar(`${res.message}`, 'OK');
        this.closeDialog('activateDrill');
        this.drillsService.callDrillsEvent(drillId);
        this.drillsService.sendChangeTab();
      },
      (error) => {
        this.openSnackBar(error.message, 'Ok');
        if (loadBar) loadBar.style.display = 'none';
        cmfBtn.disabled = false;
      }
    );
  }

  deactivateDrill(drillId: string, locationId: string) {
    let loadBar = document.getElementById('loading-bar');
    if (loadBar) loadBar.style.display = 'block';
    let cmfBtn = <HTMLButtonElement>(
      document.getElementById('deactivateDrillBtn')
    );
    cmfBtn.disabled = true;
    this.drillsService.deactivateDrill(locationId, drillId).subscribe(
      (res: any) => {
        this.openSnackBar(`${res.message}`, 'OK');
        this.closeDialog('deactivateDrill');
        this.drillsService.callDrillsEvent(drillId);
      },
      (error) => {
        this.openSnackBar(error.message, 'Ok');
        if (loadBar) loadBar.style.display = 'none';
        cmfBtn.disabled = false;
      }
    );
  }

  updateDrill(drillId: string) {
    let date = <HTMLInputElement>document.getElementById('date');
    let dateSplit = date.value.split('/', 3);
    let time = <HTMLInputElement>document.getElementById('time');

    let payload = {
      event_type_location_id: this.myForm?.controls['selectedEvt'].value,
      start_dte: `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]} ${time.value}:00.000000`,
      activation_method_id: this.myForm?.controls['selectedAct'].value,
    };

    this.drillsService
      .updateDrill(this.data.locationId, drillId, payload)
      .subscribe(
        (res: any) => {
          this.closeDialog('edit');
        },
        (error) => {
          this.openSnackBar(error.message, 'Ok');
        }
      );
  }

  getDrill(selectedId: string) {
    this.drillsService.getDrill(selectedId, this.data.id ?? '').subscribe({
      next: (res: any) => {
        this.setDrill(res);
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      },
    });
  }

  createDrill() {
    let date = <HTMLInputElement>document.getElementById('date');
    let dateSplit = date.value.split('/', 3);
    let time = <HTMLInputElement>document.getElementById('time');
    let payload = {
      event_type_location_id: this.myForm?.controls['selectedEvt'].value,
      start_dte: `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]} ${time.value}`,
      activation_method_id: this.myForm?.controls['selectedAct'].value,
    };
    this.drillsService.createDrill(this.data.locationId, payload).subscribe({
      next: (res: any) => {
        this.closeDialog('create');
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      },
    });
  }

  deleteDrill() {
    let loadBar = document.getElementById('loading-bar');
    if (loadBar) loadBar.style.display = 'block';
    let cmfBtn = <HTMLButtonElement>(
      document.getElementById('deleteDrillParentBtn')
    );
    cmfBtn.disabled = true;
    this.closeDialog('deleteDrill');
  }

  capitalize(str: string): string {
    const words = str.split(' ');

    for (let i = 0; i < words.length; i++) {
      const lower = words[i].toLowerCase();
      words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1);
    }

    return words.join(' ');
  }

  getEventTypes(locationId: string[]) {
    let payload = {
      location_ids: locationId,
    };
    this.eventsService.getEventTypes(payload).subscribe(
      (res: any) => {
        res.map((item: any) => {
          item.event_types.map((ev: any) => {
            let event: EventTypesLocation = mapEventTypestoModel(ev);
            this.eventTypes.push(event);
          });
        });
        this.setEventTypes();
        if (this.data.content === 'edit') {
          this.getDrill(this.data.locationId);
        }
      },
      (error) => {
        this.openSnackBar(error.message, 'Ok');
      }
    );
  }

  currentTime() {
    let date = new Date();
    let currentTime = `${date.getHours()}:${date.getMinutes()}`;

    return currentTime;
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      selectedDate: [, [Validators.required]],
      selectedTime: [, [Validators.required]],
      selectedAct: [, [Validators.required]],
      selectedEvt: [, [Validators.required]],
    });

    this.setActivationMethods();

    if (this.data.content === 'edit' || this.data.content === 'create') {
      this.getEventTypes([this.data.locationId]);
    }

    if (this.data.content === 'drillview') {
      this.drillHistory = {
        id: this.data.id,
        locationId: this.data.locationId,
      };
    }
  }

  ngOnDestroy() {
    this.locationsSubscription?.unsubscribe();
  }
}

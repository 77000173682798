import { AbstractControl } from "@angular/forms"
import { FlatLocation, GroupData } from "../models/flat-location"
import { LocationGroup } from "../models/location-group"

/** Returns an array of flat locations from the given location [groups]... Note this locations use the default sorting, which is done by default path */
export const generateFlatLocations = (groups: LocationGroup[]): FlatLocation[] => {
    const groupedFlatLocations = groups.map((nestedLocationGroup: any) => extractLocations(nestedLocationGroup))
    return groupedFlatLocations.reduce((accumulator: any, value: any) => accumulator.concat(value, [])).sort((location1, location2) => {
        return location1.fullPath.localeCompare(location2.fullPath)
    })
}


/**
 * Extracts the locations for the given [locationGroup]
 * Opctional: pass the [parentGroups], if the [locationGroup] belongs to another group
 */
export const extractLocations = (locationGroup: LocationGroup, parentGroups: GroupData[] = []): FlatLocation[] => {
    const currentGroups = parentGroups.concat({ name: locationGroup.name, id: locationGroup.id })
    let locations = locationGroup.locations
    if(locations){
        const flatLocations = locations.map((location: any) => new FlatLocation(currentGroups, location))
        if (locationGroup.groups) {
            const groupedFlatLocations = locationGroup.groups.map((nestedLocationGroup: any) => extractLocations(nestedLocationGroup, currentGroups))
            const flattenedGroupedLocations = groupedFlatLocations.reduce((accumulator, value) => accumulator.concat(value, []), [])
            return flatLocations?.concat(...flattenedGroupedLocations)
        }
    }

    return []
}

/** Validates the control value contains non-whitespace content */
export const emptyValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
    const controlValue = (control.value as string) || ''
    if (controlValue.trim() === '') {
        return {
            empty: true
        }
    }
    return null
}

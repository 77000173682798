import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/core/services/events.service';
import { SelectedLocationsService } from 'src/app/core/services/selected-locations.service';

@Component({
    selector: 'dash',
    templateUrl: 'dash.component.html',
    styleUrls: ['dash.component.scss']
})
export class DashComponent implements OnInit, OnDestroy {

    // private locationsSubscription: Subscription;
    private clickTotalEventSubscription: Subscription;
    
    isChecked = false;
    isCheckedSort = false;

    dataShare: any;
    data: any;

    loadingData: boolean = false;

    constructor(private eventService: EventsService,
        private selectedLocationsService: SelectedLocationsService,
        private _snackBar: MatSnackBar) {
        this.clickTotalEventSubscription = this.eventService.getClickTotalEvents().subscribe((data: any) => {
            // console.log("data subscription", data);
            if (data) {
                if (data.startDate && data.endDate && data.endDate.length > 0) {
                    this.dataShare = { ...this.dataShare, loadingData: true }
                    this.data = data;
                    this.getInfo(data);
                }
            } else {
                this.dataShare = {
                    ...this.dataShare,
                    // eventTypeDashRes: undefined,
                    payload: undefined,
                    actMethRes: undefined,
                    data: undefined,
                    loadingData: false
                }
            }



        })
    }

    ngOnInit(): void {
        this.dataShare = { loadingData: true }
        // console.log("se llama aqui 1")
        this.getAllEventTypes()

    }

    getToggleCheck(toggle: any) {
        // console.log("toggle", toggle);
        this.isChecked = toggle;
    }

    getToggleCheckSort(toggle: any) {
        // console.log("toggle", toggle);
        this.isCheckedSort = toggle;
    }

    getInfo(data: any) {
        // console.log("get info data", data);
        let evTypIds: string[] = [];
        data.events.map((ev: any) => {
            evTypIds.push(ev.eventTypeId);
        })
        // let startDate = data.startDate.split("/", 3);
        // let endDate = data.endDate.split("/", 3);
        // console.log("get info event type", evTypIds);
        let payload = {
            event_types_ids: evTypIds,
            start_date: data.startDate,
            end_date: data.endDate
            // start_date: `${startDate[2]}-${startDate[0]}-${startDate[1]}`,
            // end_date: `${endDate[2]}-${endDate[0]}-${endDate[1]}`
        }

        this.dataShare = {
            ...this.dataShare,
            range: data.range,
            payload: payload,
            // date: `${startDate[1]}/${startDate[0]}/${startDate[2]} - ${endDate[1]}/${endDate[0]}/${endDate[2]}`
            date: `${data.startDate} - ${data.endDate}`
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 7000,
        });
    }

    getAllEventTypes() {
        // console.log("call event types")
        let payload = {
            location_ids: []
        }
        this.eventService.getEventTypesUniques(payload).subscribe((eventTypesUniques: any) => {
            // console.log("unique call", eventTypesUniques);
            let evIds: any = [];

            eventTypesUniques.map((item: any) => {
                evIds.push(item.event_type_id);
            })

            let lastMonth = new Date();
            lastMonth.setMonth(lastMonth.getMonth() - 2);
            lastMonth.setDate(1);
            let current = new Date();
            let payload2 = {
                event_types_ids: evIds,
                start_date: `${lastMonth.getFullYear()}-${lastMonth.getMonth() + 1}-${lastMonth.getDate()}`,
                end_date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
            }

            this.dataShare = {
                content: 'dashboard',
                eventTypesUniques: eventTypesUniques,
                range: 'month',
                payload: payload2,
                date: `${lastMonth.getDate()}/${lastMonth.getMonth() + 1}/${lastMonth.getFullYear()} - ${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
            }

        }, error => {
            this.openSnackBar(error.message, 'Ok');
        })
    }

    ngOnDestroy() {
        // this.locationsSubscription?.unsubscribe();
        this.clickTotalEventSubscription?.unsubscribe();
    }

}
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private subject = new Subject<any>();
  private subjectUsers = new Subject<any>();
  private subjectCloseDialog = new Subject<any>();
  private subjectCloseBulkDialog = new Subject<any>();
  private subjectCloseBulkErrorDialog = new Subject<any>();
  private subjectEnableButton = new Subject<any>();
  private subjectDisableButton = new Subject<any>();
  private subjectEnableBulkButton = new Subject<any>();
  private subjectDisableBulkButton = new Subject<any>();
  private subjectLogout = new Subject<any>();

  sendLogout(){
    this.subjectLogout.next('');
  }

  getLogout(): Observable<any>{ 
    return this.subjectLogout.asObservable();
  }

  sendClickEvent() {
    this.subject.next('');
  }
  sendClickEventUsers() {
    this.subjectUsers.next('');
  }

  sendClickCloseDialog() {
    this.subjectCloseDialog.next('');
  }
  sendClickCloseBulkDialog() {
    this.subjectCloseBulkDialog.next('');
  }

  sendClickCloseBulkErrorDialog() {
    this.subjectCloseBulkErrorDialog.next('');
  }

  sendEnableButton(){
    this.subjectEnableButton.next('');
  }

  sendDisableBulkButton(){
    this.subjectDisableBulkButton.next('');
  }

  sendEnableBulkButton(){
    this.subjectEnableBulkButton.next('');
  }

  sendDisableButton(){
    this.subjectDisableButton.next('');
  }

  getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }

  getClickCloseDialog(): Observable<any>{ 
    return this.subjectCloseDialog.asObservable();
  }
  getClickCloseBulkDialog(): Observable<any>{ 
    return this.subjectCloseBulkDialog.asObservable();
  }

  getClickCloseBulkErrorDialog(): Observable<any>{ 
    return this.subjectCloseBulkErrorDialog.asObservable();
  }

  getClickEventUsers(): Observable<any>{ 
    return this.subjectUsers.asObservable();
  }

  getEnableButton(): Observable<any>{ 
    return this.subjectEnableButton.asObservable();
  }
  getDisableButton(): Observable<any>{ 
    return this.subjectDisableButton.asObservable();
  }

  getEnableBulkButton(): Observable<any>{ 
    return this.subjectEnableBulkButton.asObservable();
  }
  getDisableBulkButton(): Observable<any>{ 
    return this.subjectDisableBulkButton.asObservable();
  }

  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) { }

  // getUsers() {

  //   let api = `${this.endpoint}/users`;
  //   return this.http
  //     .get(api, { headers: this.headers, responseType: "json" },)
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  getUsersByLocation(locationId: string) {
    let api = `${this.endpoint}/location/${locationId}/users`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getUsersByLocationGroup(locationGroupId: string) {
    let api = `${this.endpoint}/location-group/${locationGroupId}/users-group`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getUser(userId: string) {

    let api = `${this.endpoint}/user/${userId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getUserEmergencyContact(locationId: string, userId: string) {

    let api = `${this.endpoint}/location/${locationId}/user/${userId}/emergency-contact`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateUser(locationId: string, userId: string, payload: object) {
    let api = `${this.endpoint}/location/${locationId}/user/${userId}`;
    // let api = `${this.endpoint}/user/${userId}`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  createUser(payload: object) {
    let api = `${this.endpoint}/users`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  deleteUser(userId: string){
    let api = `${this.endpoint}/user/${userId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getUserRoleLocation(locationId: string){
    let api = `${this.endpoint}/location/${locationId}/user-role-locations`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getUserRoleLocationGroup(locationGroupId: string){
    let api = `${this.endpoint}/location-group/${locationGroupId}/user-role-location-groups`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  // setUserRole(locationId: string, payload: Object){
  //   let api = `${this.endpoint}/location/${locationId}/user-role-locations`;
  //   return this.http
  //     .post(api, payload, { headers: this.headers, responseType: "json" },)
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  // updateUserRole(locationId: string, payload: Object, userRoleId: string){
  //   let api = `${this.endpoint}/location/${locationId}/user-role-location/${userRoleId}`;
  //   return this.http
  //     .put(api, payload, { headers: this.headers, responseType: "json" },)
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  deleteUserRole(locationId: string, userRoleId: string){
    let api = `${this.endpoint}/location/${locationId}/user-role-location/${userRoleId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getUserProfile(){
    let api = `${this.endpoint}/user-profile`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateAccount(payload: Object){
    let api = `${this.endpoint}/user-account`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  sendMobileInvitation(payload: Object){
    let api = `${this.endpoint}/user-invite-mobile`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  userValidation(email: string) {
    let api = `${this.endpoint}/user/email/${email}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error.message
      };
    }
    return throwError(msg);
  }
}

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry, map } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildingsService {

  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) { }

  getBuildings(locationid: string){
    let api = `${this.endpoint}/location/${locationid}/buildings`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  // getBuilding(locationId: string, buildingId: string){
  //   let api = `${this.endpoint}/location/${locationId}/building/${buildingId}`;
  //   return this.http
  //     .get(api, { headers: this.headers, responseType: "json" })
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  editBuilding(locationId: string, buildingId: string, edit: any) {
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}`;
    return this.http
      .put(api, edit, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  createBuilding(building: any) {
    let api = `${this.endpoint}/location/${building.location_id}/buildings`;
    return this.http
      .post(api, building, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  

  deleteBuilding(locationId: string, buildingId: string) {
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }
  

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error.message
      };
    }
    return throwError(msg);
  }
}

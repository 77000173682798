import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedSkeletonComponent } from './shared/components/logged-skeleton/logged-skeleton.component';
// import { AuthGuard } from './core/guards/auth.guard';
import { TermsGuard } from './core/guards/terms.guard';
// import { ProfileModule } from './feature/profile/profile.module';
import { MsalGuard } from '@azure/msal-angular';
import { Oidc2Component } from './core/components/oidc2/oidc2.component';


const routes: Routes = [
  {
    path: "secured",
    component: LoggedSkeletonComponent,
    children: [
      {
        path: "home",
        pathMatch: 'full',
        loadChildren: () => import('./feature/home/home.module').then(m => m.HomeModule),
        canActivate: [MsalGuard]
      },
      {
        path: "drill",
        loadChildren: () => import('./feature/drill/drill.module').then(m => m.DrillModule),
        canActivate: [MsalGuard]
      },
      {
        path: "dash",
        loadChildren: () => import('./feature/dash/dash.module').then(m => m.DashModule),
        canActivate: [MsalGuard]
      },
      {
        path: "settings",
        loadChildren: () => import('./feature/solutions-management/solutions-management.module').then(module => module.SolutionsManagementModule),
        canActivate: [MsalGuard]
      },
      {
        path: "events-history",
        loadChildren: () => import('./feature/event-history/event-history.module').then(module => module.EventHistoryModule),
        canActivate: [MsalGuard]
      },
      {
        path: "profile",
        loadChildren: () => import('./feature/profile/profile.module').then(module => module.ProfileModule),
        canActivate: [MsalGuard]
      },
      {
        path: "**",
        redirectTo: "/404"
      }
    ]
  },
  {
    path: "login",
    loadChildren: () => import('./core/components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: "about",
    loadChildren: () => import('./core/components/about/about.module').then(m => m.AboutModule)
  },
  {
    path: "404",
    loadChildren: () => import('./core/components/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: "terms-of-use",
    loadChildren: () => import('./core/components/terms/terms.module').then(m => m.TermsModule),
    canActivate: [TermsGuard]
  },
  {
    path: "terms-of-use-view",
    loadChildren: () => import('./core/components/terms/terms-view.module').then(m => m.TermsViewModule)
    // canActivate: [TermsGuard]
  },
  {
    path: "privacy-policy-view",
    loadChildren: () => import('./core/components/privacy-policy/privacy-policy-view.module').then(m => m.PrivacyViewModule)
    // canActivate: [TermsGuard]
  },
  {
    path: "privacy-policy-mobile",
    loadChildren: () => import('./core/components/privacy-policy-mobile/privacy-policy-mobile.module').then(m => m.PrivacyMobileModule)
    // canActivate: [TermsGuard]
  },
  {
    path: "oidc",
    pathMatch: 'full',
    component: Oidc2Component,
    // loadChildren: () => import('./core/components/oidc/oidc.module').then(m => m.OidcModule),
    canActivate: [MsalGuard]
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/login" // TODO: change redirect
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<h1 mat-dialog-title>
  {{ data.title }} 
</h1>
<!-- <hh-progress-bar id="loading-bar"></hh-progress-bar> -->
<div mat-dialog-content>

  <!-- Content for "create a drill" -->

  <div *ngIf="data.content=='view-display'" class="container-blp-dialog">
    <div [ngStyle]="{ 'backgroundColor': data.activation.generalEventType.backgroundColor }"
      class="p-4 d-flex area-loc-event-info">
      <img width="50px" src="./assets/images/icons/icon_havrion_connect.svg">
      <span class="ms-3 blp-loc-area-info">
        <p class="location-name">{{ data.activation.dialogData.locationName }}</p>
        <p class="area-name">Event: <strong>{{ data.activation.description.eventType }}</strong></p>
      </span>
      <span class="d-flex blp-event-active">
        <img width="44px" [src]="'./assets/images/icons/' + data.activation.generalEventType.icon">
        <span style="font-weight: 500; line-height: 1.3; font-size: 16px;" class="ms-2 flex-grow-1 my-auto">{{
          data.activation.generalEventType.name }} <br>
          <span class="blp-act-met"><strong>via:</strong> {{ data.activation.generalActMethod.description }}</span>
        </span>
      </span>
    </div>

    <div class="blp-list-container-image">
      <div class="blp-image" >
        Current Image:
        <img *ngIf="!isImageLoading" id="image-tag" [src]="imageToShow">
      </div>
      <hh-progress-bar *ngIf="isImageLoading"></hh-progress-bar>
      <!-- <div class="blp-list">
        <p>
          <span class="head-list">Select Blueprint</span>
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" vertical>
            <mat-button-toggle *ngFor="let blp of data.blueprints" [value]="blp.id" (click)="getBlueprintImg(blp.id)" [checked]="blp.id == data.blueprint.id">
              {{ blp.name }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </p>
      </div> -->
    </div>
    
  </div>



</div>


<div mat-dialog-actions align="end" class="dialog-buttons">
  <button mat-button (click)="closeDialog('')">Close</button>
</div>
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartConfiguration, ChartData } from 'chart.js';
import { ActivationMethod } from 'src/app/core/models/activation-method';
import { EventsService } from 'src/app/core/services/events.service';
import { OneTimeService } from 'src/app/core/services/onetime.service';

@Component({
  selector: 'event-breakdown',
  templateUrl: './event-breakdown.component.html',
  styleUrls: ['./event-breakdown.component.scss']
})
export class EventBreakdownComponent {

  @Input('content') content: string = "";

  dataShared: any = undefined;
  totalActMethAux: any = undefined;
  totalActMethPercentage: any = undefined;
  @Input('dataShared') set dataSharedSet(value: any) {
    this.dataShared = value;

    this.loadingData = true;
    this.totalActMeth = [];
    this.totalEvents = 0;
    this.eventLabels = [];
    this.eventCounts = [];
    this.backgroundColor = [];
    if (this.dataShared?.payload) {

      this.eventTypes = this.dataShared.eventTypesUniques;
      this.setChart();
    } else {
      this.eventCounts = [];
      this.loadingData = false;
    }
  }

  get dataSharedSet(): any {
    return this.dataShared;
  }

  locationData: any = undefined;
  @Input('locationData') set locationDataSet(value: any) {
    this.loadingData = true;
    this.locationData = value;
    this.totalActMeth = [];
    this.totalEvents = 0;
    this.eventLabels = [];
    this.eventCounts = [];
    this.backgroundColor = [];
    this.infoAux = {};

    this.eventTypes = this.locationData.eventTypes;
    let actMeth: any[] = []
    this.locationData.activation_methods.map((act: any) => {
      actMeth.push({
        count: act.count,
        id: act.activation_method_id
      })
    })
    setTimeout(() => {
      this.event_brreakdown = this.locationData.events_breakdown
      this.setEvents(this.event_brreakdown);
      this.setActMethTotals(actMeth);
    }, 500)
  }

  get locationDataSet(): any {
    return this.locationData;
  }

  backgroundColor: string[] = [];
  eventLabels: string[] = [];
  eventCounts: number[] = [];
  eventCountsPercentage: any = undefined;
  eventCountsAux: any = undefined;

  data: any = {};
  eventTypes: any = undefined;
  loadingData: boolean = true;

  totalEvents: number = 0;
  totalActMeth: any = undefined;
  percentage: boolean = false;

  organizationName: string = "";
  activationMethods: any = undefined;
  infoAux: any = {};

  event_brreakdown: any = [];
  lineChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    maintainAspectRatio: false
  }

  @Input('percentage') set percentageSet(value: any) {
    this.percentage = value;
    if (this.eventCounts && this.totalActMeth.length > 0) {
      this.changetoPercentage();
    }
  }

  get percentageSet(): any {
    return this.percentage;
  }

  sorting: boolean = false;

  @Input('sorting') set sortingSet(value: any) {
    this.sorting = value;
    this.sortingCheck()
  }

  get sortingSet(): any {
    return this.sorting;
  }

  constructor(private eventService: EventsService,
    private _snackBar: MatSnackBar,
    private oneTimeService: OneTimeService) {

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  sortingCheck() {
    if (this.sorting) {
      this.eventCounts = [];
      this.eventLabels = [];
      this.backgroundColor = [];
      let evBrekWithName: any[] = [];
      this.event_brreakdown?.map((item: any) => {
        this.eventTypes.map((ev: any) => {
          if (ev.event_type_id === item.event_type_id) {
            evBrekWithName.push({
              id: ev.event_type_id,
              name: ev.name,
              color: ev.color,
              count: item.count
            });
          }
        })
      });

      evBrekWithName.sort(function (a: any, b: any) {
        if (a.name < b.name) {
          return -1;
        }
        if (b.name < a.name) {
          return 1;
        }
        return 0;
      });
      evBrekWithName.map((item: any) => {
        this.eventCounts.push(item.count);
        this.eventLabels.push(item.name);
        this.backgroundColor.push(item.color);
      });

    } else {
      if (this.infoAux) {
        this.eventCounts = [];
        this.eventLabels = [];
        this.backgroundColor = [];
        let evBrekWithName: any[] = [];
        this.event_brreakdown?.map((item: any) => {
          this.eventTypes.map((ev: any) => {
            if (ev.event_type_id === item.event_type_id) {
              evBrekWithName.push({
                id: ev.event_type_id,
                name: ev.name,
                color: ev.color,
                count: item.count
              });
            }
          })
        });
        evBrekWithName.sort( (a: any, b: any) => b.count - a.count );
        evBrekWithName.map((item: any) => {
          this.eventCounts.push(item.count);
          this.eventLabels.push(item.name);
          this.backgroundColor.push(item.color);
        });
      }
    }
  }

  getActivationMethods() {
    this.activationMethods = [];
    if (sessionStorage.getItem('activationMethods')) {
      let strObj = sessionStorage.getItem('activationMethods') || "";
      let obj = JSON.parse(strObj);
      for (let i = 0; i < obj.length; i++) {
        this.activationMethods.push(obj[i])
      }
    } else {
      let activationMethods: ActivationMethod[] = [];
      this.oneTimeService.getActivationMethods().subscribe((res: any) => {
        res.map((item: any) => {
          activationMethods.push({
            id: item.id,
            description: item.description,
            backgroundColor: item.background_color,
            icon: item.icon
          })
        })
        sessionStorage.setItem('activationMethods', JSON.stringify(activationMethods));
        this.getActivationMethods();
      }, error => {
        this.openSnackBar(error.message, 'Ok');
      });
    }
  }

  setActMethTotals(res: any) {
    this.getActivationMethods();
    if (res.length > 0) {
      this.totalActMeth = [];
      for (let i = 0; i < this.activationMethods.length; i++) {
        this.totalActMeth[i] = {
          icon: this.activationMethods[i].icon,
          id: this.activationMethods[i].id,
          count: 0,
        };
        for (let j = 0; j < res.length; j++) {
          if (this.activationMethods[i].id === res[j].id) {
            this.totalActMeth[i] = {
              ...this.totalActMeth[i],
              count: res[j].count
            };
          }
        }
      }
    }

    this.loadingData = false;

    if (this.percentage) {
      this.changetoPercentage();
    }
  }

  changetoPercentage() {

    if (this.percentage) {
      this.eventCountsAux = this.eventCounts;
      this.totalActMethAux = this.totalActMeth;
      if (this.eventCountsPercentage) {
        this.eventCounts = this.eventCountsPercentage;
        this.totalActMeth = this.totalActMethPercentage;
      } else {
        let sumEvents = 0;
        let sumActMet = 0;
        for (let i = 0; i < this.totalActMeth.length; i++) {
          sumActMet = sumActMet + this.totalActMeth[i].count;
        }
        this.totalActMethPercentage = [];
        for (let i = 0; i < this.totalActMeth.length; i++) {
          this.totalActMethPercentage.push({
            ...this.totalActMeth[i],
            count: (this.totalActMeth[i].count * 100 / sumActMet).toFixed()
          })
        }
        this.eventCountsPercentage = [];
        this.eventCounts.map((value: any) => {
          sumEvents = sumEvents + value;
        })
        this.eventCounts.map((value: any) => {
          this.eventCountsPercentage.push((value * 100 / sumEvents).toFixed());
        })
        this.eventCounts = this.eventCountsPercentage;
        this.totalActMeth = this.totalActMethPercentage
      }
    } else {
      this.eventCounts = this.eventCountsAux;
      this.totalActMeth = this.totalActMethAux;
    }
  }

  capitalize(str: string): string {
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
      const lower = words[i].toLowerCase()
      words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
    }

    return words.join(" ");
  }

  setChart() {
    let payload = {
      ...this.dataShared.payload,
      is_drill: this.dataShared.content === 'drill'
    }
    this.eventService.getDashboardGeneralInfo(payload).subscribe((res: any) => {
      this.organizationName = res.account_name;
      this.event_brreakdown = res.event_brreakdown;
      if (res.event_brreakdown) {
        this.setEvents(res.event_brreakdown);
        this.setActMethTotals(res.activation_methods_breakdown);
      } else {
        this.loadingData = false;
      }

    }, error => {
      this.openSnackBar(error.message, 'Ok');
    })
  }

  setEvents(res: any) {

    this.eventCounts = [];
    res.map((item: any) => {

      this.eventCounts.push(item.count);
      this.eventTypes.map((ev: any) => {
        if (ev.event_type_id === item.event_type_id) {
          this.eventLabels.push(ev.name);
          this.backgroundColor.push(ev.color);
        }
      })

      if (this.eventCounts.length > 0) {
        this.totalEvents = this.eventCounts.reduce((a: any, b: any) =>  a + b, 0);
      }
    })

    this.infoAux = {
      eventCounts: this.eventCounts,
      eventLabels: this.eventLabels,
      backgroundColor: this.backgroundColor
    }

    this.sortingCheck();
  }

  protected readonly undefined = undefined;
}


import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { DashComponent } from "./dash.component";
import { CommonModule } from '@angular/common';
import { DashRoutingModule } from "./dash-routing.module";
import { MaterialModule } from '../../shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        DashRoutingModule,
        CommonModule,
        NgMultiSelectDropDownModule.forRoot(),
    ],
    declarations: [
        DashComponent,
    ],
    exports: [
        DashComponent
    ]
})
export class DashModule { }
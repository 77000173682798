import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocationType } from "src/app/core/models/global-types";
import { emptyValidator } from "src/app/core/utils/utility-functions";

@Component({
    selector: "manage-sites-dialog",
    templateUrl: "manage-sites-dialog.component.html",
    styleUrls: [
        "manage-sites-dialog.component.scss"
    ]
})
export class ManageSitesDialogComponent {

    /** The index of the currently selected tab */
    selectedTabIndex = 0

}
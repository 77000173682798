import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { EventData } from 'src/app/core/models/event-data';
import { EventsService } from 'src/app/core/services/events.service';
import {
  rotateIcon180Trigger,
  toggleCollapseTrigger,
} from 'src/app/core/utils/collapse-animations';
import { ActivationMethod } from 'src/app/core/models/activation-method';
import { EventActivation } from 'src/app/core/models/event-activation';
import { MatDialog } from '@angular/material/dialog';
import { CreateClearEventComponent } from '../create-clear-event-dialog/create-clear-event.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OneTimeService } from 'src/app/core/services/onetime.service';
import { EventTypesLocation } from 'src/app/core/models/event-types-location';
import {
  EventTimeline,
  TimeliesResponse,
} from 'src/app/core/models/timeline-response';

@Component({
  selector: 'events',
  templateUrl: 'events.component.html',
  styleUrls: ['events.component.scss'],
  animations: [toggleCollapseTrigger, rotateIcon180Trigger],
})
export class EventsComponent implements OnInit, OnDestroy {
  loadingData = true;

  @Input('content') content: string = '';

  data: any;
  websocketOpenTimeline = false;
  // totalAttendance = 0;

  @Input('data') set dataSet(value: any) {
    this.loadingData = true;
    this.data = value;
    // console.log('event data', this.data);
    
    if (this.data) {
      if (this.data.eventsRes && this.data.eventTypes) {
        this.setEvents(this.data.eventsRes);
        if (!this.openedEventIndex && this.events.length == 1) {
          this.openedEventIndex = this.events[0].id;
        }
      } else {
        this.loadingData = false;
        this.events = [];
      }
      if (this.data.eventIdsOpen) {
        if (this.data.eventIdsOpen != this.openedEventIndex) {
          this.toggleEvent(this.data.eventIdsOpen, true, true);
        }
      }

      // if (this.openedEventIndex && !this.data.eventIdsOpen) {
      //     setTimeout(() => {
      //         for (let i = 0; i < this.data.eventsRes.length; i++) {
      //             if (this.data.eventsRes[i].id == this.openedEventIndex) {
      //                 this.eventsService.sendClickMapLocation(this.data.eventsRes[i].location_id, false);
      //                 break;
      //             }
      //         }
      //     }, 1000);
      // }

      if (this.data.selectedIds?.length === 0) {
        this.loadingData = false;
      }
    }
  }

  get dataSet(): any {
    return this.data;
  }

  /** Reference to the locations subcription, used to unsubscribe */

  events: EventData[] = [];
  activationMethods: ActivationMethod[] = [];

  /** The index of the currently opened event */
  openedEventIndex: string | null = '';

  tabSelected: number = 0;
  openedAttendance: number | null = null;

  constructor(
    private eventsService: EventsService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private oneTimeService: OneTimeService,
    private _cdr: ChangeDetectorRef
  ) {}

  attendanceOpen(event: number) {
    this.openedAttendance = event;
    this._cdr.detectChanges();
  }

  getClassCSS(): string {
    if (this.data?.content == 'home' || this.data?.content == 'drill') {
      return 'p-0 home__height';
    } else {
      return 'p-0 history__height';
    }
  }

  tabChanged(event: any, location_id: string) {
    this.tabSelected = event.index;
    this.eventsService.sendEventsSubs({
      channel: 'event-map-timeline-pins',
      broadcastID: 'EventsComponent',
      payload: {
        isAttendanceTab: event.index == 1,
        location_id: location_id,
      },
    });
  }

  // Snackbar

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  openDialog(elem: string, id: string): void {
    if (elem == 'clearEvent') {
      let event: EventTypesLocation | null | undefined = null;
      let location: any;
      this.events?.map((ev: any) => {
        if (ev.id === id) {
          event = ev.type;
          location = {
            name: ev.location,
            id: ev.locationId,
          };
        }
      });
      const dialogRef = this.dialog.open(CreateClearEventComponent, {
        width: '800px',
        data: {
          title: 'CONFIRM CLEAR ACTIVE EVENT',
          content: 'clearEvent',
          button: 'Confirm',
          event: event,
          location: location,
          id: id,
          from: 'events',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'cleared') {
          this.openSnackBar('The event was cleared', 'OK');
        }
        console.log('The dialog was closed');
      });
    }
  }

  clearEvent(eventId: string) {
    let location: string | undefined;
    this.events?.map((ev: any) => {
      if (ev.id === eventId) {
        location = ev.locationId;
      }
    });

    if (location) {
      let payload = {
        location_id: location,
      };
      this.eventsService.clearEventRequest(payload).subscribe(
        (res: any) => {},
        (error) => {
          this.openSnackBar(error.message, 'Ok');
        }
      );
    }
  }

  getSelectedEventType(eventTypeId: string, locationId: string) {
    let selectedEventType: EventTypesLocation | null = null;

    this.data.eventTypes?.map((item: any) => {
      if (item.location_id === locationId) {
        item.eventTypes?.map((event: any) => {
          if (eventTypeId === event.id) {
            selectedEventType = event;
          } else {
            event.event_types?.map((nev: any) => {
              if (eventTypeId === nev.id) {
                selectedEventType = {
                  ...nev,
                  name: `${event.name}: ${nev.name}`,
                };
              }
            });
          }
        });
      }
    });
    return selectedEventType;
  }

  getSelectedActivationMethod(activationMethodId: string) {
    let selectedActivatioMethod: ActivationMethod | null = null;
    this.activationMethods?.map((item: any) => {
      if (activationMethodId === item.id) {
        selectedActivatioMethod = item;
      }
    });
    return selectedActivatioMethod;
  }

  setActivations(
    eventTimeline: EventTimeline[],
    locationId: string,
    generalEvType: EventTypesLocation,
    generalActMethod: ActivationMethod
  ) {
    let activations: EventActivation[] = [];
    eventTimeline?.map((item: any) => {
      if (item.record_type === 'event') {
        let event_date = item.event_date?.split('T', 2)[0].split('-', 3);
        let hour = item.event_date
          ? item.event_date?.split('T', 2)[1].split(':', 2)
          : '';
        let utcDate = item.event_date
          ? Date.UTC(
              +event_date[0],
              +event_date[1] - 1,
              +event_date[2],
              +hour[0],
              +hour[1]
            )
          : undefined;
        if (utcDate) {
          let localZoneDate = item.event_date ? new Date(utcDate) : undefined;

          if (item.event_type_location_id && item.activation_method_id) {
            let evType: any = this.getSelectedEventType(
              item.event_type_location_id,
              locationId
            );

            let acMethod: any = this.getSelectedActivationMethod(
              item.activation_method_id
            );
            // let event_date = item.event_date.split("T", 2)[0].split('-',3);
            // let hour = event_date[1].split(":", 2);
            // let utcDate = Date.UTC(+event_date[0], +event_date[1], +event_date[2], +hour[0], +hour[1]);
            // let localZoneDate = new Date(utcDate)
            let activatedBy: string | null | undefined = '';
            if (
              item.activation_method_name === 'web' ||
              item.activation_method_name === 'mobile'
            ) {
              activatedBy = item.user_full_name;
            }
            let eventName;
            let loc = this.data.eventTypes.find(
              (dataItem: any) => dataItem.location_id === locationId
            );
            loc?.eventTypes?.map((event: any) => {
              if (item.event_type_location_id === event.id) {
                eventName = event.name;
              } else {
                event.event_types?.map((nev: any) => {
                  if (item.event_type_location_id === nev.id) {
                    eventName = `${event.name}: ${nev.name}`;
                  }
                });
              }
            });
            activations.push({
              id: item.event_id,
              record: item.record_type,
              building_id: item.building_id,
              title: acMethod ? acMethod.description : '',
              isEvent: true,
              activationIcon: acMethod.icon,
              backgroundColor: evType ? evType.backgroundColorStrong : '000',
              locationId: locationId,
              generalEventType: generalEvType,
              generalActMethod: generalActMethod,
              description: {
                device_name: item.device_name,
                event_lat: item.event_latitude,
                event_long: item.event_longitude,
                activatedBy: activatedBy,
                eventType: eventName,
                area_name: item.building_name,
                eventIcon: evType.icon,
                eventBackground: evType.backgroundColor,
                eventTypeImage: item.event_type_content,
                eventTypeId: evType.id,
              },
              // time: `${event_date[0]} ${hour[0]}:${hour[1]}`,
              time: localZoneDate?.toString(),
              // time: item.event_date
            });
          }
        }
      }
      if (item.record_type === 'attendance') {
        let event_date = item.reported_on?.split('T', 2)[0].split('-', 3);
        let hour = item.reported_on
          ? item.reported_on?.split('T', 2)[1].split(':', 2)
          : '';

        if (event_date) {
          let utcDate = item.reported_on
            ? Date.UTC(
                +event_date[0],
                +event_date[1] - 1,
                +event_date[2],
                +hour[0],
                +hour[1]
              )
            : undefined;
          if (utcDate) {
            let localZoneDate = item.reported_on
              ? new Date(utcDate)
              : undefined;
            activations.push({
              id: item.id,
              record: item.record_type,
              building_id: item.building_id,
              title: 'Attendance',
              isEvent: true,
              activationIcon: 'icon_attendance.svg',
              backgroundColor: 'white',
              locationId: locationId,
              description: {
                area_name: item.building_name,
                people: item.people,
                activatedBy: item.reported_by,
                details: item.location_description,
              },
              // time: `${event_date[0]} ${hour[0]}:${hour[1]}`,
              time: localZoneDate?.toString(),
              _rank: item._rank
            });
          }
        }
      }

      if (item.record_type == 'event_instances_communications') {
        // let event_date = item.event_date.split("T", 2);
        // let hour = event_date[1].split(":", 2);
        let event_date = item.event_date?.split('T', 2)[0].split('-', 3);
        let hour = item.event_date
          ? item.event_date?.split('T', 2)[1].split(':', 2)
          : '';
        let utcDate = item.event_date
          ? Date.UTC(
              +event_date[0],
              +event_date[1] - 1,
              +event_date[2],
              +hour[0],
              +hour[1]
            )
          : undefined;
        if (utcDate) {
          let localZoneDate = item.event_date ? new Date(utcDate) : undefined;

          activations.push({
            id: item.event_date,
            record: item.record_type,
            building_id: item.building_id,
            title: 'Text, Email, Voicecall',
            isEvent: false,
            activationIcon: 'icon_text-email.svg',
            backgroundColor: this.searchForEventColor(
              activations.length - 1,
              activations
            ),
            locationId: locationId,
            description: {
              activatedBy: item.user_full_name,
              text: 'Sent',
            },
            // time: `${event_date[0]} ${hour[0]}:${hour[1]}`
            time: localZoneDate?.toString(),
          });
        }
      }

      if (item.cancelation_status) {
        if (item.cancelation_status === 'pending') {
          let event_date = item.event_date?.split('T', 2)[0].split('-', 3);
          let hour = item.event_date
            ? item.event_date?.split('T', 2)[1].split(':', 2)
            : '';
          let utcDate = item.event_date
            ? Date.UTC(
                +event_date[0],
                +event_date[1] - 1,
                +event_date[2],
                +hour[0],
                +hour[1]
              )
            : undefined;
          if (utcDate) {
            let localZoneDate = item.event_date ? new Date(utcDate) : undefined;
            activations.push({
              id: item.event_date,
              record: item.record_type,
              building_id: item.building_id,
              title: 'Request to Clear',
              isEvent: false,
              activationIcon: 'icon_mobile_activation.svg',
              backgroundColor: '#606060',
              locationId: locationId,
              description: {
                activatedBy: item.user_full_name,
              },
              // time: `${event_date[0]} ${hour[0]}:${hour[1]}`,
              time: localZoneDate?.toString(),
            });
          }
        }
      }

      if (item.comunication_id) {
        let event_date = item.event_date?.split('T', 2)[0].split('-', 3);
        let hour = item.event_date
          ? item.event_date?.split('T', 2)[1].split(':', 2)
          : '';
        let utcDate = item.event_date
          ? Date.UTC(
              +event_date[0],
              +event_date[1] - 1,
              +event_date[2],
              +hour[0],
              +hour[1]
            )
          : undefined;
        if (utcDate) {
          let localZoneDate = item.event_date ? new Date(utcDate) : undefined;
          let title = '';
          if (item.is_conference) {
            title = 'Group Call Started';
          }
          if (item.is_e911) {
            title = '911 Connected';
          }
          activations.push({
            id: item.event_date,
            record: 'communication',
            building_id: item.building_id,
            title: title,
            isEvent: false,
            // activationIcon: "icon_web_activation.svg",
            // backgroundColor: "#606060",
            // locationId: locationId,
            activationIcon: 'icon_group-call.svg',
            backgroundColor: this.searchForEventColor(
              activations.length - 1,
              activations
            ),
            locationId: locationId,
            description: {
              activatedBy: item.user_full_name,
              text: 'Initiated',
            },
            // time: `${event_date[0]} ${hour[0]}:${hour[1]}`,
            time: localZoneDate?.toString(),
          });
        }
      }
    });
    /**To test the cards for externar comunication */
    // activations.push({
    //     id: 'group-call',
    //     record: "communication",
    //     building_id: 'no building',
    //     title: "Group Call",
    //     isEvent: false,
    //     activationIcon: "icon_group-call.svg",
    //     backgroundColor: this.searchForEventColor(activations.length - 1, activations),
    //     locationId: locationId,
    //     description: {
    //         activatedBy: "Emil",
    //         text: 'Initiated'
    //     },
    //     time: `2022 -09-22:48`,
    // });

    // activations.push({
    //     id: 'other-comunications',
    //     record: "communication",
    //     building_id: 'no building',
    //     title: "Text, Email, Voicecall",
    //     isEvent: false,
    //     activationIcon: "icon_text-email.svg",
    //     backgroundColor: this.searchForEventColor(activations.length - 1, activations),
    //     locationId: locationId,
    //     description: {
    //         activatedBy: "Emil",
    //         text: 'Sent'
    //     },
    //     time: `2022-09-22:48`,
    // });

    /**END */
    activations = activations.reverse();
    return activations;
  }

  searchForEventColor(
    activationIndex: number,
    activations: EventActivation[]
  ): string {
    for (let i = activationIndex; i >= 0; i--) {
      if (activations[i].record == 'event') {
        return activations[i].backgroundColor;
      }
    }

    return '';
  }

  roleHandle(locId: string) {
    let roleLocUser: string = '';
    let usrProf: string | null = sessionStorage.getItem('user-profile');
    let roleProfile = JSON.parse(usrProf ? usrProf : '');
    roleProfile?.locations?.map((loc: any) => {
      if (loc.location_id === locId) {
        roleLocUser = loc.role_name;
      }
    });
    return roleLocUser;
  }

  setEvents(res: any) {
    if (res) {
      this.events = [];
      let clearedBy;
      if (this.data?.content === 'home' || this.data?.content === 'drill') {
        let resAux: TimeliesResponse[] = res;
        resAux?.map((item: any) => {
          let start_date = item.start_dte?.split(' ', 2)[0].split('-', 3);
          let hour = item.start_dte
            ? item.start_dte?.split(' ', 2)[1].split(':', 2)
            : '';
          let utcDate = item.start_dte
            ? Date.UTC(
                +start_date[0],
                +start_date[1] - 1,
                +start_date[2],
                +hour[0],
                +hour[1]
              )
            : undefined;
          let localZoneDate = item.start_dte ? new Date(utcDate) : undefined;
          let acMethod: any = this.getSelectedActivationMethod(
            item.general_activation_method_id
          );
          let genEvType: any = this.getSelectedEventType(
            item.general_event_type_id,
            item.location_id
          );
          this.events.push({
            id: item.id,
            time: localZoneDate?.toString(),
            location: item.location_name,
            locationId: item.location_id,
            type: genEvType,
            activationMethod: acMethod ? acMethod.description : '',
            activations: this.setActivations(
              item.event_timeline,
              item.location_id,
              genEvType,
              acMethod
            ),
            roleLocUser: this.roleHandle(item.location_id),
            general_attendance: item.general_attendance,
          });
        });
      }

      if (
        this.data?.content === 'drillHistory' ||
        this.data?.content === 'eventHistory'
      ) {
        let resAux: TimeliesResponse[] = res;
        resAux?.map((item) => {
          if (item.id === this.data.eventHistory.id) {
            if (item.end_dte) {
              clearedBy = item.event_timeline.filter(
                (ev: any) => ev.record_type === 'clear'
              );
            }
            let start_date = item.start_dte?.split(' ', 2)[0].split('-', 3);
            let hour = item.start_dte
              ? item.start_dte?.split(' ', 2)[1].split(':', 2)
              : '';
            let utcDate = item.start_dte
              ? Date.UTC(
                  +start_date[0],
                  +start_date[1] - 1,
                  +start_date[2],
                  +hour[0],
                  +hour[1]
                )
              : undefined;
            let localZoneDate = item.start_dte ? new Date(utcDate) : undefined;
            let acMethod: any = this.getSelectedActivationMethod(
              item.general_activation_method_id
            );
            let genEvType: any = this.getSelectedEventType(
              item.general_event_type_id,
              item.location_id
            );
            this.events.push({
              id: item.id,
              time: localZoneDate?.toString(),
              location: item.location_name,
              locationId: item.location_id,
              type: genEvType,
              activationMethod: acMethod ? acMethod.description : '',
              activations: this.setActivations(
                item.event_timeline,
                item.location_id,
                genEvType,
                acMethod
              ),
              clearedBy: clearedBy ? clearedBy[0].user_full_name : '',
              roleLocUser: this.roleHandle(item.location_id),
              general_attendance: item.general_attendance,
            });
            this.toggleEvent(item.id, true);
          }
        })
      }

      this.loadingData = false;
    }
  }

  setActivationMethods() {
    if (sessionStorage.getItem('activationMethods')) {
      let strObj = sessionStorage.getItem('activationMethods');
      if (strObj) {
        let obj = JSON.parse(strObj);
        for (let i = 0; i < obj.length; i++) {
          this.activationMethods.push(obj[i]);
        }
      }
    } else {
      this.oneTimeService.getActivationMethods().subscribe((res: any) => {
        res?.map((item: any) => {
          this.activationMethods.push({
            id: item.id,
            description: item.description,
            backgroundColor: item.background_color,
            icon: item.icon,
          });
        });
        sessionStorage.setItem(
          'activationMethods',
          JSON.stringify(this.activationMethods)
        );
      });
    }
  }

  ngOnInit() {
    this.setActivationMethods();
  }

  ngOnDestroy() {}

  /** Toggles the accordion of the event at the given [eventIndex] */
  toggleEvent(eventIndex: string, asObervable: boolean, websocket?: boolean) {
    if (asObervable || websocket) {
      if (websocket) {
        this.websocketOpenTimeline = true;
      }
      this.openedEventIndex =
        eventIndex === this.openedEventIndex ? null : eventIndex;
    } else {
      if (eventIndex === this.openedEventIndex) {
        this.openedEventIndex = null;
        if (
          this.data.content !== 'drillHistory' &&
          this.data.content !== 'eventHistory'
        ) {
          this.eventsService.sendShowAllLocations();
        }
      } else {
        this.openedEventIndex = eventIndex;
        if (
          this.data.content != 'drillHistory' &&
          this.data.content != 'eventHistory'
        ) {
          this.events?.map((ev: any) => {
            if (ev.id === eventIndex) {
              this.eventsService.sendClickMapLocation(
                ev.locationId ? ev.locationId : '',
                true
              ); // To call the function that zoom in the location on Map Component
            }
          });
        }
      }
    }
  }

  /** The state of the rotation icon for the event at the [eventIndex] */
  eventIconState(eventIndex: string): string {
    return this.openedEventIndex === eventIndex ? 'rotated' : 'default';
  }

  clearDrill(elem: string, hubEvent: any) {
    this.eventsService.sendClickDeactivateDrill(elem, hubEvent);
  }
}

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry, map } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  private subject = new Subject<any>();
  private subjectCloseDialog = new Subject<any>();
  private subjectEnableButton = new Subject<any>();
  private subjectDisableButton = new Subject<any>();
  private subjectSaveDevice = new Subject<any>();
  private subjectSaveDisplayMsg = new Subject<any>();
  private subjectActivateLoadingBar= new Subject<any>();


  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) { }

  sendActivateLoadingBar(value:boolean) {
    this.subjectActivateLoadingBar.next(value);
  }

  getActivateLoadingBar(): Observable<any> {
    return this.subjectActivateLoadingBar.asObservable();
  }

  sendClickSaveDisplayMsg() {
    this.subjectSaveDisplayMsg.next('');
  }

  getClickSaveDisplayMsg(): Observable<any> {
    return this.subjectSaveDisplayMsg.asObservable();
  }

  sendClickSaveDevice() {
    this.subjectSaveDevice.next('');
  }

  getClickSaveDevice(): Observable<any> {
    return this.subjectSaveDevice.asObservable();
  }

  sendEnableButton(){
    this.subjectEnableButton.next('');
  }

  sendDisableButton(){
    this.subjectDisableButton.next('');
  }

  getEnableButton(): Observable<any>{ 
    return this.subjectEnableButton.asObservable();
  }
  getDisableButton(): Observable<any>{ 
    return this.subjectDisableButton.asObservable();
  }

  sendClickCloseDialog(msg: string) {
    this.subjectCloseDialog.next(msg);
  }

  getClickCloseDialog(): Observable<any>{ 
    return this.subjectCloseDialog.asObservable();
  }

  sendClickEvent() {
    this.subject.next('');
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  sendReleaseLock(location_id: string, device_group_id: string, payload: Object){
    let api = `${this.endpoint}/location/${location_id}/device-group/${device_group_id}/release-locks`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDeviceGroupTypes() {
    let api = `${this.endpoint}/device-group-types`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  discoveryDevices(payload: Object){
    let api = `${this.endpoint}/device-group/discovery`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDeviceTypes(){
    let api = `${this.endpoint}/device-types`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDevice(locationId: string, deviceGroupId: string, deviceId: string){
    let api = `${this.endpoint}/location/${locationId}/device-group/${deviceGroupId}/device/${deviceId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateDevice(locationId: string, deviceGroupId: string, deviceId: string, payload: Object){
    let api = `${this.endpoint}/location/${locationId}/device-group/${deviceGroupId}/device/${deviceId}`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getSensorMapping(locationId: string, deviceId: string){
    let api = `${this.endpoint}/location/${locationId}/device-groups/sensor/${deviceId}/mappings`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  // getDisplayMapping(locationId: string, deviceId: string){
  //   let api = `${this.endpoint}/location/${locationId}/device-groups/display/${deviceId}/mappings`;
  //   return this.http
  //     .get(api, { headers: this.headers, responseType: "json" })
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  createDeviceGroup(locationId: string, payload: Object) {
    let api = `${this.endpoint}/location/${locationId}/device-groups`;
    return this.http
      .post(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getGroup(locationid: string, deviceGroupId: string){
    let api = `${this.endpoint}/location/${locationid}/device-group/${deviceGroupId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateGroup(locationId: string, groupId: string, payload: Object) {
    let api = `${this.endpoint}/location/${locationId}/device-group/${groupId}`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  deleteGroup(locationId: string, groupId: string) {
    let api = `${this.endpoint}/location/${locationId}/device-group/${groupId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getDisplays(locationId: string){
    let api = `${this.endpoint}/location/${locationId}/device-groups/displays`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getSensors(locationId: string){
    let api = `${this.endpoint}/location/${locationId}/device-groups/sensors`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getBuildingDisplays(locationId: string, buildingId: string){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/displays`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }
  
  getDisplayCustomization(locationId: string, eventTypeId: string, deviceId: string){
    let api = `${this.endpoint}/location/${locationId}/event-type-location/${eventTypeId}/device/${deviceId}`;
    // let api = `${this.endpoint}/location/${locationId}/device/${deviceId}/event-type-location/${eventTypeId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  updateDisplayCustomization(locationId: string, eventTypeId: string, deviceId: string, payload: Object){
    let api = `${this.endpoint}/location/${locationId}/event-type-location/${eventTypeId}/device/${deviceId}`;
    // let api = `${this.endpoint}/location/${locationId}/device/${deviceId}/event-type-location/${eventTypeId}`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEventDisplayImage(locationId: string, eventTypeId: string, deviceId: string, file_path: string){
    let api = `${this.endpoint}/location/${locationId}/device/${deviceId}/event-type-location/${eventTypeId}/file?file_path=${file_path}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "blob" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getEditEventImage(locationId: string, eventTypeId: string, file_path: string){
    let api = `${this.endpoint}/location/${locationId}/event-type/${eventTypeId}/file?file_path=${file_path}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "blob" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }


  getBuildingSensors(locationId: string, buildingId: string){
    let api = `${this.endpoint}/location/${locationId}/building/${buildingId}/sensors`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error.message
      };
    }
    return throwError(msg);
  }
}

<div class="container-area-info">
    <div class="area-info">
        <p class="name">{{ data?.area.name }}</p>
        <p>{{ data?.area.address }}</p>
        <p>{{ data?.area.city }}, {{ data?.area.state }}, {{ data?.area.zip_code }}
            <span *ngIf="data?.area.zip_code_plus_4 != '0000'"> - {{ data?.area.zip_code_plus_4 }}</span>
        </p>
    </div>
    <table [dataSource]="areaDetails" mat-table class="areaDetails">
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Area ID</th>
            <td mat-cell *matCellDef="let el"> {{ el.code }} </td>
        </ng-container>
        <ng-container matColumnDef="level">
            <th mat-header-cell *matHeaderCellDef>Level</th>
            <td mat-cell *matCellDef="let el">{{ el.level }}</td>
        </ng-container>
        <ng-container matColumnDef="total_blueprints">
            <th mat-header-cell *matHeaderCellDef>Blueprints</th>
            <td mat-cell *matCellDef="let el"> {{ el.total_blueprints }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="areaDetailFields; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: areaDetailFields;"></tr>
    </table>
    <div class="blueprints-header">
        <img width="44px" src="./assets/images/icons/icon-blueprint.svg">
        <span>Blueprints</span>
    </div>
    <div class="table-blueprint-list sslim-scrollbar">
        <div *ngIf="data?.blueprints.length == 0" class="empty-state-hhub"> </div>
        <table *ngIf="data?.blueprints.length > 0" [dataSource]="blueprintsList" mat-table class="blueprintsList">
            <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let blp"> {{ blp.name }} </td>
            </ng-container>
            <ng-container matColumnDef="view">
                <td mat-cell *matCellDef="let blp">
                    <button mat-stroked-button color="warn" (click)="openDialog(blp)">
                        VIEW   <mat-icon>open_in_new</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: blueprintsFields;"></tr>
        </table>
    </div>
</div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/core/services/events.service';
import { SelectedLocationsService } from 'src/app/core/services/selected-locations.service';

@Component({
  selector: 'drill-dashboard',
  templateUrl: './drill-dashboard.component.html',
  styleUrls: ['./drill-dashboard.component.scss']
})
export class DrillDashboardComponent implements OnInit, OnDestroy {

  private locationsSubscription: Subscription | undefined;
  private clickTotalEventSubscription: Subscription;

  isChecked = false;

  selectedLocationsIds: string[] | undefined;

  dataShare: any;
  data: any;

  loadingData: boolean = false;

  private dataDrill: any;

  @Input('dataDrill') set dataSet(value: any) {
    // console.log("entra en el location set")
    this.loadingData = true

    this.dataDrill = value;
    // console.log("data", this.data);
    if (!this.dataDrill) {
      this.loadingData = false
    }
  }

  get dataSet(): any {
    return this.dataDrill;
  }

  constructor(private eventService: EventsService,
    private selectedLocationsService: SelectedLocationsService,
    private _snackBar: MatSnackBar) {
    this.clickTotalEventSubscription = this.eventService.getClickTotalEvents().subscribe((data: any) => {
      // this.events = [];
      // console.log("data subscription", data);
      if (data) {
        if (data.startDate && data.endDate && data.endDate.length > 0) {
          this.dataShare = { ...this.dataShare, loadingData: true }
          this.data = data;
          this.getInfo(data);
        }
      } else {
        this.dataShare = {
          ...this.dataShare,
          eventTypeDashRes: undefined,
          actMethRes: undefined,
          data: undefined,
          loadingData: false
        }
      }



    })
  }

  ngOnInit(): void {
    this.dataShare = { loadingData: true }
    this.selectedLocationsIds = [];
    // console.log("se llama aqui 1")
    this.getAllEventTypes()

    this.locationsSubscription = this.selectedLocationsService.selectedLocationsObservable.subscribe(selectedIds => {
      // console.log("entra al subscribe")
      this.dataShare = {
        ...this.dataShare,
        eventTypeDashRes: undefined,
        actMethRes: undefined,
        loadingData: true
      }
      if (selectedIds.length > 0) {
        this.selectedLocationsIds = selectedIds
        if (this.data) {
          this.getInfo(this.data);
        } else {
          this.dataShare = {
            ...this.dataShare,
            eventTypeDashRes: undefined,
            actMethRes: undefined,
            data: undefined,
            selectedLocIds: selectedIds,
            loadingData: false
          }
        }
      } else {
        this.dataShare = {
          ...this.dataShare,
          selectedLocIds: selectedIds
        }
        if (!this.data && this.dataShare.eventTypesUniques) {
          // console.log("se llama aqui 2")
          this.selectedLocationsIds = [];
          this.getAllEventTypes()
        }
      }
    });
  }

  getToggleCheck(toggle: any) {
    // console.log("toggle", toggle);
    this.isChecked = toggle;
  }

  getInfo(data: any) {
    // console.log("entra al get info")
    let evTypIds: string[] = [];
    data.events.map((ev: any) => {
      evTypIds.push(ev.eventTypeId);
    })
    let startDate = data.startDate.split("/", 3);
    let endDate = data.endDate.split("/", 3);
    let payload = {
      // location_ids: this.selectedLocationsIds,
      event_types_ids: evTypIds,
      start_date: `${startDate[2]}-${startDate[0]}-${startDate[1]}`,
      end_date: `${endDate[2]}-${endDate[0]}-${endDate[1]}`
    }

    this.dataShare = {
      ...this.dataShare,
      range: data.range,
      payload: payload,
      date: `${startDate[1]}/${startDate[0]}/${startDate[2]} - ${endDate[1]}/${endDate[0]}/${endDate[2]}`
  }

    // // console.log("payload", payload)
    // this.eventService.getEventTypesDashboard(payload).subscribe((eventTypeDashRes: any) => {

    //   this.eventService.getActivationMethodsDashboard(payload).subscribe((actMethRes: any) => {
    //     // console.log("entra al servicio", actMethRes)
    //     this.dataShare = {
    //       ...this.dataShare,
    //       eventTypeDashRes: eventTypeDashRes,
    //       actMethRes: actMethRes,
    //       data: data,
    //       selectedLocIds: this.selectedLocationsIds,
    //       date: `${startDate[1]}/${startDate[0]}/${startDate[2]} - ${endDate[1]}/${endDate[0]}/${endDate[2]}`
    //       // loadingData: true
    //     }
    //     // console.log("data share", this.dataShare)
    //   }, error => {
    //     this.openSnackBar(error.message, 'Ok');
    //   })
    // }, error => {
    //   this.openSnackBar(error.message, 'Ok');
    // })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }

  getAllEventTypes() {
    // console.log("call event types")
    let payload = {
      location_ids: this.selectedLocationsIds
    }
    this.eventService.getEventTypesUniques(payload).subscribe((eventTypesUniques: any) => {
      // console.log("unique call", eventTypesUniques);
      let evIds: any[] = [];

      eventTypesUniques.map((item: any) => {
        evIds.push(item.event_type_id);
      })

      let lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 2);
      lastMonth.setDate(1);
      let current = new Date();
      let payload2 = {
        // location_ids: this.selectedLocationsIds,
        event_types_ids: evIds,
        start_date: `${lastMonth.getFullYear()}-${lastMonth.getMonth() + 1}-${lastMonth.getDate()}`,
        end_date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
      }

      this.dataShare = {
        content: 'drill',
        eventTypesUniques: eventTypesUniques,
        range: 'month',
        payload: payload2,
        date: `${lastMonth.getDate()}/${lastMonth.getMonth() + 1}/${lastMonth.getFullYear()} - ${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    }

      //   console.log("payload", payload2);
      // this.eventService.getEventTypesDashboard(payload2).subscribe((eventTypeDashRes: any) => {

      //   this.eventService.getActivationMethodsDashboard(payload2).subscribe((actMethRes: any) => {

      //     this.dataShare = {
      //       eventTypesUniques: eventTypesUniques,
      //       eventTypeDashRes: eventTypeDashRes,
      //       actMethRes: actMethRes,
      //       selectedLocIds: this.selectedLocationsIds,
      //       date: `${lastMonth.getDate()}/${lastMonth.getMonth() + 1}/${lastMonth.getFullYear()} - ${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      //     }

      //     // console.log("datashare", this.dataShare);
      //   }, error => {
      //     this.openSnackBar(error.message, 'Ok');
      //   })
      // }, error => {
      //   this.openSnackBar(error.message, 'Ok');
      // })
    }, error => {
      this.openSnackBar(error.message, 'Ok');
    })
  }

  ngOnDestroy() {
    this.locationsSubscription?.unsubscribe();
    this.clickTotalEventSubscription?.unsubscribe();
  }

}
export interface EventTypesLocation {
    id: string,
    color: string,
    backgroundColor: string,
    backgroundColorStrong: string
    name: string,
    icon: string,
    priority: number,
    code: string,
    trigger_e911?: boolean,
    event_actions?: any[],
    event_types?: EventTypesLocation[]
    is_custom?: boolean
    disabled?: boolean
}

export function mapEventTypestoModel(ev: any): EventTypesLocation {
    let eventTypes: EventTypesLocation;

    let rgb = hexToRgb(ev.background_color);
    let nestEv: any[] = [];
    ev.event_types?.map((nev: any) => {
        nestEv.push(mapEventTypestoModel(nev));
    });
    eventTypes = {
        id: ev.id,
        color: ev.color,
        backgroundColor: `rgb(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.1)`,
        backgroundColorStrong: ev.background_color,
        name: capitalize(ev.name),
        icon: ev.icon,
        priority: ev.priority,
        code: ev.code,
        trigger_e911: ev.trigger_e911,
        event_actions: ev.event_actions,
        is_custom: ev.is_custom,
        event_types: nestEv

    }
    return eventTypes
}

export function eventTypesWithCustomEvents(events: EventTypesLocation[]): EventTypesLocation[] {
    let eventsWithCustom: EventTypesLocation[] = [];
    let customs: EventTypesLocation[] = [];
    events.map((ev: any) => {
        if (ev.is_custom) {
            customs.push(ev)
        } else {
            eventsWithCustom.push(ev)
        }
    })
    if (customs.length > 0) {
        let rgb = hexToRgb('#9417eb');
        let rootCustomEvent: EventTypesLocation = {
            id: '',
            color: '#555555',
            backgroundColor: `rgb(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.1)`,
            backgroundColorStrong: '#9417eb',
            name: 'Custom Events',
            icon: 'icon_custom_event_root.svg',
            priority: 20,
            code: 'custom',
            event_types: customs
        }
        eventsWithCustom.push(rootCustomEvent);
    }


    return eventsWithCustom
}

function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function capitalize(str: string): string {
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
        const lower = words[i].toLowerCase()
        words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
    }

    return words.join(" ");
}

import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartDataset, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { EventsService } from 'src/app/core/services/events.service';


interface WholeData {
  barChartData: ChartDataset,
  barChartLabels: string
}

@Component({
  selector: 'locations-chart',
  templateUrl: './locations-chart.component.html',
  styleUrls: ['./locations-chart.component.scss']
})
export class LocationsChartComponent implements OnInit {

  searchValue: string = '';
  loadingData: boolean = true;
  percentage: boolean = false;

  dataShared: any = undefined;
  eventTypes: any = undefined;
  backgroundColor: string[] = [];
  @Input('dataShared') set dataSharedSet(value: any) {
    this.dataShared = value;
    this.loadingData = true;
    this.barChartData = [];
    this.barChartLabels = [];
    if (this.dataShared?.payload) {
      this.searchValue = '';
      this.eventTypes = this.dataShared.eventTypesUniques;
      this.getGeneralInfo();
    } else {
      this.barChartData = [];
      this.loadingData = false;
    }
  }

  get dataSharedSet(): any {
    return this.dataShared;
  }

  @Input('percentage') set percentageSet(value: any) {
    this.percentage = value;
  }

  get percentageSet(): any {
    return this.percentage;
  }

  sorting: boolean = false;

  @Input('sorting') set sortingSet(value: any) {
    this.sorting = value;
    this.sortingCheck()
  }

  get sortingSet(): any {
    return this.sorting;
  }

  public barChartOptions: ChartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          offset: true,
        },
        position: 'top',
      },
      y: {
        ticks: {
          minRotation: 1,
        },
      },
    },
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend: boolean = false;

  private barSize: number = 0.4;
  private categorySize: number = 1.0;

  public barChartData: ChartDataset[] = [];
  public barChartLabels: any[] = [];

  private originalData: any = undefined;
  sortedArray: any = undefined;

  constructor(private eventService: EventsService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  sortingCheck() {
    if (this.sorting) {
      this.sortedArray.sort( (a: any, b: any) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (b.name.toLowerCase() < a.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.setChart(this.sortedArray);
    } else {
      if (this.sortedArray) {
        this.sortedArray.sort(function (a: any, b: any) { return b.totalCount - a.totalCount });
        this.setChart(this.sortedArray);
      }
    }
  }

  searchLocation(locationName: string) {
    this.sortedArray = this.originalData;
    if (locationName != '') {
      let newArray = this.sortedArray.filter((item: any) => (item.name.toLowerCase()).includes(locationName.toLowerCase()));
      this.sortedArray = newArray;
    }
    this.setChart(this.sortedArray);
  }

  strToArray(str: string, limit: number) {
    const words = str.split(' ')
    let aux = []
    let concat = []

    for (let i = 0; i < words.length; i++) {
      concat.push(words[i])
      let join = concat.join(' ')
      if (join.length > limit) {
        aux.push(join)
        concat = []
      }
    }

    if (concat.length) {
      aux.push(concat.join(' ').trim())
    }
    return aux
  }

  setChart(res: any) {
    let evtArr: any = {};
    this.barChartLabels = [];
    this.barChartData = [];
    res.map((loc: any) => {
      this.barChartLabels.push(this.strToArray(loc.name, 10));
      loc.event_breakdown.map((evbr: any) => {
        if (!(evbr.event_type_id in evtArr)) {
          evtArr[evbr.event_type_id] = [];
        }
        evtArr[evbr.event_type_id].push(evbr.count);
      })
    })
    this.dataShared.payload.event_types_ids.map((pEv: any) => {
      this.eventTypes.map((ev: any) => {
        if(pEv == ev.event_type_id){
          this.barChartData.push({
            data: evtArr[ev.event_type_id],
            label: this.capitalize(ev.name),
            stack: 'a',
            barPercentage: this.barSize,
            categoryPercentage: this.categorySize,
            backgroundColor: ev.color,
            borderColor: ev.color,
            hoverBackgroundColor: ev.color,
            hoverBorderColor: ev.color,
          })
        }
      })
    })
    this.loadingData = false;
  }

  sortData(res: any) {
    this.sortedArray = [];
    for (let i = 0; i < res.length; i++) {

      let totalCount = 0;
      for (let j = 0; j < res[i].event_breakdown.length; j++) {
        totalCount = totalCount + res[i].event_breakdown[j].count;
      }
      this.sortedArray.push({
        ...res[i],
        totalCount: totalCount
      })
    }

    this.sortedArray.sort(function (a: any, b: any) { return b.totalCount - a.totalCount });
    this.originalData = [...this.sortedArray];
    this.setChart(this.sortedArray);
  }

  capitalize(str: string) {
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
      const lower = words[i].toLowerCase()
      words[i] = words[i].charAt(0).toUpperCase() + lower.slice(1)
    }

    return words.join(" ");
  }

  getGeneralInfo() {
    let payload = {
      ...this.dataShared.payload,
      is_drill: this.dataShared.content === 'drill'
    }
    this.eventService.getDashboardGeneralInfoLocations(payload).subscribe((res: any) => {
      if (res.length > 0){
        this.sortData(res)
      }else{
        this.barChartData = [];
        this.loadingData = false;
      }


    }, error => {
      this.openSnackBar(error.message, 'Ok');
    })

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
    });
  }
}

import { Component, DoCheck, ElementRef, Input, OnDestroy, QueryList, ViewChildren, ViewContainerRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { EventActivation } from "src/app/core/models/event-activation";
import { EventData } from "src/app/core/models/event-data";
import { SubscriptionModel } from "src/app/core/models/subscriptionModel";
import { EventsService } from "src/app/core/services/events.service";
import { LocationService } from "src/app/core/services/location.service";
import { DialogDisplayComponent } from "./dialog-display/dialog-display.component";

@Component({
    selector: "events-timeline",
    templateUrl: "events-timeline.component.html",
    styleUrls: [
        "events-timeline.component.scss"
    ]
})
export class EventsTimelineComponent implements DoCheck, OnDestroy {

    @ViewChildren('eventCard') eventCards: QueryList<ElementRef> = new QueryList<ElementRef>();

    /** The event data from which the timeline should be drawn */
    data: EventData = {
      activationMethod: "",
      activations: [],
      cancelRequest: undefined,
      clearedBy: undefined,
      general_attendance: 0,
      id: "",
      location: "",
      locationId: "",
      roleLocUser: "",
      time: "",
      type: null
    };
    @Input("data") set dataSet(value: EventData) {
        this.data = value;
        this.addButtonViewContent(this.data.activations);
    }

    get dataSet(): EventData {
        return this.data;
    }

    // data: EventData

    /** The height of the last card in px */
    lastCardHeight = 0

    private getClickMapEventHighlight: Subscription;
    private getEventsSubs: Subscription;

    constructor(
        private elementRef: ElementRef,
        private eventsService: EventsService,
        private locationService: LocationService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {
        const activations = this.data.activations || [];
        this.getClickMapEventHighlight = this.eventsService.getMapEventHighlight().subscribe((info: any) => {
            if (info) {
                for (let i = 0; i < activations.length; i++) {
                    if (activations[i].building_id == info.id) {
                        let div = <HTMLDivElement>document.getElementById(`ev-crd-${activations[i].id}`);
                        console.log('this.highlightCard 1', activations[i]);
                        
                        this.highlightCard(div, activations[i], i, true);
                    }
                }
            } else {
                for (let i = 0; i < activations.length; i++) {
                    let div = <HTMLDivElement>document.getElementById(`ev-crd-${activations[i].id}`);
                    this.undoHighlightCard(div, true);
                }
            }

        });

        this.getEventsSubs = this.eventsService.getEventsSubs().subscribe((info: SubscriptionModel) => {

            if (info.channel == 'map-timeline' && info.broadcastID != 'EventsTimelineComponent') {
                let div = <HTMLDivElement>document.getElementById(`ev-crd-${info.payload.eventId}`);
                if (info.payload.isGlow) {
                    for (let i = 0; i < activations.length; i++) {
                        if (activations[i].id == info.payload.eventId) {
                            console.log('this.highlightCard 1', activations[i]);
                            this.highlightCard(div, activations[i], i, true);
                            break;
                        }
                    }
                } else {
                    this.undoHighlightCard(div, true);
                }

            }
        })

    }

    openDialog(activation: any): void {

        if(activation.description.eventTypeImage){
            const dialogRef = this.dialog.open(DialogDisplayComponent, {
                width: '1300px',
                data: {
                    title: "EVENT CONTENT",
                    content: "view-display",
                    activation: activation
                }
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log('The dialog was closed', result);

            });
        }else{
            this.openSnackBar('There is no image uploaded for this event', 'Ok');
        }

    }

    //record_type === "event"

  addButtonViewContent(activations: EventActivation[] | undefined) {
        if(!activations) return;
        const licencesCounter = sessionStorage.getItem('licenses-counter') || "0";
        let licenses = JSON.parse(licencesCounter);
        if (licenses) {
            if (licenses.enable_dd.total > 0) {
                for (let i = 0; i < activations.length; i++) {
                    if (activations[i].record === "event") {
                        activations[i] = {
                            ...activations[i],
                            dialogData: {
                                locationName: this.data.location
                            }
                        }
                    }

                }
            }
        } else {
            this.locationService.licensesCounter().subscribe((res: any) => {
                sessionStorage.setItem('licenses-counter', JSON.stringify(res));
                this.addButtonViewContent(activations);
            }, error => {
                this.openSnackBar(error.message, 'Ok');
            })
        }


    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }

    mix2Colors(color_1: any, color_2: any, weight: any) {
        function d2h(d: any) { return d.toString(16); }  // convert a decimal value to hex
        function h2d(h: any) { return parseInt(h, 16); } // convert a hex value to decimal

        weight = (typeof (weight) !== 'undefined') ? weight : 50; // set the weight to 50%, if that argument is omitted

        var color = "#";

        for (var i = 0; i <= 5; i += 2) { // loop through each of the 3 hex pairs—red, green, and blue
            var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
                v2 = h2d(color_2.substr(i, 2)),

                // combine the current pairs from each source color, according to the specified weight
                val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

            while (val.length < 2) { val = '0' + val; } // prepend a '0' if val results in a single digit

            color += val; // concatenate val to our new color string
        }

        return color; // PROFIT!
    };

    highlightCard(div: any, activation: EventActivation | undefined, activationIndex: number, isSubscription?: boolean) {
        
        if (div?.classList.contains("event-card")) {
            let buildingId = activation?.building_id;
            let cardId = activation?.id;
            if (activation?.record != "event") {
                activation = this.searchForEventColor(activationIndex + 1);
            }
            if(activation?.backgroundColor){
                let color = activation?.backgroundColor.split('#') || [];
                let colorMixed = this.mix2Colors("000000", color[1], 50);
                div.style.outline = `4px solid ${activation?.backgroundColor}`;
                div.style.boxShadow = `0 0 60px 2px ${activation?.backgroundColor}, 0.5rem 0.5rem 30px ${colorMixed}`;
                if (!isSubscription) {
                    this.eventsService.sendEventMapHighlight({ ...activation, building_id: buildingId, id: cardId });
                }
            }

        }
    }

    searchForEventColor(activationIndex: number): EventActivation | undefined {
      const activations = this.data.activations || [];
        for (let i = activationIndex; i < activations.length; i++) {
            if (activations[i].record == "event") {
                return activations[i];
            }
        }
        return undefined;
    }

    undoHighlightCard(div: HTMLDivElement, isSubscription?: boolean) {
        if (div?.classList.contains("event-card")) {
            div.style.outline = `unset`;
            div.style.boxShadow = `unset`;
            if (!isSubscription) {
                this.eventsService.sendEventMapHighlight(undefined);
            }

        }
    }

    ngDoCheck() {
        // console.log("data event timeline", this.data);
        
        const activations = this.data.activations || []
        const lastEventRow = <HTMLDivElement>this.elementRef.nativeElement.querySelector(`#event-row${activations.length - 1}`)

        if (!lastEventRow) {
            setTimeout(() => {
                const lastEventRow = <HTMLDivElement>this.elementRef.nativeElement.querySelector(`#event-row${activations.length - 1}`)
                if (lastEventRow?.offsetHeight && lastEventRow.offsetHeight !== this.lastCardHeight) {
                    this.lastCardHeight = lastEventRow.offsetHeight
                }
                // console.log(lastEventRow)
            }, 20)
        } else {
            this.lastCardHeight = lastEventRow.offsetHeight
            // console.log(this.lastCardHeight)
        }
    }


    /** Returns the classes to be used for the card at the given [activationIndex] */
    cardClassMap(activationIndex: number): any {
        const { activationsMod, indexMod } = this.getMods(activationIndex)

        return {
            'event-card--right': (activationsMod == 0 && indexMod == 0) || (activationsMod == 1 && indexMod == 1),
            'event-card--left': (activationsMod == 1 && indexMod == 0) || (activationsMod == 0 && indexMod == 1)
        }
    }

    /** Returns the classes to be used by the time label at the given [activationIndex] */
    timeLabelClassMap(activationIndex: number): any {
        const { activationsMod, indexMod } = this.getMods(activationIndex)

        return {
            'time-label--left': (activationsMod == 0 && indexMod == 0) || (activationsMod == 1 && indexMod == 1),
            'time-label--right': (activationsMod == 1 && indexMod == 0) || (activationsMod == 0 && indexMod == 1)
        }
    }

    /** Returns true if the given [color] is white */
    isWhite(color: string): boolean {
        return color === "#fff" || color === "#ffffff" || color === "white"
    }

    /** Returns the modulus used to calculate the positions on the timeline, based on the [activationIndex] */
    private getMods(activationIndex: number): { activationsMod: number, indexMod: number } {
        const activations = this.data.activations || []
        const activationsMod = activations.length % 2
        const indexMod = activationIndex % 2
        return { activationsMod: activationsMod, indexMod: indexMod }
    }

    ngOnDestroy(): void {
        this.getClickMapEventHighlight?.unsubscribe();
        this.getEventsSubs?.unsubscribe();
    }

}

<div class="dash__header">
    <!-- <div>
        <p class="dash__header-title">{{ title }}</p>
    </div> -->
    <div class="header-time">
        <time-range (toggleCheck)="getToggleCheck($event)"></time-range>
    </div>
</div>
<div class="container__dash container-fluid">
    <!-- <time-range (toggleCheck)="getToggleCheck($event)"></time-range> -->
    <mat-tab-group>
        <mat-tab label="Organization Overview">
            <ng-template matTabContent>
                <div class="row print-dash-row">
                    <div class="col-md-4 print-dash-col">
                        <event-breakdown [dataShared]="dataShare" [percentage]="isChecked"></event-breakdown>
                    </div>
                    <div class="col-md-8 print-dash-col">
                        <event-breakdown-time [dataShared]="dataShare" [percentage]="isChecked"></event-breakdown-time>
                    </div>
                </div>
                <div class="row print-dash-row">
                    <div class="col-md-4 print-dash-col">
                        <groups-donuts [dataShared]="dataShare" [percentage]="isChecked"></groups-donuts>
                    </div>
                    <div class="col-md-8 print-dash-col">
                        <locations-chart [dataShared]="dataShare" [percentage]="isChecked"></locations-chart>
                        <!-- <eventsdash [dataShared]="dataShare" [percentage]="isChecked"></eventsdash> -->
                    </div>

                    <!-- <activation-methods [dataShared]="dataShare" [percentage]="isChecked"></activation-methods> -->
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Location(s) Metrics">
            <ng-template matTabContent>
                <location-metrics [dataShared]="dataShare" [percentage]="isChecked"></location-metrics>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

</div>
<div class="position-absolute main">
    <div *ngFor="let alert of alerts" class="alert__container d-flex" (click)="removeAlert(alert)">
        <div class="alert__logo d-flex justify-content-center align-items-center">
            <img class="alert__logo-img" src="../../../../assets/images/icons/Icon_Havrion_Hub_2.svg" alt="">
        </div>
        <div class="alert__message d-flex align-items-center ms-3">
            <p class="alert__message-text">{{alert.message}}</p>
        </div>
    </div>
</div>

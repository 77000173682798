import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EventTypesLocation } from 'src/app/core/models/event-types-location';
import { SubscriptionModel } from 'src/app/core/models/subscriptionModel';
import { EventsService } from 'src/app/core/services/events.service';
import {
  rotateIcon180Trigger,
  toggleCollapseTrigger,
} from 'src/app/core/utils/collapse-animations';

@Component({
  selector: 'attendance',
  templateUrl: 'attendance.component.html',
  styleUrls: ['attendance.component.scss'],
  animations: [toggleCollapseTrigger, rotateIcon180Trigger],
})
export class AttendanceComponent implements OnInit, OnDestroy {
  @Input('content') content: string = '';

  public openedAtt: number = -1;
  @Input('openedAtt') set dataOpenedAtt(value: number) {
    this.openedAtt = value;
    if (this.openBuildingIndex != this.openedAtt) {
      this.openBuildingIndex = this.openedAtt;
    }
  }

  get dataOpenedAtt(): number {
    return this.openedAtt;
  }

  @Output('onOpenBuilding') onOpenBuilding: EventEmitter<number> =
    new EventEmitter<number>();

  public data: any = undefined;

  @Input('data') set dataSet(value: any) {
    this.data = value;
    if (this.data) {
      if (this.data.length > 0) {
        this.setAreas();
      }
    }
  }

  get dataSet(): any {
    return this.data;
  }

  /** Reference to the locations subcription, used to unsubscribe */
  openBuildingIndex: number = -1;
  buildings: BuildingData[] = [];

  private getEventsSubs: Subscription;

  constructor(private eventsService: EventsService) {
    this.getEventsSubs = this.eventsService
      .getEventsSubs()
      .subscribe((info: SubscriptionModel) => {
        if (
          info.channel == 'map-attendance' &&
          info.broadcastID != 'AttendanceComponent'
        ) {
          for (let i = 0; i < this.buildings?.length; i++) {
            if (this.buildings[i].id == info.payload.area.id) {
              this.buildings[i]['highlighted'] = info.payload.isGlow;
              break;
            }
          }
        }
      });
  }

  highlightAttend(
    isHover: boolean,
    attendance?: AttendanceEvent,
    building_id?: string
  ) {
    if (isHover) {
      this.eventsService.sendEventMapHighlight({
        ...attendance,
        building_id: building_id,
      });
    } else {
      this.eventsService.sendEventMapHighlight(undefined);
    }
  }

  getSelectedEventType(eventTypeId: string, locationId: string) {
    let selectedEventType: EventTypesLocation = {
      id: 'not found',
      color: 'red',
      backgroundColor: 'yellow',
      backgroundColorStrong: '',
      name: 'No type',
      icon: '',
      priority: 0,
      code: '',
      trigger_e911: false,
      event_types: [],
    };
    this.data.eventTypes?.map((item: any) => {
      if (item.location_id === locationId) {
        item.eventTypes?.map((event: any) => {
          if (eventTypeId === event.id) {
            selectedEventType = event;
          } else {
            event.event_types?.map((nev: any) => {
              if (eventTypeId === nev.id) {
                selectedEventType = nev;
              }
            });
          }
        });
      }
    });
    return selectedEventType;
  }

  setAttendanceEvents(attendances: any) {
    let attendaceEvent: AttendanceEvent[] = [];
    attendances?.map((item: any) => {
      let start_date = item.time?.split('T', 2)[0].split('-', 3);
      let hour = item.time
        ? item.time?.split('T', 2)[1].split(':', 2)
        : '';
      let utcDate = item.time
        ? Date.UTC(
            +start_date[0],
            +start_date[1] - 1,
            +start_date[2],
            +hour[0],
            +hour[1]
          )
        : undefined;
      let localZoneDate = item.time ? new Date(utcDate) : undefined;
      attendaceEvent.push({
        time: localZoneDate?.toString(),
        reportedBy: item.reported_by,
        location: item.attendances_notes,
        people: item.reported,
      });
    });
    return attendaceEvent;
  }

  setAreas() {
    this.buildings = [];
    this.data.map((bld: any) => {
      this.buildings.push({
        id: bld.id,
        name: bld.name,
        events: this.setAttendanceEvents(bld.reported_attendances),
        totalReported: bld.total_reported,
      });
    });
  }

  ngOnInit() {
    if (!this.openBuildingIndex && this.buildings.length > 0) {
      this.openBuildingIndex = 0;
      this.onOpenBuilding.emit(this.openBuildingIndex);
    }
  }

  ngOnDestroy() {
    this.getEventsSubs?.unsubscribe();
  }

  /** Returns the the state of the expand_more icon, used in the animation */
  buildingIconState(buildingIndex: number): string {
    const openBuildingIndex = this.openBuildingIndex;
    return openBuildingIndex === buildingIndex ? 'rotated' : 'default';
  }

  /** Toggles the according of the building with the givne [buildingIndex], belongin to the school with the given [schoolIndex] */
  toggleBuilding(buildingIndex: number) {
    if (buildingIndex === this.openBuildingIndex) {
      this.openBuildingIndex = -1;
    } else {
      this.openBuildingIndex = buildingIndex;
    }
    this.onOpenBuilding.emit(this.openBuildingIndex);
  }
}

interface BuildingData {
  id: string;
  name: string;
  events: AttendanceEvent[];
  totalReported: number;
  highlighted?: boolean;
}

interface AttendanceEvent {
  time: string;
  reportedBy: string;
  location: string;
  people: number;
}

import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventTypesLocation, mapEventTypestoModel } from 'src/app/core/models/event-types-location';
import { EventsService } from 'src/app/core/services/events.service';

// export interface DialogData {
//   id: string
//   title: string
//   button: string
//   content: string
//   isDrill: boolean
// }

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewEventComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NewEventComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private eventsService: EventsService, private router: Router) { 
      this.event = mapEventTypestoModel(this.data.name);    
    }


  title: any;
  text: any;
  event: EventTypesLocation

  ngOnInit(): void {
    // console.log("message", this.data);

    this.event = mapEventTypestoModel(this.data.name);    

    if (this.data.type === 0) {
      this.title = 'EVENT ALERT';
      this.text = 'event in progress';
    } else if (this.data.type === 1) {
      this.title = 'CLEAR EVENT ALERT';
      this.text = 'event recently cleared'
    } else if (this.data.type === 2) {
      this.title = 'REQUEST TO CANCEL EVENT ALERT';
      this.text = 'request to cancel event'
    }

  }

  dismiss() {
    this.dialogRef.close({ msg: "dismiss" });
  }

  viewEvent() {
    this.dialogRef.close({ msg: "view", locationId: this.data.locationId, type: this.data.type });

  }


}

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry, map } from 'rxjs/operators';
import { Observable, Subject, throwError, of } from 'rxjs';
import { LocationResponse } from '../models/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private subjectCloseDialog = new Subject<any>();
  private updateNodeCloseDialog = new Subject<any>();
  private subjectLocIssues = new Subject<any>();
  private subjectSystemMode = new Subject<any>();
  private subjectLocationUpdate = new Subject<any>();


  endpoint: string = environment.havrionHubApi.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) { }

  sendLocationUpdate(location: any) {
    this.subjectLocationUpdate.next(location);
  }

  getLocationUpdate(): Observable<any> {
    return this.subjectLocationUpdate.asObservable();
  }

  sendSystemModeChange() {
    this.subjectSystemMode.next('');
  }

  getSystemModeChange(): Observable<any> {
    return this.subjectSystemMode.asObservable();
  }

  sendClickCloseDialog(msg: string) {
    this.subjectCloseDialog.next(msg);
  }

  getClickCloseDialog(): Observable<any> {
    return this.subjectCloseDialog.asObservable();
  }

  sendLocIssuesArr(loc: Array<string>) {
    this.subjectLocIssues.next(loc);
  }

  getLocIssuesArr(): Observable<any> {
    return this.subjectLocIssues.asObservable();
  }

  sendUpdateNodeClickCloseDialog(node: any) {
    this.updateNodeCloseDialog.next(node);
  }

  getUpdateNodeClickCloseDialog(): Observable<any> {
    return this.updateNodeCloseDialog.asObservable();
  }

  getLocationWithIssues(): Observable<any> {
    // return of({location_ids:['9138B79D-1365-46A0-8126-270CCF61BB83']});
    let api = `${this.endpoint}/locations/locks`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getLocation(location_id: String): Observable<LocationResponse> {
    let api = `${this.endpoint}/location/${location_id}`;
    return this.http
      .get<LocationResponse>(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getLocations(): Observable<any> {
    // return of([]);
    let api = `${this.endpoint}/locations`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Edit Location */

  editLocation(locationId: string, edit: any) {
    let api = `${this.endpoint}/location/${locationId}`;
    return this.http
      .put(api, edit, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Create Location */

  // createLocation(location) {
  //   let api = `${this.endpoint}/locations`;
  //   return this.http
  //     .post(api, location, { headers: this.headers, responseType: "json" })
  //     .pipe(retry(this.retry), catchError(this.handleError));
  // }

  /**Version 2 of the locations */
  createLocation(location: any) {
    let api = `${this.endpoint}/v2/locations`;
    return this.http
      .post(api, location, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Delete Location */

  deleteLocation(locationId: string) {
    let api = `${this.endpoint}/location/${locationId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  licensesCounter() {
    let api = `${this.endpoint}/licenses-counter`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getLocationGroups(): Observable<any> {
    let api = `${this.endpoint}/location-groups`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Get Location Group */

  getLocationGroup(locationGroupId: string) {
    let api = `${this.endpoint}/location-group/${locationGroupId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Edit Location Group */

  editLocationGroup(locationGroupId: string, edit: any) {
    let api = `${this.endpoint}/location-group/${locationGroupId}`;
    return this.http
      .put(api, edit, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }


  // Add Edit groups

  editAccountInfo(edit: any) {
    let api = `${this.endpoint}/user/account`;
    return this.http
      .put(api, edit, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  getAccountInfo() {
    let api = `${this.endpoint}/user/account`;
    return this.http
      .get(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Delete Location Group */

  deleteLocationGroup(locationGroupId: string) {
    let api = `${this.endpoint}/location-group/${locationGroupId}`;
    return this.http
      .delete(api, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Create Location Group */

  createLocationGroup(group: any) {
    let api = `${this.endpoint}/location-groups`;
    return this.http
      .post(api, group, { headers: this.headers, responseType: "json" })
      .pipe(retry(this.retry), catchError(this.handleError));
  }

  /* Handle Error */

  handleError(error: HttpErrorResponse) {
    let msg = {
      code: 0,
      message: "Unknown error!"
    }
    // console.log("error", error)
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = {
        code: 0,
        message: error.error.message
      }
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error.message
      };
    }
    return throwError(msg);
  }
}

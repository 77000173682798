import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogBlueprintComponent } from './dialog-blueprint/dialog-blueprint.component';

@Component({
  templateUrl: './map-overlays.component.html',
  styleUrls: ['./map-overlays.component.scss']
})
export class MapOverlaysComponent implements OnInit {

  data: any;
  @Input('data') set dataSet(value: any) {
    this.data = JSON.parse(value);
    this.setAreaDetails(this.data.area);
    this.setBlueprints(this.data.blueprints);
  }

  get dataSet(): any {
    return this.data;
  }

  areaDetailFields: string[] = ['code', 'level', 'total_blueprints'];
  blueprintsFields: string[] = ['name', 'view'];

  areaDetails: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  blueprintsList: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  constructor(public dialog: MatDialog,) { }


  ngOnInit(): void {
    // console.log("this.data onInit", this.data);
  }

  openDialog(blueprint: any): void {

    const dialogRef = this.dialog.open(DialogBlueprintComponent, {
      width: '1300px',
      data: { 
        title: "VIEW BLUEPRINTS",
        content: "view-blueprints",
        area: this.data.area,
        blueprintId: blueprint.id,
        blueprints: this.data.blueprints,
        event: this.data.event,
        genEvType: this.data.genEvType,
        genActMeht: this.data.genActMeht
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
     
    });


  }

  setBlueprints(blueprints: any) {
    this.blueprintsList = new MatTableDataSource<any>(blueprints);
  }

  setAreaDetails(area: any) {
    this.areaDetails = new MatTableDataSource<any>([area]);
  }

}

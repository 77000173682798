<mat-card [class]="getClassCSS()">
    <hh-progress-bar *ngIf="loadingData"></hh-progress-bar>
    <div class="row" style="margin-right: 0px; margin-left: 0px;">
        <div class="col pt-3" style="display: flex;align-content: center;flex-wrap: nowrap;flex-direction: row;">
            <p style="font-size: 28px!important;" class="text-dark-iptv">EVENTS TIMELINE </p>
            <div class="icon-warning icon"
                style="margin-left: 10px;margin-top:5px;height: 28px; width: 31px; background-color: #525252;"></div>
        </div>
    </div>

    <div class="d-flex flex-row mx-3">
        <div style="font-size: 12px;" class="header-container d-flex flex-row mx-2 text-dark-iptv">
            <div class="event-grid-cell col-time">Time</div>
            <div class="event-grid-cell col-location">Location</div>
            <div class="event-grid-cell col-event">Event Type</div>
            <div class="event-grid-cell col-activation">Reported Attendance</div>
        </div>
        <mat-icon class="text-white no-select">expand_more</mat-icon>
    </div>
    <simple-divider></simple-divider>
    <div class="list-of-events slim-scrollbar">
        <div *ngIf="events?.length === 0 || (!loadingData && !events)" class="empty-state-hhub"> </div>
        <div *ngFor="let hubEvent of events; let eventIndex = index">
            <div style="min-height: 80px;" class="d-flex flex-row px-3 align-items-center click-element"
                (click)="toggleEvent(hubEvent.id, false)">
                <div class="flex-grow-1 mx-2 d-flex flex-row">
                    <div style="font-weight: 500;" class="col-time text-dark-iptv my-auto bold">{{ hubEvent.time | date:'hh:mm a'}}</div>
                    <div id="location-name" style="font-size: 14px;"
                        class="col-location event-grid-cell text-dark-iptv pe-2 my-auto">{{hubEvent.location}}</div>
                    <div style="min-height: 80px;" class="col-event event-grid-cell text-dark-iptv pe-2">
                        <div style="min-height: 80px;">
                            <div [ngStyle]="{ 'backgroundColor': hubEvent.type?.backgroundColor }"
                                class="p-4 d-flex event-type">
                                <img width="44px" [src]="'./assets/images/icons/' + hubEvent.type?.icon">
                                <span style="font-weight: 500; line-height: 1.3; font-size: 16px;"
                                    class="ms-2 flex-grow-1 my-auto">{{hubEvent.type?.name}}</span>

                                <button *ngIf="data.content === 'home'" class="btn-clear"
                                    (click)="clearEvent(hubEvent.id); openDialog('clearEvent', hubEvent.id)"
                                    [disabled]="!(data.content === 'home')">Clear</button>
                                <button *ngIf="data.content === 'drill'" class="btn-clear"
                                    (click)="clearDrill('deactivateDrill', hubEvent)"
                                    [disabled]="!(data.content === 'drill')">Clear Drill</button>
                            </div>
                            <div [ngStyle]="{ 'backgroundColor': hubEvent.type?.backgroundColor }"
                                style="text-align: center; text-transform: uppercase; font-size: 10px;">
                                <strong>via:</strong> {{hubEvent.activationMethod}}
                            </div>
                        </div>
                    </div>
                    <div style="font-size: 40px;" class="col-activation event-grid-cell text-dark-iptv text-center my-auto">
                        {{ hubEvent.general_attendance }}</div>
                </div>
                <mat-icon [@rotateIcon180]="eventIconState(hubEvent.id)" class="text-dark-iptv">expand_more</mat-icon>
            </div>
            <div [@toggleCollapse] *ngIf="openedEventIndex === hubEvent.id" class="tabs-timeline">

                <mat-tab-group mat-align-tabs="end" [selectedIndex]="tabSelected"
                    (selectedTabChange)="tabChanged($event, hubEvent.locationId ? hubEvent.locationId : '')">

                    <mat-tab>
                        <ng-template mat-tab-label class="timeline-tab-header">
                            <div class="mx-3 pt-4 mb-3" *ngIf="data && data?.content!='eventHistory'">
                                <app-real-time></app-real-time>
                            </div>
                            <div style="display: flex;align-content: center;align-items: center;flex-wrap: nowrap;">
                                <div class="icon-warning icon"
                                    style="margin-right: 20px; height: 28px; width: 31px; background-color: #525252;">
                                </div>
                                <span style="font-size: 12px;">View Event Timeline</span>
                                <span style="color: #000; margin-left: 10px; font-size: 12px;">
                                    <mat-icon>sync_alt</mat-icon>
                                </span>
                            </div>
                        </ng-template>
                        <events-timeline [data]="hubEvent"></events-timeline>
                    </mat-tab>
                    <mat-tab>

                        <ng-template mat-tab-label class="timeline-tab-header">
                            <div class="mx-3 pt-4 mb-3" *ngIf="data && data?.content!='eventHistory'">
                                <app-real-time></app-real-time>
                            </div>
                            <div style="display: flex;align-content: center;align-items: center;flex-wrap: nowrap;">
                                <span style="margin-right: 20px;"> <img width="30px"
                                        src="./assets/images/icons/timeline_icon_attendance.svg"> </span>
                                <span style="font-size: 12px;">View Attendance Details</span>
                                <span style="color: #000; margin-left: 10px; font-size: 12px;">
                                    <mat-icon>sync_alt</mat-icon>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template matTabContent>
                            <attendance
                                *ngIf="this.data.content =='home' || this.data.content =='drill' ; else singleAttendances"
                                [data]="data.eventsRes[eventIndex].areas_attendance" [openedAtt]="openedAttendance ? openedAttendance : 0"
                                (onOpenBuilding)="attendanceOpen($event)"></attendance>
                            <ng-template #singleAttendances>
                                <attendance [data]="data.eventsRes.areas_attendance">
                                </attendance>
                            </ng-template>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>

            </div>
            <simple-divider></simple-divider>
        </div>
    </div>
</mat-card>